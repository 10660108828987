import React from 'react';
import styled from 'styled-components';

export const Styled = styled.div`
  height: 100vh;
  background: url("assets/bc.jpg") no-repeat center;
`;

const BecautifulBackgroud = () => {
    return (
        <Styled className="background"/>
    );
};

export default BecautifulBackgroud;