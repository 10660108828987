import React from 'react';
import ServerComInfo from '../ServerComInfo';
import utilsService from '../../../../../servises/utils.service';
import styled from 'styled-components';

const Styled = styled.section`
  height: 150px;
`;

const ServerProcessesWidget = ({ server }) => {
    return (
        <Styled>
            <ServerComInfo
                name={ 'Processes Count' }
                data={ utilsService.getFromMetrics(server, 'processes') }
            />
        </Styled>
    );
};

export default ServerProcessesWidget;