class SettingsStore {
    redirectRoute = null;

    setRedirectRoute(route) {
        if (route === '' || route === '/login') {
            this.redirectRoute = '/servers';
        } else {
            this.redirectRoute = route;
        }
    }
}

const settingsStore = new SettingsStore();

export default settingsStore;