import React from 'react';
import styled from 'styled-components';

const ServerInfoStyled = styled.section`
  margin: 17px 0;
  
  .label{
    //font-size: 13px;
  }
  
  div{
    margin-top: 2px;
    //background: #0e1b25;
    background-color: rgb(52,55,65);
    padding: 6px 5px;
  }
`;

const ServerComInfo = ({ name, data }) => {
    return (
        <ServerInfoStyled>
            <legend className='label'>
                { name }:
            </legend>
            <div>
                { data }
            </div>
        </ServerInfoStyled>
    );
};

export default ServerComInfo;