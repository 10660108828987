import React, { useCallback, useState } from 'react';
import { ModalMainStyled } from './modal.styled';
import alarmsStore from '../../../stores/alarms.store';
import AlarmMoreInfo from '../alarmsMoreInfo/AlarmMoreInfo';
import {
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle
} from '@elastic/eui';
import { addToast } from '../toasts/Toast';

const ModalAlarm = ({ data, close }) => {
    const { alarm, text, server, severity } = data;

    const [isSending, setIsSending] = useState(false);

    const handleSubmit = useCallback(async () => {
        if (isSending) {
            return;
        }

        setIsSending(true);

        const status = alarm.status === 1 ? 2 : 1;
        await alarmsStore.setAlarmStatus(alarm._id, status);
        addToast('Alarm marked as read', 'suc');
        close();

    }, [isSending]);

    return (
        <ModalMainStyled>
            <EuiModal onClose={ close }>
                <EuiModalHeader>
                    <EuiModalHeaderTitle><h1>Alarm</h1></EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    <AlarmMoreInfo
                        alarm={ alarm }
                        severity={ severity }
                        text={ text }
                    />
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButton
                        onClick={ handleSubmit }
                        fill
                        isLoading={ isSending }
                    >Mark
                        as { alarm.status === 1
                            ? 'read'
                            : 'unread' }</EuiButton>
                </EuiModalFooter>
            </EuiModal>

        </ModalMainStyled>
    );
};

export default ModalAlarm;