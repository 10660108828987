import React from 'react';
import Header from '../../components/header/Header';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import userStore from '../../../stores/user.store';
import { withRouter } from 'react-router';
import LoaderComponent from '../../components/Loader.component';
import AlertsSection from './components/AlertsSection';

const Styles = styled.div`
  font-size: 20px;
  margin: 10px 20px;
`;

const AlarmsSettingsPage = props => {
    document.title = 'Alarms Settings - SyAgent';

    return (
        <main>
            <Header/>
            <Styles>
                <SettingsPageWrapper
                    history={ props.history }
                />
            </Styles>
        </main>
    );
};

const SettingsPageWrapper = props => {
    return useObserver(() => (
        <SettingsPage
            history={ props.history }
            user={ userStore.user }
        />
    ));
};

const SettingsPage = props => {
    const { user } = props;

    return (
        <>
            { !user && (
                <LoaderComponent/>
            ) }

            { user && (
                <AlertsSection
                    user={ user }
                />
            ) }
        </>
    );
};

export default withRouter(AlarmsSettingsPage);