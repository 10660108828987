import React, { useCallback } from 'react';
import styled from 'styled-components';
import { alarmsSettingsTypes } from '../../../constants/data.types';
import alarmsStore from '../../../stores/alarms.store';
import { EuiSwitch } from '@elastic/eui';
import { addToast } from '../toasts/Toast';

const ItemStyles = styled.div`
  margin: 25px 0;

  fieldset {
    margin-top: 20px;
  }

  label {
    width: fit-content;
    font-size: 16px;
  }

  .soon {
    font-size: 13px;
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }

  .alarms-toggle {
    margin-top: 20px;
  }

  .alarms-settings-toggle {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: baseline;
  }

  .code {
    white-space: pre-wrap;
    background-color: black;
    color: white;
    font-size: medium;
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
    width: 100%;
    display: inline-block;
    line-height: 1.4;
    margin: 10px 0 35px;
    padding: 10px;
  }

  .webhook-alarm {
    .form {

    }

    input {
      margin: 5px 0 10px;
      display: block;
      height: 30px;
      min-width: 400px;

       @media (max-width: 600px) {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  h3 {

  }
`;

const ToggleWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
  margin-top: 18px;

  legend {
    font-size: 17px;
  }
`;

const AlarmsSettings = ({ serverAlarmsSettings }) => {
    const handleSettingsChange = useCallback(async (key, data) => {
        addToast('Alarms settings changed', 'suc');
        await alarmsStore.updateAlarmsSettings(
            {
                type: key,
                status: data,
                serverId: serverAlarmsSettings.serverId
            });
    }, [serverAlarmsSettings]);

    return (
        <ItemStyles>
            <h3>Alarms notifications</h3>
            <p>Set type of alarm you prefer to get notification.</p>

            <ToggleWrapperStyled>
                <legend>Server is down</legend>
                <EuiSwitch
                    label="On"
                    onChange={ () => null }
                    checked={ true }
                    disabled={ true }
                />
            </ToggleWrapperStyled>

            { alarmsSettingsTypes.map((item, idx) => {
                return (
                    <ToggleWrapperStyled
                        key={ idx + 'zkkdw' }
                    >
                        <legend>{ item.title }</legend>
                        <EuiSwitch
                            label={ serverAlarmsSettings[item.key]
                                ? 'On'
                                : 'Off' }
                            checked={ serverAlarmsSettings[item.key] }
                            onChange={ () => handleSettingsChange(item.key,
                                !serverAlarmsSettings[item.key]
                            ) }
                        />
                    </ToggleWrapperStyled>
                );
            }) }
        </ItemStyles>
    );
};

export default AlarmsSettings;