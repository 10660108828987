import React from 'react';
import { Modal } from '@fluentui/react';
import { ModalMainBodyStyled, ModalMainStyled } from './modal.styled';
import Changes from '../changes/Changes';

const ChangesModal = ({ close }) => {
    return (
        <ModalMainStyled>
            <Modal
                titleAriaId={ 'titleIdd' }
                isOpen={ true }
                onDismiss={ close }
                isBlocking={ false }
                containerClassName={ 'default-modal' }
            >
                <div className={ 'close-button' } onClick={ close }/>
                <ModalMainBodyStyled>
                    <Changes/>
                </ModalMainBodyStyled>
            </Modal>
        </ModalMainStyled>
    );
};

export default ChangesModal;