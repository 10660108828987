import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../styleComponents/Colors';

const Styles = styled.div`
  height: 32px;
  line-height: 1.7;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 3px 12px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid transparent;
  position: relative;

  &.selected {
    border: 2px solid rgb(0, 128, 213);

    &:before {
      content: "selected";
      position: absolute;
      top: -15px;
      font-size: 9px;
      width: 100%;
      left: -2px;
      color: rgb(0, 128, 213);
      //text-transform: uppercase;
    }
  }
`;

const StylesMini = styled.div`
  display: inline-block;
  position: relative;
  width: 6px;
  height: 8px;
  margin-right: 5px;
`;

const TagItem = ({ tag, selected, selectEvent, makeMini }) => {
    if (!tag) return null;

    const color = COLORS[tag.color];

    if (makeMini) {
        return (
            <StylesMini
                className={ selected
                    ? 'selected tags-item-zll'
                    : 'tags-item-zll' }
                onClick={ () => selectEvent
                    ? selectEvent(tag._id, !selected)
                    : null }
                style={ { background: color, color: '#fff' } }
            />
        );
    }

    return (
        <Styles
            className={ selected ? 'selected tags-item-zll' : 'tags-item-zll' }
            onClick={ () => selectEvent
                ? selectEvent(tag._id, !selected)
                : null }
            style={ { background: color, color: '#fff' } }
        >
            { tag.name }
        </Styles>
    );
};

export default TagItem;
