import React, { useCallback, useEffect, useState } from "react";
import { BoxedStyled } from "../../../style/components/common.styled";
import styled from "styled-components";
import userStore from "../../../stores/user.store";
import { PrimaryButtonStyled } from "../../../style/components/button.styled";
import { getCurrentUser } from "../../../servises/auth.service";

const Styles = styled.div`
  text-align: center;
  padding: 30px;
  font-size: 19px;

  button {
    margin-top: 60px;
  }
`;

const VerifyEmailAskPage = props => {
    if (userStore.user && userStore.emailVerified) {
        props.history.push("/servers");
        // return;
    }

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        const sentEmail = localStorage.getItem("emailSentIn");
        const dateNow = Date.now();
        let emailSentNum = 0;
        let timeOut = 120000;

        if (sentEmail) {
            emailSentNum = Number(sentEmail);
        }

        if (dateNow - 120000 > emailSentNum) {
            timeOut = 0;
        }

        const timer = setTimeout(() => {
            setButtonDisabled(false);
        }, timeOut);
        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (isSending) {
            return;
        }

        setIsSending(true);
        const user = getCurrentUser();
        user.sendEmailVerification();
        localStorage.setItem("emailSentIn",
            Date.now().toString()
        );

    }, [isSending]);

    const logout = () => {
        return userStore.doLogOut('/login');
    };

    return (
        <BoxedStyled>
            <Styles>
                <h1>We sent you an email, please verify</h1>

                { !buttonDisabled && !isSending && (
                    <PrimaryButtonStyled
                        onClick={ handleSubmit }
                    >
                        Send verification email again
                    </PrimaryButtonStyled>
                ) }

                <div className="logout-button">
                    <PrimaryButtonStyled
                        onClick={ logout }
                    >
                        Logout
                    </PrimaryButtonStyled>
                </div>
            </Styles>
        </BoxedStyled>
    );
};
// http://localhost:3000/pik?mode=verifyEmail&oobCode=y4eBnckh4ToN9RkNnNjNzJ3Bus5X06Mlh3IhXsGMyCcAAAF3RYkgXA&apiKey=AIzaSyDuXWbbtUFQe3K7w80p41SBfFwCKCSmFT8&lang=en

export default VerifyEmailAskPage;