import fetchService from '../servises/fetch.service';
import { doSignOut } from '../servises/auth.service';
import { makeAutoObservable } from 'mobx';
import LogRocket from 'logrocket';

class UserStore {
    constructor() {
        makeAutoObservable(this);
    }

    user = null;
    userToken = null;
    logged = null;
    emailVerified = false;
    sub = null;
    apiKey = null;

    async getCurrentUser(googleJwt) {
        this.userToken = googleJwt;
        const response = await fetchService.authenticatedGet(`/user`);
        this.user = response.data.data_o;
        this.logged = true;
        if (this.user) {
            LogRocket.identify(this.user._id, {
                email: this.user.email
            });
        }
        return this.user;
    }

    async createUser(googleJwt, body) {
        this.userToken = googleJwt;
        const response = await fetchService.authenticatedPost(`/user`,
            body
        );
        this.user = response.data;
        this.logged = true;
        return this.user;
    }

    async updateNotifications(key, data, metaData) {
        const response = await fetchService.authenticatedPost(
            `/user-notifications`,
            { key, data, metaData }
        );
        this.user = response.data.data_o;
    }

    async deleteUser() {
        await fetchService.authenticatedDelete(`/user`);
        await doSignOut();
    }

    async verifyEmail() {
        const response = await fetchService.authenticatedGet(`/user-f`);
        this.user = response.data.data_o;
    }

    async doLogOut(whereTo) {
        this.user = null;
        this.userToken = null;
        await doSignOut();
        if (whereTo) {
            window.location.replace(whereTo);
        } else {
            window.location.reload(true);
        }
    }

    async getSub() {
        const response = await fetchService.authenticatedGet(`/sub`);
        this.sub = response.data.data_o;
    }

    async generateApiKey() {
        const response = await fetchService.authenticatedPost(`/api-key`);
        this.apiKey = response.data.data_o.apiKey;
    }

    async getApiKey() {
        const response = await fetchService.authenticatedGet(`/api-key`);
        this.apiKey = response.data.data_o.apiKey;
    }

    async deleteApiKey() {
        const response = await fetchService.authenticatedDelete(`/api-key`);
        this.apiKey = response.data.data_o.apiKey;
    }
}

const userStore = new UserStore();

export default userStore;