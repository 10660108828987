import React, { useState } from 'react';
import styled from 'styled-components';
import { InputStyled } from '../../../style/components/Input.styled';
import serverStore from '../../../stores/server.store';
import { Link } from 'react-router-dom';
import LoaderComponent from '../Loader.component';
import utilsService from '../../../servises/utils.service';
import uptimeStore from '../../../stores/uptime.store';
import mongodbStore from '../../../stores/mongodb.store';

const Styles = styled.div`
  position: relative;

  @media (max-width: 800px) {
    display: none;
  }
`;

const InputWrapperStyled = styled.div`
  input {
    border: none;
    height: 30px;
    //background: #0e1b25;
    background-color: rgb(52,55,65);
    //background: rgba(0, 193, 216, 0.7);
    padding: 0 15px;
    outline: none;
    color: rgba(255, 255, 255, 0.85);
    width: 200px;
    font-size: 14px;
    transition: width 0.4s ease;

    &:focus {
      width: 300px;
      border-color: #1890ff;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
`;

const ResultsWrapperStyled = styled.div`
  width: 300px;
  //background: #0e1b25;
  background-color: rgb(52,55,65);
  position: absolute;
  top: 31px;
  border-color: #1890ff;
  border-right-width: 1px !important;
  outline: 0;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease;
  //background: rgba(0, 193, 216, 0.7);

  &.focused_3 {
    max-height: 500px;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    padding: 10px;
    transition: max-height 0.8s ease;
    overflow: auto;
`;

const ResultItemStyled = styled.div`
  margin: 7px 0;
  position: relative;
  padding-left: 15px;
  background: rgb(52, 55, 65);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, .4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);

  &:hover {
    background: #1f313e;
  }

  a {
    text-decoration: none;
  }

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    background: rgb(0, 128, 213);
    display: block;
    position: absolute;
    //border-radius: 50%;
    left: 0;
    top: 2px;
    height: 100%;
    left: 0;
    top: 0px;
  }

  &.no-data {
    &:before {
      background: #f9be49;
    }
  }

  &.old-data {
    &:before {
      background: #F86B63;
    }
  }
`;

const ResultItemLinkWrapperStyled = styled.div`
  padding: 2px 0;

  &:hover {
    color: #fff;
  }

  .bottom {

  }

  .name {
    margin: 3px 0;
  }

  .item {
    label {
      color: rgba(255, 255, 255, 0.65);
      font-size: 10px;
      margin-right: 3px;
    }

    span {
      font-size: 11px;
    }
  }
`;

const HeaderSearch = () => {
    const [value, setValue] = useState('');
    const [isOnFocus, setFocus] = useState(false);
    const [isLoader, setLoader] = useState(false);
    const [resultList, setResultList] = useState([]);

    const searchHandle = txt => {
        setValue(txt);

        const listOfServers = Object.values(
            { ...serverStore.serversListObj, ...uptimeStore.uptimeListObj });

        let tempResults = [];
        const listLength = listOfServers.length;
        for (let i = listLength - 1; i >= 0; i--) {
            const currentItem = listOfServers[i];
            const expr = RegExp(`${ txt.toLowerCase() }`);
            if (expr.test(currentItem.name.toLowerCase())) {
                tempResults.push(currentItem);
            }
        }

        if (txt === '') {
            tempResults = listOfServers;
        }

        setResultList(tempResults);
    };

    const focusHandle = e => {
        setTimeout(() => {
            setFocus(true);
            setResultList(
                Object.values({ ...serverStore.serversListObj, ...uptimeStore.uptimeListObj, ...mongodbStore.mongoDbListObj }));
        }, 300);
    };

    const fcousOutHandle = () => {
        setTimeout(() => {
            setFocus(false);
            setValue('');
            setResultList([]);
        }, 100);
    };

    return (
        <Styles>
            <InputWrapperStyled>
                <InputStyled
                    type="text"
                    value={ value }
                    onChange={ e => searchHandle(e.target.value) }
                    onFocus={ e => focusHandle(e) }
                    onBlur={ e => fcousOutHandle(e) }
                />
            </InputWrapperStyled>


            <ResultsWrapperStyled
                className={ `search-results_dd299 ${ isOnFocus
                    ? 'focused_3'
                    : '' }` }>
                { isLoader && (
                    <LoaderComponent/>
                ) }
                { resultList.map((res, idx) => {
                    const isUptime = !!res.type;
                    const isMongo = !!res.isMongo;
                    const isServer = !isUptime && !isMongo;

                    let alarmsNumber = 0;
                    if (res.alarms && res.alarms.length) {
                        alarmsNumber = res.alarms.filter(alarm => alarm.status === 1).length;
                    }

                    let classes = '';
                    if (isUptime) {
                        classes = res.classes;
                    } else if (isServer) {
                        classes = res.serverData.classes;
                    } else {
                        classes = res.classes;
                    }

                    let link = '';
                    if (isUptime) {
                        link = `/uptime/${ res._id }`;
                    } else if (isServer) {
                        link = `/servers/${ res._id }`;
                    } else {
                        link = `/mongo/${ res._id }`;
                    }

                    return (
                        <ResultItemStyled
                            className={ classes }
                            key={ idx + '33axz' }
                        >
                            <Link
                                to={ link }
                                className="color-2"
                            >
                                <ResultItemLinkWrapperStyled>
                                    <div className="name">
                                        { res.name }
                                    </div>
                                    <div className="bottom">
                                        { isMongo && (
                                            <>
                                                <div className="item">
                                                    <label>Connections:</label>
                                                    <span>{ res.connections }</span>
                                                </div>
                                            </>
                                        ) }
                                        { isUptime && (
                                            <>
                                                <div className="item">
                                                    <label>Type:</label>
                                                    <span>{ res.type }</span>
                                                </div>
                                                <div className="item">
                                                    <label>Interval:</label>
                                                    <span>{ utilsService.calculateEveryTime(
                                                        res) }</span>
                                                </div>
                                            </>
                                        ) }

                                        { isServer && (
                                            <>
                                                <div className="item">
                                                    <label>OS:</label>
                                                    <span>
                                                { utilsService.getFromMetrics(
                                                    res, 'os_name') }
                                            </span>
                                                </div>
                                                <div className="item">
                                                    <label>IP:</label>
                                                    <span>
                                                { utilsService.getFromMetrics(
                                                    res, 'ipv4') }
                                            </span>
                                                </div>
                                            </>
                                        ) }
                                        <div className="item">
                                            <label>Alarms:</label>
                                            <span>
                                                { alarmsNumber }
                                            </span>
                                        </div>
                                    </div>
                                </ResultItemLinkWrapperStyled>
                            </Link>
                        </ResultItemStyled>
                    );
                }) }
            </ResultsWrapperStyled>

        </Styles>
    );
};

export default HeaderSearch;
