import { makeAutoObservable } from 'mobx';
import fetchService from '../servises/fetch.service';

class MongoDbMetricsStore {
    constructor() {
        makeAutoObservable(this);
    }

    mongoDbMetrics = [];

    async getMongoMetrics(uptimeId) {
        const response = await fetchService.authenticatedGet(
            `/mongo/${ uptimeId }`);
        const data = response.data.data_o;
        if (data) {
            if (data) {
                this.mongoDbMetrics = data.reverse();
            }
        }
    }
}

const mongoDbMetricsStore = new MongoDbMetricsStore();

export default mongoDbMetricsStore;