import React from 'react';
import utilsService from '../../../../../servises/utils.service';
import ServerComInfo from '../ServerComInfo';

const generateData = server => {
    return [
        {
            title: 'IP',
            data: utilsService.getFromMetrics(server, 'ipv4')
        },
        {
            title: 'Os Name',
            data: utilsService.getFromMetrics(server, 'os_name')
        },
        {
            title: 'Data Since',
            data: server.serverData.timeAgo
        },
        {
            title: 'Kernel',
            data: utilsService.getFromMetrics(server, 'os_kernel')
        },
        {
            title: 'Os Arch',
            data: utilsService.getFromMetrics(server, 'os_arch')
        },
        {
            title: 'Server Added',
            data: server.serverData.createdAt
        },
        {
            title: 'Sessions',
            data: utilsService.getFromMetrics(server, 'sessions')
        }
    ];
};

const ServerInfoWidget = ({ server }) => {
    // name, createdAt, ipv4, ipv6, os_arch, os_kernel, os_name, uptime, version
    const data = generateData(server);
    return (
        <div>
            { data.map((item, idx) => {
                return (
                    <ServerComInfo
                        key={ idx + '_ddw2-s' }
                        name={ item.title }
                        data={ item.data }
                    />
                );
            }) }
        </div>
    );
};

export default ServerInfoWidget;