import React, {useState} from "react";
import styled from "styled-components";
import {useObserver} from "mobx-react-lite";
import serverStore from "../../../../stores/server.store";
import {Link} from "react-router-dom";
import TagsList from "../../../components/tags/TagsList";
import uptimeStore from "../../../../stores/uptime.store";
import {EuiHorizontalRule, EuiIcon} from "@elastic/eui";
import mongoDbStore from "../../../../stores/mongodb.store";
import ServerServerListWithChildren from "./ServerListWithChildren";
import ServerUptimeListWithChildren from "./ServerUptimeListWithChildren";
import ServerMongoListWithChildren from "./ServerMongoListWithChildren";
import {serverFilterTypes} from "../../../../constants/data.types";

export const ServersListStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(150px, 1fr));
  grid-gap: 10px;
  padding: 5px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  .normal {
    border-bottom: 2px solid rgb(0, 128, 213);
  }

  .no-data {
    border-bottom: 2px solid #F3D371;
  }

  .old-data {
    border-bottom: 2px solid #F86B63;
  }

  &.table {
    grid-template-columns: 1fr;
  }
`;

const ServersListWrapperStyled = styled.div`
  position: relative;
  width: 100%;
`;

const AscDescFilterStyled = styled.div`
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 11px;
`;

const FilterItemStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  .icon-wrapper-adsd {
    width: 25px;
    height: 25px;
  }

  .value-text-oddz {

  }
`;

export const FilterGroupStyled = styled.div`
  position: relative;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  @media (max-width: 600px) {
    display: block;
    width: 100%;
  }

  .pllasdaw {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: 5px;
    min-width: 230px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr 15%;
      margin-bottom: 9px;
      grid-gap: 10px;
    }
  }

  .view-change-zd4 {
    display: grid;
    cursor: pointer;

    @media (max-width: 600px) {
      margin: 5px 0;
    }

    .view-change-zd4-dzz {
      display: grid;
      grid-template-columns:auto 1fr;
      grid-gap: 5px;
      align-items: end;
    }
  }

  .pijsz {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 5px;

    .iuy {
      font-size: inherit;
      color: #36A2EF;
    }
  }
`;

export const TagsListWrapperStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto auto 1fr;
  justify-items: end;
  align-items: initial;
  grid-gap: 8px;

  .tags-item-zll {
    font-size: 12px;
    padding: 1px 5px;
    height: 25px;
  }

  @media (max-width: 600px) {
    display: block;
    width: 100%;
  }

  .position-kdz {
    @media (max-width: 600px) {
      margin: 10px 0;
    }

    svg {
      cursor: pointer;
    }
  }
`;

export const ServersItemStyled = styled.div`
  border-radius: 6px;
  padding: 0 7px 0 7px;
  position: relative;
  min-height: 223px;
  background-color: rgb(29, 30, 36);
  box-shadow: rgb(0 0 0 / 28%) 0px 0.9px 4px, rgb(0 0 0 / 21%) 0px 2.6px 8px, rgb(0 0 0 / 18%) 0px 5.7px 12px, rgb(0 0 0 / 14%) 0px 15px 15px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px, rgba(0, 0, 0, 0.243) 0px 3.6px 13px, rgba(0, 0, 0, 0.21) 0px 8.4px 23px, rgba(0, 0, 0, 0.176) 0px 23px 35px;
    transform: translateY(-2px);
    cursor: pointer;
  }

  &.table-dz777 {
    min-height: auto;
  }

  .status {
    text-align: right;
    font-size: 11px;
    margin: 5px;
    position: relative;
    height: 22px;
  }

  h4, .ip, .uptime-data {
    margin: 7px 0;
    word-break: break-all;
  }

  .server-data {
    & > div {
      color: #00bcd4;
      margin: 7px 0;
    }
  }

  .server-link {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    //&:hover {
    //  border: 1px solid rgba(0, 193, 216, 0.2);
    //}
  }

  .server-link-add {

  }

  .alarms-oodz4_1 {
    font-size: 10px;
    color: #F86B63;

    .bibl {
      color: rgba(255, 255, 255, 0.65);
    }
  }

  .alarms-oodz4 {
    position: absolute;
    left: -5px;
    font-size: 10px;
    color: #F86B63;

    .bibl {
      color: rgba(255, 255, 255, 0.65);
    }
  }

  .uptime-data {
    font-size: 13px;

    .label {
      color: rgba(255, 255, 255, 0.65);
    }
  }

  .table-view-pox-iuzsd {
    display: grid;
    grid-template-columns: 1fr 7fr 2fr 2fr 2fr 2fr 1fr;
    height: fit-content;
    align-items: baseline;
    justify-items: left;

    @media (max-width: 600px) {
      grid-template-columns: 1fr 2fr 2fr;
    }
  }

  .pepew {
    position: relative;
    width: 100%;
    text-align: center;
    min-height: 40px;

    &.mobile12 {
      @media (max-width: 600px) {
        display: none;
      }
    }

    label {
      position: absolute;
      font-size: 10px;
      color: rgba(255, 255, 255, 0.65);
      left: 0;
      right: 0;
    }

    .val {
      font-size: 14px;
      margin-top: 18px;
      display: block;
      color: rgba(255, 255, 255, 0.85);

      &.alarmed {
        color: rgb(247, 97, 78);
      }
    }
  }

  .label {
    margin-right: 4px;
  }

  &.uptime-d {
    min-height: 200px;
  }
`;

const NewServerItemStyled = styled.div`
  border-radius: 6px;
  padding: 0 7px 0 7px;
  position: relative;
  min-height: 100px;
  cursor: pointer;

  background-color: rgb(29, 30, 36);
  box-shadow: rgb(0 0 0 / 28%) 0px 0.9px 4px, rgb(0 0 0 / 21%) 0px 2.6px 8px, rgb(0 0 0 / 18%) 0px 5.7px 12px, rgb(0 0 0 / 14%) 0px 15px 15px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px, rgba(0, 0, 0, 0.243) 0px 3.6px 13px, rgba(0, 0, 0, 0.21) 0px 8.4px 23px, rgba(0, 0, 0, 0.176) 0px 23px 35px;
    transform: translateY(-2px);
    cursor: pointer;
  }

  &::before {
    content: '+';
    color: #1890ff;
    font-size: 30px;
    display: grid;
    align-content: center;
    height: 100%;
    justify-content: center;
  }

  a {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

`;

const ServersList = () => {
    return useObserver(() => (
        <ServerListObserved
            servers={Object.values(serverStore.serversListObj)}
            canAddNewServers={serverStore.canAddNewServers}
            uptime={Object.values(uptimeStore.uptimeListObj)}
            mongo={Object.values(mongoDbStore.mongoDbListObj)}
        />
    ));
};

export default ServersList;

const getServerSeder = () => {
    let result = ['server', 'uptime', 'mongo'];
    const seder = localStorage.getItem("serverSeder");
    if (seder) {
        result = JSON.parse(seder);
    }
    return result;
}

const ServerListObserved = ({servers, canAddNewServers, uptime, mongo}) => {
    const tableViewStorage = localStorage.getItem("tableViewStorage");
    const [viewSort, setViewSort] = useState(getServerSeder());

    const [selectedTagId, setSelectedTagId] = useState(null);
    const [isTableView, setIsTableView] = useState(!!tableViewStorage);

    const totalPosition = viewSort.length;

    const changeOrder = (position, type) => {
        const newSeder = [...viewSort];
        const currentItem = newSeder[position];
        const newIndex = type === "down" ? position + 1 : position - 1;
        newSeder[position] = newSeder[newIndex];
        newSeder[newIndex] = currentItem;
        localStorage.setItem("serverSeder", JSON.stringify(newSeder));
        setViewSort(newSeder);
    };
    const onTagChange = (id, value) => {
        if (id === selectedTagId) {
            setSelectedTagId(null);
        } else {
            setSelectedTagId(id);
        }
    };

    const onViewChane = () => {
        setIsTableView(!isTableView);
        if (isTableView) {
            localStorage.removeItem("tableViewStorage");
        } else {
            localStorage.setItem("tableViewStorage", "hh");
        }

    };

    return (
        <ServersListWrapperStyled>
            <TagsListWrapperStyled>
                <FilterGroupStyled>
                    <div className="pijsz">
                        <div className="iuy">
                            Tags:
                        </div>
                        <TagsList
                            showMessage={true}
                            serverTags={[selectedTagId]}
                            selectEvent={onTagChange}
                        />
                    </div>
                    <div className="pijsz">
                        <div className="iuy">
                            View:
                        </div>
                        <div className="view-change-zd4" onClick={onViewChane}>
                            {!isTableView && (
                                <div className="view-change-zd4-dzz">
                                    <EuiIcon type="tableDensityExpanded" size="m" color="#fff"/>
                                    <span>Table view</span>
                                </div>
                            )}
                            {isTableView && (
                                <div className="view-change-zd4-dzz">
                                    <EuiIcon type="grid" size="m" color="#fff"/>
                                    <span>Grid view</span>
                                </div>
                            )}
                        </div>
                    </div>
                </FilterGroupStyled>
            </TagsListWrapperStyled>
            <EuiHorizontalRule/>

            {viewSort.map((item, idx) => {
                if (item === 'server') {
                    return (
                        <ServerServerListWithChildren
                            key={item}
                            position={idx}
                            totalPosition={totalPosition}
                            isTableView={isTableView}
                            canAddNewServers={canAddNewServers}
                            servers={servers}
                            selectedTagId={selectedTagId}
                            changeOrder={changeOrder}
                        />
                    )
                }
                if (item === 'uptime') {
                    return (
                        <ServerUptimeListWithChildren
                            key={item}
                            position={idx}
                            totalPosition={totalPosition}
                            isTableView={isTableView}
                            canAddNewServers={canAddNewServers}
                            servers={uptime}
                            selectedTagId={selectedTagId}
                            changeOrder={changeOrder}
                        />
                    )
                }
                if (item === 'mongo') {
                    return (
                        <ServerMongoListWithChildren
                            key={item}
                            position={idx}
                            totalPosition={totalPosition}
                            isTableView={isTableView}
                            canAddNewServers={canAddNewServers}
                            servers={mongo}
                            selectedTagId={selectedTagId}
                            changeOrder={changeOrder}
                        />
                    )
                }
                return null;
            })}

        </ServersListWrapperStyled>
    );
};

export const NewServerItem = () => {
    return (
        <NewServerItemStyled>
            <Link to={`/servers-new`} className={"server-link-add"}/>
        </NewServerItemStyled>
    );
};

export const FilterItemDisplay = ({item}) => {
    return (
        <FilterItemStyled>
            <div className="icon-wrapper-adsd">
                <EuiIcon type={item.iconType} size="m" color="#fff"/>
            </div>
            <div className="value-text-oddz">
                {item.label}
            </div>
        </FilterItemStyled>
    );
};

export const AscDescFilter = ({isAck, onClick}) => {
    return (
        <AscDescFilterStyled onClick={onClick}>
            <EuiIcon type={isAck ? "arrowDown" : "arrowUp"} size="s"/>
            <div className="klawdiuuudd">
                {isAck ? "ASC" : "DESC"}
            </div>
        </AscDescFilterStyled>
    );
};
