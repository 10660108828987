import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import userStore from "../../../stores/user.store";
import settingsStore from "../../../stores/settings.store";
import {doSignInWithEmailAndPassword, signInWithGoogle} from '../../../servises/auth.service';
import LoaderComponent from "../../components/Loader.component";
import {AuthStyled, FormItem} from "./Auth.styled";
import {Link} from "react-router-dom";
import idleCheckRefreshComponent from "../../../servises/idleRefreshToken";
import Logo from "../../components/Logo";
import BecautifulBackgroud from "../../components/BecautifulBackgroud";
import {
    EuiFieldText,
    EuiFieldPassword,
    EuiButton,
    EuiForm,
    EuiFormRow,
    EuiHorizontalRule
} from "@elastic/eui";
import {addToast} from "../../components/toasts/Toast";
import {GoogleIcon} from '../../../style/svg';

class LoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            user: true,
            message: "Logging",
            resetPassword: false,
            errorMessage: "",
            submitLoader: false
        };
    }

    componentDidMount() {
        document.title = "Sign In - SyAgent";
        this.unregisterAuthObserver = firebase.auth()
            .onAuthStateChanged(user => {
                this.setState({user: user});
                if (!user) return;

                user.getIdToken().then(token => {
                    userStore.emailVerified = user.emailVerified;
                    userStore.getCurrentUser(token).then(async data => {
                        if (!data) {
                            this.setState({user: false});
                            return;
                        }
                        // if (!userStore.emailVerified) {
                        //     notificationsStore.add(
                        //         'Please verify your email address', 'info');
                        //     this.proceedToUrl('/verify-email');
                        //     return;
                        // }
                        idleCheckRefreshComponent.registerRefreshToken();
                        this.proceedToUrl();
                    });
                });
            });
    }

    proceedToUrl = url => {
        if (url) {
            this.props.history.push(url);
        } else {
            this.props.history.push(
                settingsStore.redirectRoute
                    ? settingsStore.redirectRoute
                    : "/servers"
            );
        }
    };

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    inputValueChangeHandle(e, type) {
        this.setState({
            [type]: e.target.value
        });
    }

    handleSubmit = e => {
        this.setState({submitLoader: true});
        const {email, password} = this.state;
        this.signIn(email, password);
        e.preventDefault();
    };

    signIn(email, password) {
        doSignInWithEmailAndPassword(email, password).then(resp => {
            console.log(resp);
        }).catch((error) => {
            console.log("pew", error);
            if (error.code === "auth/user-not-found") {
                this.setState({errorMessage: "User not found", submitLoader: false});
                addToast("Authentication failed, user not found",
                    "error"
                );
            } else {
                this.setState(
                    {errorMessage: "Email or password are not valid", submitLoader: false});
                addToast(
                    "Authentication failed, email or password are not valid",
                    "error"
                );
            }
        });

    }

    render() {
        const {email, password, user, message, errorMessage} = this.state;

        return (
            <>
                <Logo/>

                {user && (
                    <LoaderComponent/>
                )}

                <AuthStyled
                    loader={user}
                    className="login">

                    {!user && (
                        <div className="forms-sz">
                            <h1>Login</h1>
                            <EuiForm
                                component="form" className="form">
                                <EuiFormRow label="Email">
                                    <EuiFieldText
                                        value={email}
                                        isInvalid={false}
                                        onChange={e => this.inputValueChangeHandle(
                                            e,
                                            "email"
                                        )}
                                    />
                                </EuiFormRow>

                                {/*<TextField label="Email"*/}
                                {/*           value={ email }*/}
                                {/*           onChange={ e => this.inputValueChangeHandle(*/}
                                {/*               e,*/}
                                {/*               'email'*/}
                                {/*           ) }*/}
                                {/*/>*/}
                                <EuiFormRow label="Password">
                                    <EuiFieldPassword
                                        type="dual"
                                        value={password}
                                        onChange={e => this.inputValueChangeHandle(
                                            e,
                                            "password"
                                        )}
                                    />
                                </EuiFormRow>
                                {/*<TextField label="Password"*/}
                                {/*           value={ password }*/}
                                {/*           type={ 'password' }*/}
                                {/*           canRevealPassword*/}
                                {/*           onChange={ e => this.inputValueChangeHandle(*/}
                                {/*               e,*/}
                                {/*               'password'*/}
                                {/*           ) }*/}
                                {/*/>*/}
                                <FormItem>
                                    <EuiButton
                                        isDisabled={!email || !password}
                                        size="s"
                                        onClick={this.handleSubmit}
                                        fill
                                        isLoading={this.state.submitLoader}
                                    >Sign In</EuiButton>
                                    {/*<PrimaryButton*/}
                                    {/*    text="Sign In"*/}
                                    {/*    onClick={ this.handleSubmit }*/}
                                    {/*/>*/}


                                    <EuiHorizontalRule/>
                                    <span className="ppdaw2">or</span>

                                    <EuiButton
                                        className="add-auth"
                                        size="s"
                                        style={{"backgroundColor": "#ffffff", color: "#757575"}}
                                        onClick={signInWithGoogle}
                                        fill
                                    ><GoogleIcon/> Sign in with Google</EuiButton>
                                </FormItem>

                                {errorMessage && (
                                    <div className="errorMessage">
                                        {errorMessage}
                                    </div>
                                )}

                                <FormItem className="forgot-password">
                                    <Link to={"/forgot-password"}>
                                        Forgot Password
                                    </Link>
                                </FormItem>

                                <FormItem className="register">
                                    <p className="pooolxz">
                                        No account?
                                    </p>
                                    <Link to={"/register"}>
                                        Join
                                    </Link>
                                </FormItem>
                            </EuiForm>
                        </div>
                    )}

                    {!user && (
                        <BecautifulBackgroud/>
                    )}
                </AuthStyled>
            </>
        );
    }
}

export default LoginView;
