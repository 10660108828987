import React from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import tagsStore from '../../../stores/tags.store';
import TagItem from './TagItem';
import serverStore from '../../../stores/server.store';
import { MONITOR_TYPES } from '../../pages/servers/ServersCreate.page';
import uptimeStore from '../../../stores/uptime.store';
import mongodbStore from '../../../stores/mongodb.store';

const Styles = styled.div`
  //display: grid;
`;

const TagsList = ({
    serverTags,
    serverId,
    selectEvent,
    monitorType,
    showMessage = false,
    isServerCreated = false
}) => {
    return useObserver(
        () => (
            <Observed
                tags={ tagsStore.tags }
                serverTags={ serverTags }
                serverId={ serverId }
                monitorType={ monitorType }
                showMessage={ showMessage }
                selectEventOveride={ selectEvent }
                isServerCreated={ isServerCreated }
            />
        ));
};

const Observed = ({
    tags,
    serverId,
    selectEventOveride,
    showMessage,
    isServerCreated,
    monitorType,
    serverTags = []
}) => {
    const selectEvent = async (tagId, isToAdd) => {
        if (selectEventOveride) {
            selectEventOveride(tagId, isToAdd);
        } else {
            if(monitorType === MONITOR_TYPES.SERVER){
                await serverStore.updateServerTags(serverId, tagId, isToAdd,
                    isServerCreated
                );
            }
            if(monitorType === MONITOR_TYPES.HEARTBEAT){
                await uptimeStore.updateUptimeTags(serverId, tagId, isToAdd);
            }
            if(monitorType === MONITOR_TYPES.MONGO){
                await mongodbStore.updateMongoTags(serverId, tagId, isToAdd);
            }
        }
    };

    const noTags = !tags || tags.length === 0;

    return (
        <Styles>
            { noTags && (
                <div>{ `No Tags${ showMessage
                    ? ', to add tags go to edit server'
                    : '' }` }</div>
            ) }
            { !noTags && tags.map((item, idx) => {
                const indexOf = serverTags.indexOf(item._id);

                return (
                    <TagItem
                        selectEvent={ selectEvent }
                        selected={ indexOf > -1 }
                        key={ `${ item._id }_dzjjjawd-tag_${ idx }` }
                        tag={ item }
                    />);
            }) }
        </Styles>
    );
};

export default TagsList;