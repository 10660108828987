import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    EuiButton, EuiFieldNumber,
    EuiFieldText,
    EuiFormFieldset,
    EuiFormRow,
    EuiSelect,
    EuiSwitch, EuiTextArea
} from '@elastic/eui';
import { FooterButtons } from '../../ServerEdit.page';
import { addToast } from '../../../../components/toasts/Toast';
import {
    ContentItemStyled, HEART_BEAT_TYPES,
    ITERVAL_OPTIONS
} from '../../createNewMonitor/CreateNewMonitorComponent';
import validationsService from '../../../../../servises/validations.service';
import uptimeStore from '../../../../../stores/uptime.store';

const Styles = styled.div`
  display: grid;
  align-items: end;
  grid-gap: 30px;

  .left {
    text-align: left;
    margin-bottom: 4px;
  }
`;

const EditUptimeData = ({ uptime }) => {
    const [isSendingSave, setIsSendingSave] = useState(false);
    const [uptimeName, setUptimeName] = useState(uptime.name);
    const [allDisabled, setAllDisabled] = useState(false);
    const [isCustomInterval, setIsCustomIterval] = useState(uptime.isCustomInterval);
    const [customInterval, setCustomIterval] = useState(uptime.customInterval);
    const [interval, setIterval] = useState(null);
    const [url, setUrl] = useState(uptime.url);
    const [validHttpStatus, setValidHttpStatus] = useState(uptime.validHttpStatus);
    const [method, setMethod] = useState(uptime.method);
    const [body, setBody] = useState(uptime.body);
    const [header, setHeader] = useState(uptime.header);

    const heartBeatType = uptime.type;

    useEffect(() => {
        if (!uptime.isCustomInterval) {
            const intervalValue = ITERVAL_OPTIONS.find(item => item.interval === uptime.interval);
            if (intervalValue) {
                setIterval(intervalValue.text);
            }
        }
    }, []);

    const handleSubmit = useCallback(async () => {
        if (isSendingSave) {
            return;
        }

        setIsSendingSave(true);
        setAllDisabled(true);

        let intervalInSeconds = 0;
        if (!isCustomInterval) {
            const intervalValue = ITERVAL_OPTIONS.find(item => item.text === interval);
            intervalInSeconds = intervalValue.interval;
        }

        await uptimeStore.updateUptime(uptime._id, {
            name: uptimeName,
            url,
            validHttpStatus,
            method,
            body,
            header,
            isCustomInterval,
            customInterval,
            interval: intervalInSeconds
        });
        addToast('Uptime changes saved', 'suc');

        setIsSendingSave(false);
        setAllDisabled(false);
    }, [
        isSendingSave,
        heartBeatType,
        allDisabled,
        uptimeName,
        method,
        url,
        interval,
        customInterval,
        isCustomInterval,
        validHttpStatus,
        body,
        header]);

    const change = (type, val) => {
        if (type === 'name') {
            setUptimeName(val);
        }

        if (type === 'customInterval') {
            if (val) {
                const num = Number(val);
                if (num < 59 || Number.isNaN(num)) {
                    setCustomIterval(60);
                } else {
                    setCustomIterval(val);
                }
            } else {
                setCustomIterval(60);
            }
        }
    };

    return (
        <Styles>
            <EuiFormRow label="Monitor Name">
                <EuiFieldText
                    value={ uptimeName }
                    disabled={ allDisabled }
                    isInvalid={ false }
                    onChange={ e => setUptimeName(e.target.value) }
                />
            </EuiFormRow>

            <ContentItemStyled>
                <div style={ { display: 'grid', gridGap: 25 } }>
                    <EuiFormFieldset
                        legend={ { children: 'Custom Interval' } }>
                        <EuiSwitch
                            disabled={ allDisabled }
                            label={ isCustomInterval ? 'On' : 'Off' }
                            checked={ isCustomInterval }
                            onChange={ () => setIsCustomIterval(
                                !isCustomInterval) }
                        />
                    </EuiFormFieldset>

                    <EuiFormRow
                        label="Interval"
                    >
                        <EuiSelect
                            isDisabled={ isCustomInterval || allDisabled }
                            placeholder="Select an option"
                            options={ ITERVAL_OPTIONS }
                            value={ interval }
                            onChange={ (e) => setIterval(e.target.value) }
                        />
                    </EuiFormRow>

                </div>
                <EuiFormRow fullWidth={ true }
                            label="Custom Interval (set value in seconds)">
                    <EuiFieldNumber
                        fullWidth={ true }
                        value={ customInterval }
                        disabled={ !isCustomInterval || allDisabled }
                        onChange={ e => change('customInterval',
                            e.target.value
                        ) }
                    />
                </EuiFormRow>
            </ContentItemStyled>

            { heartBeatType === HEART_BEAT_TYPES.HTTP && (
                <>
                    <ContentItemStyled className={ 'one-row' }>
                        <EuiFormRow fullWidth={ true } label="URL">
                            <EuiFieldText
                                disabled={ allDisabled }
                                fullWidth={ true }
                                isInvalid={ !validationsService.validateUrl(url) }
                                placeholder="http://"
                                value={ url }
                                onChange={ e => setUrl(e.target.value) }
                            />
                        </EuiFormRow>
                    </ContentItemStyled>

                    <ContentItemStyled>
                        <EuiFormRow
                            fullWidth={ true }
                            label="Valid http status"
                        >
                            <EuiFieldNumber
                                disabled={ allDisabled }
                                fullWidth={ true }
                                value={ validHttpStatus }
                                onChange={ e => setValidHttpStatus(e.target.value) }
                            />
                        </EuiFormRow>

                        <EuiFormRow
                            label="Select HTTP Method"
                        >
                            <EuiSelect
                                placeholder="Select an option"
                                disabled={ allDisabled }
                                options={ [
                                    {
                                        key: 'GET',
                                        text: 'GET'
                                    },
                                    {
                                        key: 'POST',
                                        text: 'POST'
                                    },
                                    {
                                        key: 'PUT',
                                        text: 'PUT'
                                    },
                                    {
                                        key: 'PATCH',
                                        text: 'PATCH'
                                    },
                                    {
                                        key: 'DELETE',
                                        text: 'DELETE'
                                    },
                                    {
                                        key: 'HEAD',
                                        text: 'HEAD'
                                    },
                                    {
                                        key: 'OPTIONS',
                                        text: 'OPTIONS'
                                    }
                                ] }
                                value={ method }
                                onChange={ (e) => setMethod(e.target.value) }
                            />
                        </EuiFormRow>
                    </ContentItemStyled>

                    <ContentItemStyled>
                        <EuiFormRow fullWidth={ true }
                                    label="Body (JSON)">
                            <EuiTextArea
                                disabled={ allDisabled }
                                fullWidth={ true }
                                placeholder={ 'Example:\n' +
                                    '{\n' +
                                    '    "key": "value"\n' +
                                    '}' }
                                value={ body }
                                onChange={ e => setBody(
                                    e.target.value) }
                            />
                        </EuiFormRow>

                        <EuiFormRow fullWidth={ true }
                                    label="Headers {JSON}">
                            <EuiTextArea
                                disabled={ allDisabled }
                                fullWidth={ true }
                                placeholder={ 'Example:\n' +
                                    '{\n' +
                                    '    "HeaderName": "HeaderValue"\n' +
                                    '}' }
                                value={ header }
                                onChange={ e => setHeader(
                                    e.target.value) }
                            />
                        </EuiFormRow>
                    </ContentItemStyled>
                </>
            ) }

            { heartBeatType === HEART_BEAT_TYPES.PING && (
                <ContentItemStyled className={ '' }>
                    <EuiFormRow fullWidth={ true } label="IP">
                        <EuiFieldText
                            fullWidth={ true }
                            isInvalid={ !validationsService.validateIp(
                                url) }
                            placeholder="127.0 0.1"
                            value={ url }
                            onChange={ e => setUrl(
                                e.target.value) }
                        />
                    </EuiFormRow>
                </ContentItemStyled>
            ) }

            <FooterButtons className={ 'left' }>
                <EuiButton
                    // isDisabled={ !serverName }
                    size="s"
                    onClick={ handleSubmit }
                    fill
                    isLoading={ isSendingSave }
                >Save
                </EuiButton>
            </FooterButtons>
        </Styles>
    );
};

export default EditUptimeData;