import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import LoaderComponent from '../../components/Loader.component';
import Header from '../../components/header/Header';
import ApiKey from './ApiKey';
import { BoxedResponsiveStyled } from '../../../style/components/common.styled';
import userStore from '../../../stores/user.store';
import ApiDocs from './ApiDocs';

const Styles = styled.div`
  h1 {
    font-size: 36px;
    text-align: center;
  }

  h3 {
    font-size: 26px;
  }
`;

const WrapperStyles = styled.section`
  width: 1000px;
  display: grid;
  grid-gap: 20px;
  margin: 10px auto;
  min-height: 500px;

   @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const ApiPage = () => {
    document.title = 'API - SyAgent';

    return useObserver(() => (
        <ApiPageContent
            sub={ userStore.sub }
            apiKey={ userStore.apiKey }
        />
    ));
};

export default ApiPage;

const ApiPageContent = ({ sub, apiKey }) => {
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const getSub = async () => {
            await userStore.getSub();
        };
        const getApiToken = async () => {
            await userStore.getApiKey();
        };
        getSub();
        getApiToken();
    }, []);

    return (
        <main>
            { loader && (
                <LoaderComponent/>
            ) }

            { !loader && (
                <>
                    <Header/>
                    <Styles>
                        <WrapperStyles>
                            <BoxedResponsiveStyled>
                                <h1>API Reference</h1>

                                <ApiKey sub={ sub } apiKey={ apiKey }/>
                                <ApiDocs/>
                            </BoxedResponsiveStyled>
                        </WrapperStyles>
                    </Styles>
                </>
            ) }
        </main>
    );
};