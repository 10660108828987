import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { EuiButton } from '@elastic/eui';

const ServerStatusStyled = styled.div`
  margin: 10px 10px 10px 14px;
  display: grid;
  grid-template-columns: 1fr auto;

  .ppp {
    color: rgb(0, 128, 213);
    position: relative;

    &:after {
      position: absolute;
      content: '';
      background: rgb(0, 128, 213) !important;
      width: 8px;
      height: 8px;
      border-radius: 30px;
      top: 4px;
      margin-left: 4px;
      animation-name: online-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
  }

  .no-data {
    color: #f9be49;

    &:after {
      background: #f9be49 !important;
    }

  }

  .old-data {
    color: #F86B63;

    &:after {
      background: #F86B63 !important;
    }
  }
`;

const ServerStatus = ({ server, hideEdit, url = 'servers' }) => {
    const history = useHistory();
    if (!server) return (<div/>);

    let classes = server.classes;
    if (!classes && server.serverData) {
        classes = server.serverData.classes;
    }

    let text = 'Online';

    if (classes === 'no-data') {
        text = 'No data received';
    }

    if (classes === 'old-data') {
        text = 'Agent stopped collecting data';
    }

    const navigateToEditMonitor = e => {
        e.preventDefault();
        history.push(`/${ url }/${ server._id }/edit`);
    };

    return (
        <ServerStatusStyled className="server-status">
            <div className={ 'ppp ' + classes }>
                { text }
            </div>

            { !hideEdit && (
                <EuiButton onClick={ navigateToEditMonitor }
                           size="s"
                           iconType={ 'documentEdit' } //play
                           color={ 'text' }>
                    Edit server
                </EuiButton>
            ) }

        </ServerStatusStyled>
    );
};

export default ServerStatus;
