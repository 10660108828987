import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import userStore from '../../../stores/user.store';
import { useObserver } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import serverStore from '../../../stores/server.store';
import HeaderSearch from './HeaderSearch';
import { Icon } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import ChangesModal from '../modal/ChangesModal';
import {
    EuiButton, EuiFlyout,
    EuiFlyoutBody,
    EuiPopover,
    EuiContextMenu,
    useGeneratedHtmlId
} from '@elastic/eui';
import uptimeStore from '../../../stores/uptime.store';
import mongoDbStore from '../../../stores/mongodb.store';

const HeaderStyle = styled.header`
  //background-color: rgb(52, 55, 65);
  position: sticky;
  top: 0;
  z-index: 100;
  
  border-bottom: 1px solid #131417;

  background-color: rgb(29,30,36);
  box-shadow: rgb(0 0 0 / 28%) 0px 0.9px 4px, rgb(0 0 0 / 21%) 0px 2.6px 8px, rgb(0 0 0 / 18%) 0px 5.7px 12px, rgb(0 0 0 / 14%) 0px 15px 15px;

  .donate-link {
    color: rgb(0, 128, 213);
  }
`;

const HeaderSectionStyle = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @media (min-width: 600px) {
    height: 45px;
  }
  padding: 0 10px;
`;

const HeaderSectionLeftStyle = styled.div`
  display: grid;
  grid-template-columns: max-content auto auto 1fr;
  grid-gap: 17px;
  align-items: center;

  .buttonLink {
    width: fit-content;
  }

  .desktop-links {
    @media (max-width: 600px) {
      display: none;
    }
  }
`;

const MenuIconStyled = styled.div`
  display: block;

  path {
    fill: rgb(0, 128, 213);
  }
`;

const HeaderSectionRightStyle = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 17px;
  justify-items: end;

  .pew {
    cursor: pointer;
    padding-right: 20px;
  }

  .desktop-left-menu {
    display: grid;
    grid-template-columns: auto auto auto auto max-content;
    grid-gap: 17px;
    justify-items: end;
    align-items: center;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .donate-link {
    color: rgb(0, 128, 213);
  }

  .mobile-left-menu {
    display: none;
    font-size: 55px;

    @media (max-width: 600px) {
      display: block;
    }
  }

  .buy-me-coffee {
    img {
      height: 35px;
    }
  }
`;

const LogoStyle = styled.div`
  text-transform: capitalize;
  font-size: 24px;

  a {
    text-decoration: none;
  }

  span {
    font-size: 11px;
    margin-left: 7px;
    color: rgb(0, 128, 213);
  }
`;

const Header = (props) => {
    const history = useHistory();

    const [drawlerOpened, setDrawlerOpened] = useState(false);
    const [changesModalShow, setChangesModalShow] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onButtonClick = () =>
        setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    const contextMenuPopoverId = useGeneratedHtmlId({
        prefix: 'contextMenuPopover'
    });

    useEffect(() => {
        const timer = setInterval(async () => {
            await Promise.all([
                serverStore.getServers(true),
                uptimeStore.getUptime(true),
                mongoDbStore.getMongoDb(true)
            ]);
        }, 60000);
        return () => clearInterval(timer);
    }, []);

    const logout = () => {
        return userStore.doLogOut();
    };

    const navigateToNewServer = e => {
        e.preventDefault();
        history.push('/servers-new');
    };

    const emailButton = (<span className="pew put-arrow-down" onClick={ onButtonClick }>
                            { userStore.user ? userStore.user.email : 'No Email' }
                        </span>);

    const panels = [
        {
            id: 0,
            title: 'Settings',
            items: [
                {
                    name: 'Account Settings',
                    onClick: () => {
                        history.push('/settings');
                    }
                },
                {
                    name: 'Support',
                    href: 'https://github.com/syagent/agent-2/issues',
                    target: '_blank'
                },
                {
                    name: 'Suggest',
                    href: 'https://github.com/syagent/agent-2/discussions',
                    target: '_blank'
                },
                {
                    name: 'Logout',
                    onClick: logout
                }
            ]
        }
    ];

    return useObserver(() => (
        <HeaderStyle className="header">
            <HeaderSectionStyle>
                <HeaderSectionLeftStyle>
                    <LogoStyle>
                        <Link to={ `/servers` } className={ 'color-1' }>
                            SyAgent
                            <span>BETA</span>
                        </Link>
                    </LogoStyle>
                    <HeaderSearch/>

                    <EuiButton fill size={ 's' }
                               iconType={ 'plus' }
                               className="desktop-links"
                               onClick={ navigateToNewServer }>Add new
                        monitor</EuiButton>

                </HeaderSectionLeftStyle>
                <HeaderSectionRightStyle>
                    <div className="mobile-left-menu">
                        <MenuIconStyled
                            onClick={ () => setDrawlerOpened(!drawlerOpened) }
                        >
                            <Icon icon="menu" size={ 24 } indent="none"/>
                        </MenuIconStyled>
                        { drawlerOpened && (
                            <>
                                <EuiFlyout
                                    size="l"
                                    ownFocus={ true }
                                    outsideClickCloses={ true }
                                    onClose={ () => setDrawlerOpened(false) }
                                >
                                    <EuiFlyoutBody>
                                        <div className="mobile-menu-wrapper">
                                            <EuiButton fill size={ 's' }
                                                       iconType={ 'plus' }
                                                       className="desktop-links"
                                                       isDisabled={ !serverStore.canAddNewServers }
                                                       onClick={ navigateToNewServer }>Add new
                                                monitor</EuiButton>

                                            <a href="https://www.buymeacoffee.com/syagent" target="_blank" rel="noreferrer" className={ 'header-link buy-me-coffee' }><img src="/assets/bmc-button.png" alt="buy me a coffee"/></a>
                                            <Link to={ `/` }
                                                  className={ 'header-link' }>Monitors
                                                List</Link>

                                            {/*<Link to={ `/donate` }*/ }
                                            {/*      className={ "header-link donate-link" }>Donate</Link>*/ }
                                            {/*<Link to={ `/api` }*/}
                                            {/*      className={ 'header-link' }>API</Link>*/}

                                            <Link to={ `/alarms-settings` }
                                                  className={ 'header-link' }>Alarms
                                                Settings</Link>

                                            <Link to={ `/settings` }
                                                  className={ 'header-link' }>Settings</Link>

                                            <a target="_blank" rel="noreferrer"
                                               href="https://github.com/syagent/agent-2/issues">Support</a>
                                            <a target="_blank" rel="noreferrer"
                                               href="https://github.com/syagent/agent-2/discussions">Suggest</a>

                                            <div className="logOut"
                                                 onClick={ logout }>Logout
                                            </div>
                                        </div>
                                    </EuiFlyoutBody>
                                </EuiFlyout>
                            </>
                        ) }

                    </div>
                    <div className="desktop-left-menu">
                        {/*<Link to={ `#` }*/ }
                        {/*      onClick={ () => setChangesModalShow(true) }*/ }
                        {/*      className={ 'header-link' }>Changes</Link>*/ }
                        <a href="https://www.buymeacoffee.com/syagent" target="_blank" rel="noreferrer" className={ 'header-link buy-me-coffee' }><img src="/assets/bmc-button.png" alt="buy me a coffee"/></a>
                        <Link to={ `/` }
                              className={ 'header-link' }>Monitors</Link>
                        {/*<Link to={ `/donate` }*/ }
                        {/*      className={ "header-link donate-link" }>Donate</Link>*/ }
                        {/*<Link to={ `/api` }*/}
                        {/*      className={ 'header-link' }>API</Link>*/}
                        <Link to={ `/alarms-settings` }
                              className={ 'header-link' }>Alarms Settings</Link>
                        {/*<Popover content={*/ }
                        {/*    <Menu className={ 'header-menu' }>*/ }
                        {/*        <MenuItem text="Account Settings"*/ }
                        {/*                  onClick={ () => history.push(*/ }
                        {/*                      '/settings') }/>*/ }
                        {/*        <MenuItem text="Support" target="_blank"*/ }
                        {/*                  href="https://github.com/syagent/agent-2/issues"/>*/ }
                        {/*        <MenuItem text="Suggest" target="_blank"*/ }
                        {/*                  href="https://github.com/syagent/agent-2/discussions"/>*/ }
                        {/*        <Menu.Divider/>*/ }
                        {/*        <MenuItem text="Logout" onClick={ logout }/>*/ }
                        {/*    </Menu>*/ }
                        {/*} position={ Position.BOTTOM }>*/ }
                        {/*    <span*/ }
                        {/*        className="pew put-arrow-down">{ userStore.user*/ }
                        {/*        ? userStore.user.email*/ }
                        {/*        : 'No Email' }</span>*/ }
                        {/*</Popover>*/ }

                        <EuiPopover
                            id={ contextMenuPopoverId }
                            button={ emailButton }
                            isOpen={ isPopoverOpen }
                            panelPaddingSize="none"
                            closePopover={ closePopover }
                        >
                            <EuiContextMenu initialPanelId={ 0 } panels={ panels }/>
                        </EuiPopover>
                    </div>
                </HeaderSectionRightStyle>
            </HeaderSectionStyle>
            { changesModalShow && (
                <ChangesModal close={ () => setChangesModalShow(false) }/>
            ) }
        </HeaderStyle>
    ));
};

export default Header;
