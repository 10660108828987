import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { EuiButton } from '@elastic/eui';
import uptimeStore from '../../../stores/uptime.store';

const ServerStatusStyled = styled.div`
  margin: 10px 10px 10px 14px;
  display: grid;
  grid-template-columns: 1fr auto;

  .ppp {
    color: rgb(0, 128, 213);
    position: relative;

    &:after {
      position: absolute;
      content: '';
      background: rgb(0, 128, 213) !important;
      width: 8px;
      height: 8px;
      border-radius: 30px;
      top: 4px;
      margin-left: 4px;
      animation-name: online-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
  }

  .no-data, .disabled {
    color: #f9be49;

    &:after {
      background: #f9be49 !important;
    }

  }

  .old-data {
    color: #F86B63;

    &:after {
      background: #F86B63 !important;
    }
  }
`;

const LeftSideOfTheMoonStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
`;

const UptimeStatus = ({ item, hideEdit }) => {
    const history = useHistory();
    const [isSettingStatus, setIsSettingStatus] = useState(false);

    const uptimeStatusHandle = useCallback(async () => {
        if (isSettingStatus) return;
        setIsSettingStatus(true);

        await uptimeStore.updateUptime(item._id, {
            isEnabled: !item.isEnabled
        });
        setIsSettingStatus(false);
    }, [item, isSettingStatus]);

    if (!item) return (<div/>);

    const classes = item.classes;

    let text = 'Unknown';

    if (classes === 'normal') {
        text = 'Up';
    }

    if (classes === 'disabled') {
        text = 'Disabled';
    }

    if (classes === 'no-data') {
        text = '';
    }

    if (classes === 'old-data') {
        text = 'Down';
    }

    const navigateToEditMonitor = e => {
        e.preventDefault();
        history.push(`/uptime/${ item._id }/edit`);
    };

    return (
        <ServerStatusStyled className="server-status">
            <div className={ 'ppp ' + classes }>
                { text }
            </div>

            <LeftSideOfTheMoonStyled>
                <EuiButton onClick={ uptimeStatusHandle }
                           size="s"
                           isLoading={ isSettingStatus }
                           iconType={ item.isEnabled ? 'pause' : 'play' } //play
                           color={ item.isEnabled ? 'warning' : 'success' }
                >
                    { item.isEnabled ? 'Pause' : 'Enable' }
                </EuiButton>
                { !hideEdit && (
                    <EuiButton onClick={ navigateToEditMonitor }
                               size="s"
                               iconType={ 'documentEdit' } //play
                               color={ 'text' }>
                        Edit uptime
                    </EuiButton>
                ) }
            </LeftSideOfTheMoonStyled>
        </ServerStatusStyled>
    );
};

export default UptimeStatus;
