import React, { useEffect, useState } from 'react';
import Header from '../../../components/header/Header';
import LoaderComponent from '../../../components/Loader.component';
import TagsModal from '../../../components/tags/TagsModal';
import styled from 'styled-components';
import { MONITOR_TYPES } from '../ServersCreate.page';
import CreateNewServerComponent from './CreateNewServerComponent';
import CreateNewMonitorComponent from './CreateNewMonitorComponent';
import { EuiTab, EuiTabs } from '@elastic/eui';
import CreateNewMongoMonitorComponent from './CreateNewMongoMonitorComponent';
import serverStore from '../../../../stores/server.store';
import tagsStore from '../../../../stores/tags.store';
import uptimeStore from '../../../../stores/uptime.store';
import mongoDbStore from '../../../../stores/mongodb.store';

const Styles = styled.main`
  .done {
    margin-top: 40px;
  }
`;

const ChooseMonitorStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
`;

const ChooseMonitorWrapStyled = styled.div`
  width: 300px;
  margin: 30px auto;
  text-align: center;

  h3 {
    font-size: 23px;
    margin-bottom: 22px;
  }
`;

const ChooseMonitorItemStyled = styled.div`
  cursor: pointer;
  position: relative;

  span {
    border-bottom: 2px solid transparent;
    padding-bottom: 5px;
  }

  &.active {
    color: #48aff0;

    span {
      border-color: #48aff0;
    }
  }

  &.beta {
    &::after {
        content: 'beta';
        position: absolute;
        top: 0;
        color: rgb(0,188,212);
        font-size: 10px;
        padding: 2px 5px;
        border-radius: 5px;
    }
  }

  //&.oir_0 {
  //  text-align: right;
  //}
  //
  //&.oir_1 {
  //  text-align: left;
  //}

  &.disabled {
    cursor: default;
    color: rgb(255 255 255 / 15%);
  }
`;

const CreateServerObserved = ({ canAddNewServers, canAddNewUptime, canAddNewMongoDb }) => {
    const [loader, setLoader] = useState(false);
    const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
    const [typeOfMonitor, setTypeOfMonitor] = useState(MONITOR_TYPES.SERVER);

    useEffect(async () => {
        const getsData = () => {
            return Promise.all([
                serverStore.getServers(),
                tagsStore.getTags(),
                uptimeStore.getUptime(),
                mongoDbStore.getMongoDb()
            ]);
        };
        await getsData();
        setLoader(false);
    }, []);

    return (
        <Styles>
            <Header/>
            { loader && (
                <LoaderComponent/>
            ) }

            <ChooseMonitorWrapStyled>
                <h3>Choose monitor type</h3>

                <ChooseMonitorStyled>
                    { Object.values(MONITOR_TYPES).map((type, idx) => {
                        let isDisabled = false;

                        if (type === MONITOR_TYPES.HEARTBEAT) {
                            isDisabled = !canAddNewUptime;
                        } else if (type === MONITOR_TYPES.SERVER) {
                            isDisabled = !canAddNewServers;
                        } else if (type === MONITOR_TYPES.MONGO) {
                            isDisabled = !canAddNewMongoDb;
                        }

                        let styles = `oir_${ idx }`;

                        if (type === typeOfMonitor) {
                            styles += ` active`;
                        }

                        if (isDisabled) {
                            styles += ` disabled`;
                        }

                        if (type === MONITOR_TYPES.MONGO) {
                            styles += ` beta`;
                        }

                        return (
                            <ChooseMonitorItemStyled
                                onClick={ () => {
                                    if (isDisabled) {
                                        return;
                                    }
                                    setTypeOfMonitor(type);
                                } }
                                className={ styles }
                                key={ `dkkkkz_${ idx }` }
                            >
                                <span>{ type }</span>
                            </ChooseMonitorItemStyled>
                        );
                    }) }
                </ChooseMonitorStyled>
            </ChooseMonitorWrapStyled>

            { typeOfMonitor === MONITOR_TYPES.SERVER && (
                <CreateNewServerComponent
                    setIsTagsModalOpen={ setIsTagsModalOpen }/>
            ) }

            { typeOfMonitor === MONITOR_TYPES.HEARTBEAT && (
                <CreateNewMonitorComponent
                    setIsTagsModalOpen={ setIsTagsModalOpen }/>
            ) }

            { typeOfMonitor === MONITOR_TYPES.MONGO && (
                <CreateNewMongoMonitorComponent
                    setIsTagsModalOpen={ setIsTagsModalOpen }
                />
            ) }

            { isTagsModalOpen && (
                <TagsModal close={ () => setIsTagsModalOpen(false) }/>
            ) }
        </Styles>
    );
};

export default CreateServerObserved;