import React, { useCallback, useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import tagsStore from '../../../stores/tags.store';
import alarmsStore from '../../../stores/alarms.store';
import uptimeStore from '../../../stores/uptime.store';
import LoaderComponent from '../../components/Loader.component';
import Header from '../../components/header/Header';
import {
    DeleteServerZoneStyled,
    EditZoneStyled,
    StyledEditWrapper,
    TagsWrapperStyled
} from './ServerEdit.page';
import { BoxedResponsiveStyled, GoBackStyled } from '../../../style/components/common.styled';
import serverStore from '../../../stores/server.store';
import TagsList from '../../components/tags/TagsList';
import { EuiButton } from '@elastic/eui';
import AlarmsSettings from '../../components/alarmsSettings/AlarmsSettings';
import TagsModal from '../../components/tags/TagsModal';
import EditUptimeData from './components/serverEdit/EditUptimeData';
import { useHistory } from 'react-router-dom';
import { addToast } from '../../components/toasts/Toast';
import { MONITOR_TYPES } from './ServersCreate.page';
import mongoDbStore from "../../../stores/mongodb.store";

const UptimeEditPage = props => {
    document.title = 'Uptime Edit - SyAgent';
    const { uptimeId } = props.match.params;

    const redirect = () => {
        props.history.push('/servers');
    };

    return useObserver(() => (
        <Observed
            tags={ tagsStore.tags }
            uptimeId={ uptimeId }
            handleRedirect={ redirect }
            items={ uptimeStore.uptimeListObj }
            serverAlarmsSettingsObj={ alarmsStore.alarmsSettingsByServer }
        />
    ));
};

export default UptimeEditPage;

const Observed = props => {
    const history = useHistory();

    const { tags, uptimeId, handleRedirect, items, serverAlarmsSettingsObj } = props;

    const [loader, setLoader] = useState(true);
    const [areYouSureState, setAreYouSureState] = useState(false);
    const [deleteSending, setDeleteSending] = useState(false);
    const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);

    const handleDelete = useCallback(async e => {
        if (deleteSending) {
            return;
        }

        setDeleteSending(true);
        await uptimeStore.deleteMonitor(uptimeId);
        addToast(
            'Monitor deleted', 'suc');
        handleRedirect();

    }, [deleteSending]);

    const goSomewhere = e => {
        e.preventDefault();
        history.push(`/uptime/${ uptimeId }`);
    };

    const uptime = items[uptimeId];

    useEffect(async () => {
        const getsData = () => {
            return Promise.all([
                serverStore.getServers(),
                tagsStore.getTags(),
                alarmsStore.getAlarmsSettings(uptimeId),
                uptimeStore.getUptime(),
                mongoDbStore.getMongoDb()
            ]);
        };
        await getsData();
        setLoader(false);
    }, []);

    return (
        <main className="servers">
            { loader && (
                <LoaderComponent/>
            ) }
            { !loader && (
                <>
                    <Header/>
                    <StyledEditWrapper>
                        <GoBackStyled>
                            <EuiButton onClick={ goSomewhere }
                                       size="s"
                                       iconType={ 'returnKey' }
                                       color={ 'text' }>
                                Go back to Uptime
                            </EuiButton>
                        </GoBackStyled>
                        <BoxedResponsiveStyled>
                            <h1>Monitor management</h1>
                            <EditZoneStyled className="bonya">
                                <h3>Tags (select)</h3>
                                <TagsWrapperStyled>
                                    { uptime && (
                                        <TagsList
                                            serverId={ uptimeId }
                                            monitorType={ MONITOR_TYPES.HEARTBEAT }
                                            serverTags={ uptime.tags }
                                        />
                                    ) }
                                </TagsWrapperStyled>
                                <EuiButton
                                    size="s"
                                    onClick={ () => setIsTagsModalOpen(true) }
                                    fill
                                >
                                    Edit or Add tags
                                </EuiButton>

                            </EditZoneStyled>

                            <EditZoneStyled className="bonya">
                                <h3>Edit Monitor settings</h3>
                                { uptime && (
                                    <EditUptimeData
                                        uptime={ uptime }
                                    />
                                ) }
                            </EditZoneStyled>

                            <DeleteServerZoneStyled className="bonya">
                                <h3>Danger zone</h3>
                                { !areYouSureState && (
                                    <EuiButton
                                        size="s"
                                        iconType={ 'trash' }
                                        color="danger"

                                        onClick={ () => setAreYouSureState(
                                            true) }
                                        fill
                                    >Delete Monitor
                                    </EuiButton>
                                ) }

                                { areYouSureState && (
                                    <>
                                        <div
                                            className="are-you-sure">Are you
                                            sure?
                                        </div>
                                        <EuiButton
                                            size="s"
                                            color="danger"

                                            onClick={ handleDelete }
                                            fill
                                        >
                                            Yes
                                        </EuiButton>

                                        <EuiButton
                                            size="s"
                                            onClick={ () => setAreYouSureState(
                                                false) }
                                            fill
                                        >
                                            No
                                        </EuiButton>
                                    </>
                                ) }
                            </DeleteServerZoneStyled>
                        </BoxedResponsiveStyled>
                    </StyledEditWrapper>
                </>
            ) }
            { isTagsModalOpen && (
                <TagsModal close={ () => setIsTagsModalOpen(false) }/>
            ) }
        </main>
    );
};
