import React from 'react';
import { ModalMainStyled } from './modal.styled';
import { EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle } from '@elastic/eui';
import utilsService from '../../../servises/utils.service';
import ServerComInfo from '../../pages/servers/components/ServerComInfo';

const MongoCollectionStatsModal = ({ data, close }) => {
    const { name, nindexes, count, avgObjSize, size, storageSize, totalIndexSize, totalSize, indexSizes, capped } = data;

    const formatedTotalSize = utilsService.formatBytes(totalSize);
    const formatedSize = utilsService.formatBytes(size);
    const formatedStorageSize = utilsService.formatBytes(storageSize);
    const formatedTotalIndexSize = utilsService.formatBytes(totalIndexSize);
    const formatedAvSize = utilsService.formatBytes(avgObjSize);

    return (
        <ModalMainStyled>
            <EuiModal onClose={ close }>
                <EuiModalHeader>
                    <EuiModalHeaderTitle><h1>{ name }</h1></EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    {avgObjSize && (
                        <ServerComInfo
                            name={ 'Average Object size' }
                            data={ `${ formatedAvSize.value } ${ formatedAvSize.unit }` }
                        />
                    )}
                    <ServerComInfo
                        name={ 'Capped' }
                        data={ capped ? 'Yes' : 'No' }
                    />
                    <ServerComInfo
                        name={ 'Total Objects' }
                        data={ count }
                    />
                    <ServerComInfo
                        name={ 'Total Indexes' }
                        data={ nindexes }
                    />
                    <ServerComInfo
                        name={ 'Total Size' }
                        data={ `${ formatedTotalSize.value } ${ formatedTotalSize.unit }` }
                    />
                    <ServerComInfo
                        name={ 'Size' }
                        data={ `${ formatedSize.value } ${ formatedSize.unit }` }
                    />
                    <ServerComInfo
                        name={ 'Storage Size' }
                        data={ `${ formatedStorageSize.value } ${ formatedStorageSize.unit }` }
                    />
                    <ServerComInfo
                        name={ 'Total Index Size' }
                        data={ `${ formatedTotalIndexSize.value } ${ formatedTotalIndexSize.unit }` }
                    />
                </EuiModalBody>
            </EuiModal>

        </ModalMainStyled>
    );
};

export default MongoCollectionStatsModal;