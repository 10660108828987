import dayjs from 'dayjs';

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

class UtilsService {
    getTimeOut(time, asString = false) {
        const total = time - Date.now();
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        let output = [];
        let outputStr = '';

        if (days) {
            outputStr += `${ days }d `;
            output.push(`${ days }d`);
        }

        if (hours) {
            outputStr += `${ hours }h `;
            output.push(`${ hours }h`);
        }

        if (minutes) {
            outputStr += `${ minutes }m `;
            output.push(`${ minutes }m`);
        }

        if (seconds) {
            outputStr += `${ seconds }s `;
            output.push(`${ seconds }s`);
        }

        if (total <= 0) {
            outputStr = 'Calc';
            output = [`Calc`];
        }

        return asString ? outputStr : output;
    }

    calculateEveryTime(uptime) {
        let result = '';

        let interval = uptime.interval;
        if (uptime.isCustomInterval) {
            interval = uptime.customInterval;
        }

        const minutes = Math.floor((interval / 60) % 60);
        const hours = Math.floor((interval / (60 * 60)) % 24);
        const days = Math.floor(interval / (60 * 60 * 24));

        if (days) {
            result = `Every ${ days } days`;
            if (hours) {
                result += ` ${ hours } hours`;
            }
            if (minutes) {
                result += ` ${ minutes } minutes`;
            }
        }

        if (hours) {
            result = `Every ${ hours } hours`;
            if (minutes) {
                result += ` ${ minutes } minutes`;
            }
        }

        if (minutes) {
            result = `Every ${ minutes } minutes`;
        }
        return result;
    }

    formatSecondsToDate(seconds) {
        const days = Math.floor(seconds / (24 * 60 * 60));
        const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((seconds % (60 * 60)) / 60);
        const remainingSeconds = seconds % 60;
        const parts = [];
        if (days) {
            parts.push(`${days} day${days > 1 ? 's' : ''}`);
        }
        if (hours) {
            parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
        }
        if (minutes) {
            parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
        }
        if (remainingSeconds) {
            parts.push(`${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`);
        }
        return parts.join(', ');
    }

    doMeAFavor(item) {
        return JSON.parse(JSON.stringify(item));
    }

    timeAgo(time) {
        return dayjs(time).fromNow();
    }

    getPercent = (cur, total) => {
        return parseInt(cur * 100 / total, 10);
    };

    getFromMetrics = (server, key, pewka = 'N/A') => {
        let response = pewka;

        if (server && server.serverData &&
            server.serverData.agentPushedTheData) {
            if (server.metrics[0]) {
                response = server.metrics[0][key];
            }
        }
        return response;
    };

    formatBytes = (bytes, decimals) => {
        const response = { value: 0, unit: '' };
        // == pew
        if (bytes === 0) {
            response.unit = 'Bytes';
            return response;
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        response.value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
        response.unit = sizes[i];

        return response;
    };

    formatByTesByUnit(bytes, unit, decimals) {
        if (bytes === 0) {
            return 0;
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const currentIndex = sizes.indexOf(unit);

        if (currentIndex > 0) {
            return parseFloat((bytes / Math.pow(k, currentIndex)).toFixed(dm));
        } else {
            return 0;
        }

    }

    normalizeApiData = data => {
        const tempData = {};
        for (let i = 0; i < data.length; i++) {
            tempData[data[i]['_id']] = data[i];
        }
        return tempData;
    };

    mergeApiData = (dataFromApi, localData) => {
        return Object.assign(localData, dataFromApi);
    };

    dateMe = date => {
        return dayjs(date).format('DD.MM.YYYY');
    };

    timeMe = date => {
        return dayjs(date).format('HH:mm');
    };

    dateMeWithTime = date => {
        return dayjs(date).format('HH:mm, DD.MM.YYYY');
    };

    queryParamsParser = params => {
        let res = {};
        if (params) {
            const removedAskSign = params.split('?')[1];
            const queries = removedAskSign.split('&');
            queries.forEach(query => {
                const splitedQuery = query.split('=');
                res[splitedQuery[0]] = splitedQuery[1];
            });
        }

        return res;
    };

    calculateInterval(interval, customInterval, isCustomInterval) {
        let result = '';
        let rightInterval = interval;
        if (isCustomInterval) {
            rightInterval = customInterval;
        }

        return result;
    }
}

const utilsService = new UtilsService();
export default utilsService;