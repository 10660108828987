import React from 'react';
import styled from 'styled-components';
import utilsService from '../../../../../servises/utils.service';
import ServerComInfo from '../ServerComInfo';

const Styles = styled.div`

`;

function generateData(server) {
    const rx = utilsService.getFromMetrics(server, 'rx');
    const tx = utilsService.getFromMetrics(server, 'tx');
    const rxGap = utilsService.getFromMetrics(server, 'rx_gap');
    const txGap = utilsService.getFromMetrics(server, 'tx_gap');

    const rx_ = utilsService.formatBytes(rx);
    const tx_ = utilsService.formatBytes(tx);
    const rxGap_ = utilsService.formatBytes(rxGap);
    const txGap_ = utilsService.formatBytes(txGap);

    return [
        {
            title: 'Connections',
            data: utilsService.getFromMetrics(server, 'connections')
        },
        {
            title: 'Nic',
            data: utilsService.getFromMetrics(server, 'nic')
        },
        {
            title: 'Data Received',
            data: `${rxGap_.value} ${rxGap_.unit}`
        },
        {
            title: 'Data Sent',
            data: `${txGap_.value} ${txGap_.unit}`
        },
        {
            title: 'Total Data Received',
            data: `${rx_.value} ${rx_.unit}`
        },
        {
            title: 'Total Data Sent',
            data: `${tx_.value} ${tx_.unit}`
        }
    ];
}

// rx - receive, tx - transfer
const ServerNetworkWidget = ({ server }) => {
    const data = generateData(server);

    return (
        <Styles>
            { data.map((item, idx) => {
                return (
                    <ServerComInfo
                        key={ idx + '_ddw2-s22' }
                        name={ item.title }
                        data={ item.data }
                    />
                );
            }) }
        </Styles>
    );
};

export default ServerNetworkWidget;