import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
    RunCodeStyled
} from '../../../../style/components/common.styled';
import userStore from '../../../../stores/user.store';
import { Toggle } from '@fluentui/react';
import MenuSettingsComponent
    from '../../../components/menuSettings/MenuSettings.component';
import { alarmsSettingsTypes } from '../../../../constants/data.types';
import {
    EuiSwitch,
    EuiFormFieldset,
    EuiSpacer,
    EuiFieldText, EuiFormRow, EuiButton, EuiCodeBlock, EuiCopy
} from '@elastic/eui';
import { addToast } from '../../../components/toasts/Toast';

const Styles = styled.div`
  margin: 20px 10px;
`;

const MoreDetailsStyles = styled.div`
  background: #122330;
  padding: 5px 10px;

  &.telegram {
    a {
      display: block;
      margin-bottom: 17px;
    }
  }
`;

const TelegaStyled = styled.div`
  h3 {
    text-align: center;
  }
`;

const ItemStyles = styled.div`
  margin: 25px 0;

  //label {
  //  width: fit-content;
  //  font-size: 16px;
  //}

  .soon {
    font-size: 13px;
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }

  .alarms-toggle {
    margin-top: 20px;
  }

  .alarms-settings-toggle {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: baseline;
  }

  .code {
    white-space: pre-wrap;
    background-color: black;
    color: white;
    font-size: medium;
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
    width: 100%;
    display: inline-block;
    line-height: 1.4;
    margin: 10px 0 35px;
    padding: 10px;
  }

  .webhook-alarm {
    .form {
      margin-top: 40px;
    }
  }

  h3 {
    margin-top: 40px;
  }
`;

const AlertsSection = props => {
    const { user } = props;
    const { notifications, nmetaData } = userStore.user;
    const { email, telegram, webhook, pushover, pushsafer } = notifications;

    const [isSendingSave, setIsSendingSave] = useState(false);
    const [copied, setCopied] = useState(false);

    const handleChange = useCallback(async (key, data, metaData) => {
        if (isSendingSave) {
            return;
        }

        setIsSendingSave(true);
        await userStore.updateNotifications(key, data, metaData);
        addToast(
            'Alarms settings changed', 'suc');

        setIsSendingSave(false);

    }, [isSendingSave, user]);

    return (
        <Styles>
            <MenuSettingsComponent
                title={ 'Alarms' }
                menu={ [
                    {
                        title: 'Alarms settings'
                    },
                    {
                        title: 'Email'
                    },
                    {
                        title: 'Webhook'
                    },
                    {
                        title: 'Telegram'
                    },
                    {
                        title: 'Pushover'
                    },
                    {
                        title: 'Pushsafer'
                    },
                    {
                        title: 'Whatsapp',
                        subTitle: 'Soon',
                        disabled: true
                    },
                    {
                        title: 'Slack',
                        subTitle: 'Soon',
                        disabled: true
                    },
                    {
                        title: 'Signal',
                        subTitle: 'Soon',
                        disabled: true
                    }
                ] }
                content={ [
                    <ItemStyles>
                        <div>Alarms Notifications are now per server, so it's
                            moved to each server edit
                        </div>
                    </ItemStyles>,
                    <ItemStyles>
                        <EmailAlert
                            data={ email }
                            handleChange={ handleChange }
                        />
                    </ItemStyles>,
                    <ItemStyles>
                        <Webhook
                            data={ webhook }
                            nmetaData={ nmetaData }
                            handleChange={ handleChange }
                        />
                    </ItemStyles>,
                    <ItemStyles>
                        <TelegramAlert
                            data={ telegram }
                            handleChange={ handleChange }
                            copied={ copied }
                            set={ setCopied }
                        />
                    </ItemStyles>,
                    <ItemStyles>
                        <PushoverAlert
                            nmetaData={ nmetaData }
                            data={ pushover }
                            handleChange={ handleChange }
                        />
                    </ItemStyles>,
                    <ItemStyles>
                        <PushSaferAlert
                            nmetaData={ nmetaData }
                            data={ pushsafer }
                            handleChange={ handleChange }
                        />
                    </ItemStyles>
                ] }
            />

        </Styles>
    );
};

export default AlertsSection;

const EmailAlert = ({ data, handleChange }) => {
    return (
        <div>
            <h2>Email</h2>
            <p>You will get server alarms from alarms@syagent.com.</p>
            <p>We don't use dedicated IP in our email service, so emails can go
                to spam folder.
            </p>

            <EuiSpacer size="xl"/>

            <EuiFormFieldset legend={ { children: 'Receive alarms by email' } }>
                <EuiSwitch
                    label={ data ? 'On' : 'Off' }
                    checked={ data }
                    onChange={ () => handleChange('email', !data) }
                />
            </EuiFormFieldset>
        </div>
    );
};

const Webhook = ({ data, handleChange, nmetaData }) => {

    const webhook = nmetaData ? nmetaData.webhook : undefined;

    const [webhookUrl, setWebhookUrl] = useState(webhook || '');
    const [toggle, setToggle] = useState(data);

    const handleSubmit = e => {
        e.preventDefault();
        const metaData = {
            webhook: webhookUrl
        };
        handleChange('webhook', toggle, metaData);
    };

    return (
        <div className="webhook-alarm">
            <h2>Webhook</h2>

            <EuiFormFieldset
                legend={ { children: 'Receive alarms by webhook' } }>
                <EuiSwitch
                    label={ toggle ? 'On' : 'Off' }
                    checked={ toggle }
                    onChange={ () => setToggle(!toggle) }
                />
            </EuiFormFieldset>

            <EuiSpacer size="xl"/>

            <EuiFormRow label="Webhook
                    URL">
                <EuiFieldText
                    value={ webhookUrl }
                    isInvalid={ false }
                    onChange={ e => setWebhookUrl(e.target.value) }
                />
            </EuiFormRow>

            <EuiButton
                isDisabled={ !webhookUrl }
                size="s"
                color="success"
                onClick={ handleSubmit }
                fill
            >
                Save
            </EuiButton>

            <h3>POST payload example</h3>

            <EuiCodeBlock language="jsx" fontSize="m" paddingSize="m">
                { JSON.stringify({
                        'server_name': 'Server Name',
                        'severity': 'Critical',
                        'message': 'Agent stopped receiving data',
                        'date': '2020-10-16T09:28:01.950Z'
                    },
                    null, 2
                ) }
            </EuiCodeBlock>
        </div>
    );
};

const TelegramAlert = ({ data, handleChange, set, copied }) => {
    return (
        <div>
            <h2>Telegram</h2>
            <p>You will get server alarms from syagent telegram bot</p>

            <EuiSpacer size="l"/>

            <EuiFormFieldset
                legend={ { children: 'Receive alarms by telegram' } }>
                <EuiSwitch
                    label={ data ? 'On' : 'Off' }
                    checked={ data }
                    onChange={ () => handleChange('telegram', !data) }
                />
            </EuiFormFieldset>

            <EuiSpacer size="xl"/>

            { data && (
                <TelegaStyled>
                    <h3>If you want to receive personal message from BOT</h3>
                    <MoreDetailsStyles className="telegram">
                        <RunCodeStyled>
                            <a href="https://t.me/syagent_bot"
                               rel="noreferrer"
                               target="_blank">Link to SyAgent bot</a>
                            Please start the bot and send
                            <EuiSpacer size="xl"/>
                            <EuiCodeBlock
                                language="jsx" fontSize="m" paddingSize="m">
                                !id
                                <span> { userStore.user._id }</span>
                            </EuiCodeBlock>
                            <EuiSpacer size="xl"/>
                            <EuiCopy textToCopy={ `!id ${ userStore.user._id }` }>
                                { copy => (
                                    <EuiButton iconType={ 'copyClipboard' }
                                               onClick={ copy } color={ 'success' }>Click
                                        to
                                        copy</EuiButton>
                                ) }
                            </EuiCopy>
                        </RunCodeStyled>
                    </MoreDetailsStyles>

                    <h3>You can also add bot to your group</h3>
                    <MoreDetailsStyles className="telegram">
                        <RunCodeStyled>
                            Add @syagent_bot to your group and run following code in your group
                            <EuiSpacer size="xl"/>
                            <EuiCodeBlock
                                language="jsx" fontSize="m" paddingSize="m">
                                /id@syagent_bot
                                <span> { userStore.user._id }</span>
                            </EuiCodeBlock>
                            <EuiSpacer size="xl"/>
                            <EuiCopy textToCopy={ `/id@syagent_bot ${ userStore.user._id }` }>
                                { copy => (
                                    <EuiButton iconType={ 'copyClipboard' }
                                               onClick={ copy } color={ 'success' }>Click
                                        to
                                        copy</EuiButton>
                                ) }
                            </EuiCopy>
                        </RunCodeStyled>
                    </MoreDetailsStyles>
                </TelegaStyled>
            ) }
        </div>
    );
};

const AlarmsSettings = ({ data, handleChange }) => {
    return (
        <div>
            <h2>Alarms Settings</h2>
            <p>Set type of alarm you prefer to get notification.</p>

            { alarmsSettingsTypes.map((item, idx) => {
                return (
                    <Toggle
                        key={ idx + 'zkkdw' }
                        className="alarms-settings-toggle"
                        label={ item.title } checked={ data[item.key] }
                        onText="On"
                        offText="Off"
                        onChange={ () => handleChange(item.key,
                            !data[item.key]
                        ) }/>
                );
            }) }
        </div>
    );
};

const PushoverAlert = ({ data, handleChange, nmetaData }) => {

    const pushover = nmetaData ? nmetaData.pushover : undefined;

    const [userToken, setUserToken] = useState(pushover || '');
    const [toggle, setToggle] = useState(!!data);

    const handleSubmit = e => {
        e.preventDefault();
        const metaData = {
            pushover: userToken
        };
        handleChange('pushover', toggle, metaData);
    };

    return (
        <div className="pushover-alarm">
            <h2>Pushover</h2>

            <p>Sign up in <a href="https://pushover.net/" target="_blank" rel="noreferrer">Pushover website</a>, copy the USER KEY after you install mobile app.</p>
            <br/>
            <br/>
            <EuiFormFieldset
                legend={ { children: 'Receive alarms in Pushover' } }>
                <EuiSwitch
                    label={ toggle ? 'On' : 'Off' }
                    checked={ toggle }
                    onChange={ () => setToggle(!toggle) }
                />
            </EuiFormFieldset>

            <EuiSpacer size="xl"/>

            <EuiFormRow label="User Key">
                <EuiFieldText
                    value={ userToken }
                    isInvalid={ false }
                    onChange={ e => setUserToken(e.target.value) }
                />
            </EuiFormRow>

            <EuiButton
                isDisabled={ !userToken }
                size="s"
                color="success"
                onClick={ handleSubmit }
                fill
            >
                Save
            </EuiButton>
        </div>
    );
};

const PushSaferAlert = ({ data, handleChange, nmetaData }) => {

    const pushsafer = nmetaData ? nmetaData.pushsafer : undefined;

    const [userToken, setUserToken] = useState(pushsafer || '');
    const [toggle, setToggle] = useState(!!data);

    const handleSubmit = e => {
        e.preventDefault();
        const metaData = {
            pushsafer: userToken
        };
        handleChange('pushsafer', toggle, metaData);
    };

    return (
        <div className="pushover-alarm">
            <h2>Pushover</h2>

            <p>Sign up in <a href="https://www.pushsafer.com/" target="_blank" rel="noreferrer">Pushsafer website</a>, copy the PRIVATE KEY.</p>
            <br/>
            <br/>
            <EuiFormFieldset
                legend={ { children: 'Receive alarms in Pushsafer' } }>
                <EuiSwitch
                    label={ toggle ? 'On' : 'Off' }
                    checked={ toggle }
                    onChange={ () => setToggle(!toggle) }
                />
            </EuiFormFieldset>

            <EuiSpacer size="xl"/>

            <EuiFormRow label="Private Key">
                <EuiFieldText
                    value={ userToken }
                    isInvalid={ false }
                    onChange={ e => setUserToken(e.target.value) }
                />
            </EuiFormRow>

            <EuiButton
                isDisabled={ !userToken }
                size="s"
                color="success"
                onClick={ handleSubmit }
                fill
            >
                Save
            </EuiButton>
        </div>
    );
};