import React, { useState } from 'react';
import styled from 'styled-components';
import { ModalMainBodyStyled, ModalMainStyled } from '../modal/modal.styled';
import { Modal, PrimaryButton, TextField } from '@fluentui/react';
import { useObserver } from 'mobx-react-lite';
import tagsStore from '../../../stores/tags.store';
import TagItem from './TagItem';
import ColorsSelect from './ColorsSelect';
import { DangerButtonStyled } from '../../../style/components/button.styled';
import {
    EuiButton, EuiFieldText, EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle
} from '@elastic/eui';

const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: self-end;
  grid-gap: 30px;
  width: 450px;

   @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const EditStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: self-end;
  grid-gap: 30px;

   @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const TagsListWrapperStyled = styled.div`
  margin: 20px 0;
`;

const SectionStyles = styled.div`
  display: block;
  margin-bottom: 100px;

  legend {
    font-size: 18px;
    margin-bottom: 15px;
  }
`;

const Observed = ({ tags, close }) => {
    const [newTag, setNewTag] = useState({ name: '', color: '1' });
    const [editTag, seEditTag] = useState({ name: '', color: '' });
    const [selectEditTag, setSelectEditTag] = useState(null);

    const tagsExist = !!(tags && tags.length);

    const addNewTag = () => {
        if (newTag.name && newTag.color) {
            tagsStore.createTag(newTag);
            setNewTag({ name: '', color: '1' });
        }
    };

    const editTagHandle = () => {
        if (editTag.name && editTag.color) {
            tagsStore.editTag(selectEditTag, editTag);
            seEditTag({ name: '', color: '' });
            setSelectEditTag(null);
        }
    };

    const deleteTagHandle = () => {
        if (editTag.name && editTag.color) {
            tagsStore.deleteTag(selectEditTag);
            seEditTag({ name: '', color: '' });
            setSelectEditTag(null);
        }
    };

    const selectEvent = (id, isSelected) => {
        if (isSelected) {
            const selectedTag = tags.find(d => d._id === id);
            setSelectEditTag(id);
            seEditTag({ name: selectedTag.name, color: selectedTag.color });
        } else {
            setSelectEditTag(null);
            seEditTag({ name: '', color: '' });
        }
    };

    return (
        <ModalMainStyled>
            <EuiModal onClose={ close } style={ { 'width': 800, "height": 600 } }>
                <EuiModalHeader>
                    <EuiModalHeaderTitle><h1>Tags management</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    { tagsExist && (
                        <SectionStyles>
                            <legend>Select to edit</legend>
                            <TagsListWrapperStyled>
                                { tags.map((item, idx) => {
                                    const isSelected = selectEditTag ===
                                        item._id;
                                    return (
                                        <TagItem
                                            selected={ isSelected }
                                            selectEvent={ selectEvent }
                                            key={ `${ item._id }_dzjjjawd-tag_${ idx }` }
                                            tag={ item }
                                        />);
                                }) }
                            </TagsListWrapperStyled>

                            { selectEditTag && (
                                <EditStyles>
                                    <EuiFormRow label="Tag name">
                                        <EuiFieldText
                                            value={ editTag.name }
                                            isInvalid={ false }
                                            onChange={ e => {
                                                const val = e.target.value;
                                                if (val.length < 15) {
                                                    seEditTag(
                                                        { ...editTag, ...{ name: val } }
                                                    );
                                                }
                                            } }
                                        />
                                    </EuiFormRow>
                                    <ColorsSelect
                                        selectEvent={ (id) => seEditTag(
                                            { ...editTag, ...{ color: id } }) }
                                        selectedId={ editTag.color }
                                    />

                                    <EuiButton
                                        isDisabled={ !editTag.name }
                                        size="s"
                                        onClick={ editTagHandle }
                                        fill
                                    >
                                        Edit tag
                                    </EuiButton>

                                    <EuiButton
                                        size="s"
                                        iconType={ 'trash' }
                                        color="danger"
                                        onClick={ deleteTagHandle }
                                        fill
                                    >
                                        Delete tag
                                    </EuiButton>
                                </EditStyles>
                            ) }
                        </SectionStyles>

                    ) }
                    <SectionStyles>
                        <legend>Add new tag</legend>
                        <Styles>
                            <EuiFormRow label="Tag name">
                                <EuiFieldText
                                    value={ newTag.name }
                                    onChange={ e => {
                                        const val = e.target.value;
                                        if (val.length < 15) {
                                            setNewTag(
                                                { ...newTag, ...{ name: val } }
                                            );
                                        }
                                    } }
                                />
                            </EuiFormRow>
                            <ColorsSelect
                                selectEvent={ (id) => setNewTag(
                                    { ...newTag, ...{ color: id } }) }
                                selectedId={ newTag.color }
                            />

                            <EuiButton
                                isDisabled={ !newTag.name }
                                size="s"
                                onClick={ addNewTag }
                                fill
                            >
                                Add new tag
                            </EuiButton>
                        </Styles>
                    </SectionStyles>
                </EuiModalBody>

                <EuiModalFooter>
                </EuiModalFooter>
            </EuiModal>
        </ModalMainStyled>
    );
};

const TagsModal = ({ close }) => {
    return useObserver(() => (
        <Observed tags={ tagsStore.tags } close={ close }/>
    ));
};

export default TagsModal;


