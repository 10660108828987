import React from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import LogRocket from "logrocket";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@elastic/eui/dist/eui_theme_dark.css";

import { EuiProvider } from "@elastic/eui";

import ScrollToTop from "./components/ScrollToTop.component";
import ServersPage from "./pages/servers/Servers.page";
import settingsStore from "../stores/settings.store";
import userStore from "../stores/user.store";
import LoginView from "./pages/auth/Login.page";
import RegisterPage from "./pages/auth/Register.page";
import "../style/reset.css";
import "../style/common.css";
import "../style/modal.css";
import ServersCreatePage from "./pages/servers/ServersCreate.page";
import ServerItemPage from "./pages/servers/ServerItem.page";
import ProfilePage from "./pages/profile/Profile.page";
import VerifyEmailAskPage from "./pages/verify/VerifyEmailAsk.page";
import ActionsFromFirebasePage from "./pages/verify/ActionsFromFirebase.page";
import ForgotPassword from "./pages/auth/ForgotPassword";
import AlarmsSettingsPage from "./pages/profile/AlarmsSettings.page";
import Toast from "./components/toasts/Toast";
import PageNotFound from "./pages/PageNotFound";
import ApiPage from "./pages/api/ApiPage";
import ServerEditPage from "./pages/servers/ServerEdit.page";
import UptimeItemPage from "./pages/servers/UptimeItem.page";
import UptimeEditPage from "./pages/servers/UptimeEdit.page";
import MongoItemPage from "./pages/servers/MongoItem.page";
import MongoEditPage from "./pages/servers/MongoEdit.page";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://c20d6f32f6264f508553f01d2e903e10@o347193.ingest.sentry.io/5654439",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0
    });
    LogRocket.init("fr9nxr/syagent");
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={ (props) => {
        settingsStore.setRedirectRoute(
            rest.location.pathname + rest.location.search);

        if (!userStore.logged) {
            return (<Redirect to="/login"/>);
        }

        if (rest.location.pathname === "/pik" && userStore.logged) {
            return (<Component { ...props } />);
        }

        return (<Component { ...props } />);
    } }/>
);

function App() {
    return (
        <EuiProvider colorMode="dark">
            <div>
                <Toast/>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Redirect from="/" exact to="/servers"/>
                            <PrivateRoute path="/servers/:serverId/edit"
                                          component={ ServerEditPage }/>
                            <PrivateRoute path="/servers/:serverId"
                                          component={ ServerItemPage }/>
                            <PrivateRoute path="/servers"
                                          component={ ServersPage }/>
                            <PrivateRoute path="/uptime/:uptimeId/edit"
                                          component={ UptimeEditPage }/>
                            <PrivateRoute path="/uptime/:uptimeId"
                                          component={ UptimeItemPage }/>
                            <PrivateRoute path="/mongo/:serverId/edit"
                                          component={ MongoEditPage }/>
                            <PrivateRoute path="/mongo/:serverId"
                                          component={ MongoItemPage }/>
                            <PrivateRoute path="/servers-new"
                                          component={ ServersCreatePage }/>
                            <PrivateRoute path="/settings"
                                          component={ ProfilePage }/>
                            <PrivateRoute path="/api"
                                          component={ ApiPage }/>
                            <PrivateRoute path="/alarms-settings"
                                          component={ AlarmsSettingsPage }/>
                            <PrivateRoute path="/verify-email"
                                          component={ VerifyEmailAskPage }/>
                            <Route path="/pik"
                                   component={ ActionsFromFirebasePage }/>
                            <Route path="/login" component={ LoginView }/>
                            <Route path="/register" component={ RegisterPage }/>
                            <Route path="/forgot-password"
                                   component={ ForgotPassword }/>
                            <Route path="/404" component={ PageNotFound }/>
                            <Route component={ PageNotFound }/>
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </EuiProvider>
    );
}

export default App;
