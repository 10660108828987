import React from 'react';
import { BaseChartStyled, TooltipshiChartStyled } from './BaseLineChart';
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';

const TinyLineChart = ({ data }) => {
    return (
        <BaseChartStyled>
            <ResponsiveContainer width={ '100%' } height={ 50 }>
                <LineChart data={ data.data }>
                    <Line type="monotone" dataKey={ data.key } stroke="rgb(0, 128, 213)" strokeWidth={ 2 }/>
                    <Tooltip content={ <CustomTooltip/> }/>
                </LineChart>
            </ResponsiveContainer>

        </BaseChartStyled>
    );
};

export default TinyLineChart;

function CustomTooltip(props) {
    const { payload, label, active } = props;

    if (active) {
        return (
            <TooltipshiChartStyled className="custom-tooltip">
                { payload && payload.length > 0 && (
                    payload.map((relod, idx) => {
                        return (
                            <div key={ idx + 'dddw3200' }>
                                <p className="label">{ `${ relod.name } : ${ relod.value } ${ relod.unit ? relod.unit : '' }` }</p>
                            </div>
                        );
                    })
                ) }
            </TooltipshiChartStyled>
        );
    }

    return null;
}
