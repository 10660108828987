import styled from 'styled-components';

export const InputStyled = styled.input`
  border-radius: 1px;
  background: #fff;
  height: 36px;
  box-sizing: border-box;
  display: block;
  padding: 0 17px;
  width: 100%;
  border: 1px solid #fff;

  &[type=checkbox] {
    width: 20px;
    height: 20px;
  }

  &[type='text'] {

  }

  &[type='password'] {

  }

  &[type='number'] {

  }

  &[type='date'] {

  }

  &[type='email'] {

  }

  &[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    outline: 0;
    background: transparent;
  }

  &.invalid, &:invalid {
    
  }
`;