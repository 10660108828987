import { makeAutoObservable } from "mobx";
import fetchService from "../servises/fetch.service";
import serverStore from "./server.store";

class AlarmsStore {
    constructor() {
        makeAutoObservable(this);
    }

    alarmsByServerObj = {};
    criticalAlarmsCount = 0;
    warningAlarmsCount = 0;
    infoAlarmsCount = 0;
    alarmsSettingsByServer = {};

    setAlarms(server) {
        if (server.alarms && server.alarms.length > 0) {
            this.alarmsByServerObj[server._id] = this.normalizeAlarms(
                server.alarms,
                server
            );
        }
    }

    normalizeAlarms(alarms, server) {
        const output = {
            today: [],
            lastWeek: [],
            rest: []
        };

        const now = Date.now();
        const dayImMs = 86400000;
        const today = now - dayImMs;
        const lastWeek = now - (dayImMs * 7);

        alarms.forEach(alarm => {
            const severity = this.getSeverity(alarm.severity);
            const text = this.getRightAlarmText(alarm, server);
            const statusClass = alarm.status === 1 ? "unread" : "read";

            alarm.severityData = severity;
            alarm.text = text;
            alarm.statusClass = statusClass;
            alarm.serverName = server.name;

            if (alarm.status === 1) {
                if (alarm.severity === 30) {
                    this.criticalAlarmsCount = this.criticalAlarmsCount + 1;
                }
                if (alarm.severity === 20) {
                    this.warningAlarmsCount = this.warningAlarmsCount + 1;
                }
                if (alarm.severity === 10) {
                    this.infoAlarmsCount = this.infoAlarmsCount + 1;
                }
            }

            if (alarm.createdAt > today) {
                output.today.push(alarm);
            } else if (alarm.createdAt > lastWeek) {
                output.lastWeek.push(alarm);
            } else {
                output.rest.push(alarm);
            }
        });

        return output;
    }

    async setAlarmStatus(alarmId, status) {
        await fetchService.authenticatedPost(`/alarm`, { alarmId, status });
        await serverStore.getServers(true);
    }

    async setAllAlarmStatusRead(alarmIds) {
        await fetchService.authenticatedPost(`/alarms`,
            { alarmIds, status: 2 }
        );
        await serverStore.getServers(true);
    }

    getSeverity(severity) {
        const sev = {
            classes: "info",
            text: "Info"
        };

        switch (severity) {
            case 10:
                break;
            case 20:
                sev.classes = "warning";
                sev.text = "Warning";
                break;
            case 30:
                sev.classes = "critical";
                sev.text = "Critical";
                break;
        }
        return sev;
    }

    getRightAlarmText(alarm, server) {
        switch (alarm.messageType) {
            case "server_stopped_getting_data":
                return `Agent stopped receiving data`;
            case "sessions_high":
                return `Sessions count is higher by ${ alarm.message } than usual`;
            case "connections_high":
                return `Connections count is higher by ${ alarm.message } than usual`;
            case "high_ram_80":
            case "high_ram_90":
                return `High usage of RAM, ${ alarm.message }%`;

            case "high_disk_80":
            case "high_disk_90":
                if(alarm.moreInfo){
                    return `Disk is almost full, usage of ${ alarm.message }% on ${ alarm.moreInfo }`;
                }else {
                    return `Disk is almost full, usage of ${ alarm.message }%`;
                }
            case "rx_high":
                return `Network of incoming traffic is higher than usual by ${ alarm.message }`;

            case "tx_high":
                return `Network of outgoing traffic is higher than usual by ${ alarm.message }`;
            case "uptime_http_fail":
                return alarm.message;
            case "cpu_high":
                return alarm.message;
        }
    }

    async getAlarmsSettings(serverId) {
        const response = await fetchService.authenticatedGet(
            `/alarms-settings?serverId=${ serverId }`);

        if (response && response.data && response.data.data_o) {
            const data = response.data.data_o;
            this.alarmsSettingsByServer[data.serverId] = data;
            this.alarmsSettingsByServer = { ...this.alarmsSettingsByServer };
            return data;
        } else {
            return null;
        }
    }

    async updateAlarmsSettings(data) {
        const response = await fetchService.authenticatedPost(
            "/alarms-settings", data);

        if (response && response.data && response.data.data_o) {
            const dataResponse = response.data.data_o;
            this.alarmsSettingsByServer[data.serverId] = dataResponse;
            this.alarmsSettingsByServer = { ...this.alarmsSettingsByServer };
            return response.data.data_o;
        } else {
            return null;
        }
    }
}

const alarmsStore = new AlarmsStore();

export default alarmsStore;
