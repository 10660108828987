import React, { useState } from 'react';
import { BoxedResponsiveDashboardStyled } from '../ServerItem2';
import styled from 'styled-components';
import utilsService from '../../../../../servises/utils.service';
import MongoCollectionStatsModal from '../../../../components/modal/MongoCollectionStatsModal';

const CollectionsWrapperStyled = styled.div`

`;

const CollectionsHeaderStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 15px 0 10px 0;
  font-size: 11px;
  font-weight: 800;

  .right {
    text-align: right;
  }
`;

const CollectionItemStyled = styled.div`
  height: 30px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  margin-bottom: 3px;
  cursor: pointer;

  .left {
    position: relative;

    span {
      position: relative;
      z-index: 2;
      line-height: 1.8;
      padding-left: 4px;
    }

    .foreground {
      z-index: 1;
      position: absolute;
      background: rgba(80, 188, 94, 0.4);
      height: 28px;
      top: 0;
    }
  }

  .right {
    span {
      line-height: 1.8;
    }
  }
`;

const CollectionItemComponent = (props) => {
    const { dbInfo, db } = props;

    const name = db.name;

    return (
        <BoxedResponsiveDashboardStyled>
            { name }

            { dbInfo && (
                <CollectionStatsComponent
                    data={ dbInfo }
                />
            ) }
        </BoxedResponsiveDashboardStyled>
    );
};

export default CollectionItemComponent;

const CollectionStatsComponent = ({ data }) => {
    const [showModal, setShowModal] = useState(null);
    const { collectionsStats } = data;
    if (!collectionsStats) return null;
    const listOfCollectionsKeys = Object.keys(collectionsStats);
    const listOfCollections = listOfCollectionsKeys.map((key) => {
        const item = collectionsStats[key];
        item.name = key;
        return item;
    });

    listOfCollections.sort((a, b) => b.totalSize - a.totalSize);
    const maxSize = listOfCollections[0].totalSize;

    return (
        <CollectionsWrapperStyled>
            <CollectionsHeaderStyled>
                <span>
                    Name
                </span>
                <span className="right">
                    Size
                </span>
            </CollectionsHeaderStyled>
            { listOfCollections.map((item) => (
                <CollectionItem
                    key={ item.name }
                    data={ item }
                    maxSize={ maxSize }
                    showMore={ () => setShowModal(item.name) }
                />
            )) }

            { showModal && (
                <MongoCollectionStatsModal
                    data={ collectionsStats[showModal] }
                    close={ () => setShowModal(false) }
                />
            ) }
        </CollectionsWrapperStyled>
    );
};

const CollectionItem = ({ data, maxSize, showMore }) => {
    const { name, totalSize } = data;
    const size = totalSize / maxSize * 100;
    const formatedSize = utilsService.formatBytes(totalSize);
    return (
        <CollectionItemStyled onClick={ showMore }>
            <div className="left">
                <span>
                { name }
            </span>
                <div className="foreground" style={ { width: `${ size }%` } }/>
            </div>
            <div className="right">
                <span>
                    { `${ formatedSize.value } ${ formatedSize.unit }` }
                </span>
            </div>
        </CollectionItemStyled>
    );
};