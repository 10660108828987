import React from 'react';
import { useObserver } from 'mobx-react-lite';
import tagsStore from '../../../stores/tags.store';
import styled from 'styled-components';
import TagItem from './TagItem';

const Styles = styled.div`

`;

const TagsMIniColors = ({ serverTags }) => {
    return useObserver(
        () => (
            <Observed
                tags={ tagsStore.tags }
                serverTags={ serverTags }
            />
        ));
};

export default TagsMIniColors;

const Observed = ({ tags, serverTags = [] }) => {
    return (
        <Styles>
            { serverTags.map((item, idx) => {
                const righTag = tags.find(d => d._id === item);
                return (
                    <TagItem
                        makeMini={ true }
                        key={ `${ item }_dzjzzzjjawd-tag_${ idx }` }
                        tag={ righTag }
                    />);
            }) }
        </Styles>
    );
};