import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    checkActionCode,
    confirmPasswordReset,
    verifyResetingPassword
} from '../../../../servises/auth.service';
import { BoxedStyled } from '../../../../style/components/common.styled';
import LoaderComponent from '../../../components/Loader.component';
import styled from 'styled-components';
import { PrimaryButton, TextField } from '@fluentui/react';
import { FormItem } from '../../auth/Auth.styled';

const Styles = styled.div`
  text-align: center;
  padding: 30px;
  font-size: 19px;
  line-height: 1.3;
`;

const ResetPassword = props => {
    const { apiKey, oobCode } = props;

    const history = useHistory();

    // if (!userStore.logged) {
    //     history.push('/login');
    // }

    const [inputText, setInputText] = useState('');
    const [loader, setLoader] = useState(true);
    const [resetingVerified, setResetingVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);

    const checkOobCone = () => {
        if (resetingVerified) {
            return;
        }

        checkActionCode(oobCode).then(async data => {
            verifyResetingPassword(oobCode).then(appliData => {
                setResetingVerified(true);
                setLoader(false);
            }).catch(err => {
                console.log(err);
                setErrorMessage(err.message);
                setLoader(false);
            });
        }).catch(e => {
            setErrorMessage(e.message);
            setLoader(false);
        });
    };

    checkOobCone(oobCode);

    const handleSubmit = async () => {
        setMessageSent(true);
        await confirmPasswordReset(oobCode, inputText).then(() => {
            history.push('/servers');
        }).catch(e => {
            setErrorMessage(e.message);
        });
    };

    return (
        <BoxedStyled>
            { loader && (
                <LoaderComponent/>
            ) }

            { !loader && errorMessage && (
                <Styles>{ errorMessage }</Styles>
            ) }

            { !loader && !errorMessage && (
                <Styles>Server error, please refresh the page</Styles>
            ) }

            <FormItem>
                <TextField label="New password"
                           value={ inputText }
                           type={ "password" }
                           canRevealPassword
                           onChange={ (e) => setInputText(e.target.value) }
                />
            </FormItem>
            <FormItem>
                <PrimaryButton text="Save" onClick={ handleSubmit }/>
            </FormItem>
        </BoxedStyled>
    );
};

export default ResetPassword;