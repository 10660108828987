import { auth } from './firebase.service';
import firebase from "firebase/app";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () =>
    auth.signOut();

// Password Reset
export const doPasswordReset = (email) =>
    auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
    auth.currentUser.updatePassword(password);

// Sign Custom Token
export const doSignInCustomToken = (token) =>
    auth.signInWithCustomToken(token);

// Sign Email Link
export const doSignInEmailLink = (email, token) =>
    auth.signInWithEmailLink(email, token);

// Check if Sign Email Link
export const isSignInWithEmailLink = (url) =>
    auth.isSignInWithEmailLink(url);

export const doRefreshToken = () => {
    if (auth.currentUser) {
        return auth.currentUser.getIdToken(true);
    }
};

export const getCurrentUser = () => auth.currentUser;

export const verifyResetingPassword = code => auth.verifyPasswordResetCode(code);

export const checkActionCode = code => auth.checkActionCode(code);

export const confirmPasswordReset = (code, password) => auth.confirmPasswordReset(code, password);

export const applyActionCode = code => auth.applyActionCode(code);

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);