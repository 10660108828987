import firebase from 'firebase/app';
import 'firebase/auth';

const prodConfig = {
    apiKey: 'AIzaSyDuXWbbtUFQe3K7w80p41SBfFwCKCSmFT8',
    authDomain: 'server-monitor-8a36f.firebaseapp.com',
    projectId: 'server-monitor-8a36f',
    storageBucket: 'server-monitor-8a36f.appspot.com',
    messagingSenderId: '386400265818',
    appId: '1:386400265818:web:d73a40b870f3b3d3c41a63',
    measurementId: 'G-CV821QKY01'
};

const devConfig = {
    apiKey: 'AIzaSyDuXWbbtUFQe3K7w80p41SBfFwCKCSmFT8',
    authDomain: 'server-monitor-8a36f.firebaseapp.com',
    projectId: 'server-monitor-8a36f',
    storageBucket: 'server-monitor-8a36f.appspot.com',
    messagingSenderId: '386400265818',
    appId: '1:386400265818:web:d73a40b870f3b3d3c41a63',
    measurementId: 'G-CV821QKY01'
};

const config = process.env.FIREBASE === 'production' ? prodConfig : devConfig;

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
    auth
};
