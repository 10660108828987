import React from 'react';
import styled from 'styled-components';
import utilsService from '../../../servises/utils.service';

const FullBarStyled = styled.div`
  height: 12px;
  background-color: rgb(52,55,65);
  margin: 3px 0;
`;

const CurrentValueBarStyled = styled.div`
  height: 100%;
`;

const BarWrapperStyled = styled.div`
  legend {
    font-size: 11px;
  }
`;

const UnitsStyled = styled.div`
  font-size: 9px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  .start {

  }

  .middle {

  }

  .end {
    text-align: right;
  }
`;

const FillBar = props => {
    const {
        name,
        totalUnit = '%',
        currentUnit = '%',
        min = 0,
        total,
        current,
        originalCurrent,
        originalTotal
    } = props;

    let percent = 0;
    if (originalTotal) {
        percent = utilsService.getPercent(originalCurrent, originalTotal);
    } else {
        percent = utilsService.getPercent(current, total);
    }

    if (total === 0) {
        percent = 0;
    }

    let barColor = 'rgb(0, 128, 213)';

    if (percent > 70) {
        barColor = '#f9be49';
    }

    if (percent > 90) {
        barColor = '#F86B63';
    }

    return (
        <BarWrapperStyled>
            <legend className='color-2'>{ name }</legend>
            <FullBarStyled className={ 'llddeeassdd' }>
                <CurrentValueBarStyled
                    className={ 'llddeeassdd-s' }
                    style={ {
                        width: percent + '%',
                        background: barColor
                    } }/>
            </FullBarStyled>
            <UnitsStyled>
                <span className="start">
                    { `${ min }` }
                </span>
                <span className="middle">
                    { `${ current } ${ currentUnit }` }
                </span>
                <span className="end">
                    { `${ total } ${ totalUnit }` }
                </span>
            </UnitsStyled>
        </BarWrapperStyled>
    );
};

export default FillBar;
