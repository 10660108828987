import fetchService from '../servises/fetch.service';
import { makeAutoObservable } from 'mobx';
import utilsService from '../servises/utils.service';
import alarmsStore from './alarms.store';
import userStore from './user.store';

class ServerStore {
    constructor() {
        makeAutoObservable(this);
    }

    serversListObj = {};
    serverCreated = null;
    serverProcesses = null;
    canAddNewServers = false;

    async getServers(isForce) {
        if (!isForce && Object.keys(this.serversListObj).length > 0) {
            return;
        }
        const response = await fetchService.authenticatedGet(`/servers`);
        const data = response.data.data_o;

        alarmsStore.criticalAlarmsCount = 0;
        alarmsStore.warningAlarmsCount = 0;
        alarmsStore.infoAlarmsCount = 0;
        if (data) {
            data.forEach(server => {
                this.normalizeServerData(server);
            });

            this.serversListObj = utilsService.normalizeApiData(data);
            this.serversListObj = { ...this.serversListObj };
        }

        this.detectIfCanAddNewServers();
    }

    async getProcessesList(serverId) {
        const response = await fetchService.authenticatedGet(
            `/processes/${ serverId }`);
        const data = response.data.data_o;

        if (data && data.processes_list && data.processes_list.length > 0) {
            data.processes_list.forEach(process => {
                const pewka = utilsService.formatBytes(process.ram * 1024);
                process.ram = `${ pewka.value } ${ pewka.unit }`;
            });
        }

        this.serverProcesses = data;
    }

    async createServer(name) {
        const response = await fetchService.authenticatedPost(`/servers`,
            { name: name }
        );
        const data = response.data.data_o;
        this.normalizeServerData(data);
        this.serversListObj[data._id] = data;
        this.serverCreated = data;

        this.detectIfCanAddNewServers();

        return data;
    }

    async updateServer(id, name) {
        const response = await fetchService.authenticatedPost(`/server`,
            { name: name, serverId: id }
        );

        const data = response.data.data_o;
        this.serversListObj[data._id].name = data.name;
    }

    async updateServerTags(id, tagId, isAdd, isServerCreated) {
        const response = await fetchService.authenticatedPost(`/server`,
            { tagId: tagId, serverId: id, isAdd }
        );

        const data = response.data.data_o;
        this.serversListObj[data._id].tags = data.tags;
        this.serversListObj = { ...this.serversListObj };

        if (isServerCreated && this.serverCreated) {
            this.serverCreated = data;
        }
    }

    async deleteServer(serverId) {
        await fetchService.authenticatedDelete(
            `/server/${ serverId }`);
        await this.getServers(true);
    }

    detectIfCanAddNewServers() {
        const serversCount = Object.keys(this.serversListObj).length;
        const subscriptionServers = userStore.user.subscription.servers;

        if (serversCount < subscriptionServers) {
            this.canAddNewServers = true;
        } else {
            this.canAddNewServers = false;
        }
    }

    normalizeServerData(server) {
        const serverData = {};

        const metric = server.metrics ? server.metrics[0] : null;

        serverData.createdAt = utilsService.dateMe(server.createdAt);
        serverData.agentPushedTheData = !!metric;

        serverData.oldDataFetched = false;

        if (metric && metric.createdAt) {
            if (metric.createdAt + 600000 < Date.now()) {
                serverData.oldDataFetched = true;
            }
        }

        serverData.classes = '';
        if (serverData.agentPushedTheData) {
            serverData.classes = 'normal';
        } else {
            serverData.classes = 'no-data';
        }
        if (serverData.oldDataFetched) {
            serverData.classes = 'old-data';
        }

        serverData.ramDataCurrent = null;
        serverData.ramDataTotal = null;

        serverData.diskDataCurrent = null;
        serverData.diskDataTotal = null;

        if (serverData.agentPushedTheData) {
            serverData.timeAgo = utilsService.timeAgo(metric.createdAt);
            serverData.ramDataCurrent = utilsService.formatBytes(
                metric.ram_usage);
            serverData.ramDataTotal = utilsService.formatBytes(
                metric.ram_total);

            serverData.swapDataCurrent = utilsService.formatBytes(
                metric.swap_usage);
            serverData.swapDataTotal = utilsService.formatBytes(
                metric.swap_total);

            serverData.diskDataCurrent = utilsService.formatBytes(
                metric.disk_usage);
            serverData.diskDataTotal = utilsService.formatBytes(
                metric.disk_total);
        } else {
            serverData.timeAgo = 'N/A';
        }

        server.serverData = serverData;

        alarmsStore.setAlarms(server);
    }
}

const serverStore = new ServerStore();

export default serverStore;