import React from "react";
import styled from "styled-components";
import FillBar from "../../../../components/metrics/FillBar";
import utilsService from "../../../../../servises/utils.service";
import TotalVerticalInfo
    from "../../../../components/metrics/TotalVerticalInfo";
import { MarginTopStyled } from "../../../../../style/components/common.styled";

const Styled = styled.section`
  .llddeeassdd {
    //background: #0e1b25;
    background-color: rgb(52,55,65);
    height: 32px;
  }

  .llddeeassdd-s {

  }
`;

const DisksListStyled = styled.section`
  height: 430px;
  overflow-y: auto;
  margin: 22px 0;
`;

function generateDisks(server) {
    let disks = [];

    if (server && server.metrics && server.metrics[0]) {
        const metrics = server.metrics[0];
        const disksList = metrics.disk_list;

        if (disksList && disksList.length > 0) {
            disks = disksList.map(pd => {
                const total = utilsService.formatBytes(pd.total);
                const used = utilsService.formatBytes(pd.used);

                return ({
                    name: pd.name,
                    total: pd.total,
                    totalData: total.value,
                    totalUnit: total.unit,
                    used: pd.used,
                    usedData: used.value,
                    usedUnit: used.unit
                });
            });
        }
    }

    disks.sort((a, b) => b.total - a.total);

    return disks;
}

const ServerDisksWidget = ({ server }) => {
    const diskTotal = utilsService.getFromMetrics(server, "disk_total");
    const diskUsage = utilsService.getFromMetrics(server, "disk_usage");

    const swapTotal = utilsService.getFromMetrics(server, "swap_total");
    const swapUsage = utilsService.getFromMetrics(server, "swap_usage");

    const disksData = generateDisks(server);

    return (
        <Styled>
            <MarginTopStyled>
                <FillBar
                    name={ "Total Storage:" }
                    total={ server.serverData.diskDataTotal.value }
                    totalUnit={ server.serverData.diskDataTotal.unit }
                    current={ server.serverData.diskDataCurrent.value }
                    currentUnit={ server.serverData.diskDataCurrent.unit }
                    originalTotal={ diskTotal }
                    originalCurrent={ diskUsage }
                />
            </MarginTopStyled>

            <MarginTopStyled>
                <FillBar
                    name={ "Swap:" }
                    total={ server.serverData.swapDataTotal.value }
                    totalUnit={ server.serverData.swapDataTotal.unit }
                    current={ server.serverData.swapDataCurrent.value }
                    currentUnit={ server.serverData.swapDataCurrent.unit }
                    originalTotal={ swapTotal }
                    originalCurrent={ swapUsage }
                />
            </MarginTopStyled>

            <DisksListStyled>
                { disksData.map((item, idx) => {
                    if (item.name) {
                        return (
                            <TotalVerticalInfo
                                key={ idx + "d21dz" }
                                title={ item.name }
                                start={ 0 }
                                current={ item.usedData }
                                total={ item.totalData }
                                currentOriginal={ item.used }
                                totalOriginal={ item.total }
                                totaExt={ item.totalUnit }
                                currentExt={ item.usedUnit }
                            />
                        );
                    } else {
                        return null;
                    }
                }) }
            </DisksListStyled>
        </Styled>
    );
};

export default ServerDisksWidget;
