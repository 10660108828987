import React from "react";
import styled from "styled-components";

const Styled = styled.div`
  width: 100%;
  text-align: center;
  height: 210px;
  align-content: center;
  display: grid;

  .load-cpu {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .pulse {
    height: 5px;
    background: ${ props => props.barColor };
    position: relative;

    span {
      position: absolute;
      display: block;
      height: 5px;
      width: 10px;
      box-shadow: ${ props => props.barColor } 0 0 15px;
      animation: animate ${ props => props.speed ? props.speed : 0 }s infinite ease-in-out;
      opacity: 0;
    }

    span:nth-child(1) {
      animation-delay: 0s;
    }

    span:nth-child(2) {
      animation-delay: 0.5s;
    }

    span:nth-child(3) {
      animation-delay: 1s;
    }

    span:nth-child(4) {
      animation-delay: 1.5s;
    }
  }

  @keyframes animate {
    0% {
      left: 0;
      opacity: 0;
    }
    10% {
      opacity: 0;
      left: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 0;
      left: calc(100% - 10px);
    }
    100% {
      opacity: 0;
      left: calc(100% - 10px);
    }
  }
`;

const CpuCssPulse = ({ load }) => {
    const speed = getSpeed(load);

    let barColor = "rgb(0, 128, 213)";

    if (load > 70) {
        barColor = "#f9be49";
    }

    if (load > 90) {
        barColor = "#F86B63";
    }

    return (
        <Styled speed={ speed } barColor={ barColor }>
            <div className="load-cpu">
                { load }%
            </div>
            <div className="pulse">
                <span/>
                <span/>
                <span/>
                <span/>
            </div>
        </Styled>
    );
};

export default CpuCssPulse;

const getSpeed = load => {
    let speed = 6;
    if (load > 0) {
        speed = 5;
    }
    if (load > 10) {
        speed = 4;
    }
    if (load > 35) {
        speed = 3;
    }
    if (load > 60) {
        speed = 1;
    }
    if (load > 90) {
        speed = 0.8;
    }
    if (load > 99) {
        speed = 0.5;
    }
    return speed;
};
