import React, { useState } from 'react';
import { COLORS } from '../../styleComponents/Colors';
import styled from 'styled-components';

const Styles = styled.div`
  position: relative;
`;

const SelectedStyles = styled.div`

`;

const SelectorStyles = styled.div`
  position: absolute;
  left: -124%;
  top: 42px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 11px;
  grid-gap: 10px;

   @media (max-width: 600px) {
    position: static;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 13px;
  }
`;

const ColorBoxStyles = styled.div`
  height: 32px;
  line-height: 2;
  text-align: center;
  border-radius: 14px;
  cursor: pointer;
  padding: 3px 7px;
`;

const ColorsSelect = ({ selectedId = '1', selectEvent }) => {
    const selectedColor = COLORS[selectedId];
    const [showSelector, setShowSelector] = useState(false);

    const onSelect = (id) => {
        setShowSelector(false);
        selectEvent((id + 1).toString());
    };

    return (
        <Styles>
            <SelectedStyles onClick={ () => setShowSelector(!showSelector) }>
                <ColorBoxStyles style={ { 'background': selectedColor } }>
                    { selectedColor }
                </ColorBoxStyles>
            </SelectedStyles>

            <SelectorStyles>
                { showSelector && (
                    Object.values(COLORS).map((col, idx) => {
                        const selected = col === selectedColor;
                        return (
                            <ColorBoxStyles
                                key={ `ddzkk000f9f9_${ idx }` }
                                onClick={ () => {onSelect(idx);} }
                                className={ selected ? 'selected' : '' }
                                style={ { background: col } }
                            >
                                { col }
                            </ColorBoxStyles>
                        );
                    })
                ) }
            </SelectorStyles>
        </Styles>
    );
};

export default ColorsSelect;