import React, {useState} from "react";
import styled from "styled-components";
import {NewServerItem, ServersItemStyled, ServersListStyled, TagsListWrapperStyled} from "./ServersList";
import {EuiIcon} from "@elastic/eui";
import {Link} from "react-router-dom";
import TagsMIniColors from "../../../components/tags/TagsMIniColors";
import FillBar from "../../../components/metrics/FillBar";
import {serverFilterTypes} from "../../../../constants/data.types";

const WrapperStyled = styled.div`
  margin-bottom: 20px;
`;
const ServerMongoListWithChildren = ({
                                         changeOrder,
                                         isTableView,
                                         canAddNewServers,
                                         servers,
                                         selectedTagId,
                                         position,
                                         totalPosition
                                     }) => {
    const monitorFilter = localStorage.getItem("monitorFilter") || serverFilterTypes.createdAt.val;
    const monitorFilterAsc = localStorage.getItem("monitorFilterAsc");
    const [selectedFilter, setSelectedFilter] = useState(monitorFilter);
    const [isAscFilter, setIsAscFilter] = useState(monitorFilterAsc === "true");

    const filterTypes = serverFilterTypes;

    const getServersList = () => {
        let temp = JSON.parse(JSON.stringify(servers));
        if (selectedTagId) {
            temp = servers.filter(ser => {
                if (ser.tags && ser.tags.length) {
                    const tagIndex = ser.tags.indexOf(selectedTagId);
                    if (tagIndex > -1) {
                        return true;
                    }
                }
                return false;
            });

        }

        const defaultString = isAscFilter ? "a" : "z";
        const defaultNumber = !isAscFilter ? 0 : 99999999999999999;
        temp.sort((a, b) => {
            let valueA, valueB;
            let type = "number";

            switch (selectedFilter) {
                case filterTypes.createdAt.val:
                    valueA = a[selectedFilter] || defaultNumber;
                    valueB = b[selectedFilter] || defaultNumber;
                    break;
                // case filterTypes.updatedAt.val:
                case filterTypes.name.val:
                    valueA = a[selectedFilter] || defaultString;
                    valueB = b[selectedFilter] || defaultString;
                    type = "string";
                    break;
                case filterTypes.alarms.val:
                    valueA = 0;
                    valueB = 0;
                    if (a.alarms && a.alarms.length) {
                        valueA = a.alarms.length || defaultNumber;
                    }
                    if (b.alarms && b.alarms.length) {
                        valueB = b.alarms.length || defaultNumber;
                    }
                    break;
                case filterTypes.tags.val:
                    valueA = "";
                    valueB = "";
                    type = "string";
                    if (a.tags && a.tags.length) {
                        valueA = a.tags[0] || defaultString;
                    }
                    if (b.tags && b.tags.length) {
                        valueB = b.tags[0] || defaultString;
                    }
                    break;
                case filterTypes.load_cpu.val:
                    valueA = defaultNumber;
                    valueB = defaultNumber;
                    if (a.metrics && a.metrics.length) {
                        valueA = a.metrics[0].load_cpu || defaultNumber;
                    }
                    if (b.metrics && b.metrics.length) {
                        valueB = b.metrics[0].load_cpu || defaultNumber;
                    }
                    break;
                case filterTypes.ram_usage.val:
                    valueA = defaultNumber;
                    valueB = defaultNumber;
                    if (a.metrics && a.metrics.length) {
                        valueA = a.metrics[0].ram_usage || defaultNumber;
                    }
                    if (b.metrics && b.metrics.length) {
                        valueB = b.metrics[0].ram_usage || defaultNumber;
                    }

                    break;
                case filterTypes.disk_usage.val:
                    valueA = defaultNumber;
                    valueB = defaultNumber;
                    if (a.metrics && a.metrics.length) {
                        valueA = a.metrics[0].disk_usage || defaultNumber;
                    }
                    if (b.metrics && b.metrics.length) {
                        valueB = b.metrics[0].disk_usage || defaultNumber;
                    }

                    break;
            }

            if (type === "number") {
                if (isAscFilter) {
                    if (valueA < valueB) {
                        return -1;
                    }
                    if (valueA > valueB) {
                        return 1;
                    }
                } else {
                    if (valueA > valueB) {
                        return -1;
                    }
                    if (valueA < valueB) {
                        return 1;
                    }
                }
                return 0;
            }

            if (type === "string") {
                if (isAscFilter) {
                    return valueA.localeCompare(valueB);
                } else {
                    return valueB.localeCompare(valueA);
                }
            }

            if (type === "bool") {
                if (isAscFilter) {
                    return (valueA === valueB) ? 0 : valueA ? -1 : 1;
                } else {
                    return (valueB === valueA) ? 0 : valueB ? -1 : 1;
                }
            }
            return 0;
        });
        return temp;
    };

    return (
        <WrapperStyled>
            <TagsListWrapperStyled>
                <h3><EuiIcon type="database" size="m" color="text"/>MongoDb</h3>
                <div className="position-kdz">
                    {position === 0 && (
                        <EuiIcon
                            onClick={() => changeOrder(position, 'down')}
                            type="arrowDown" size="m" color="text"/>
                    )}
                    {position === totalPosition - 1 && (
                        <EuiIcon
                            onClick={() => changeOrder(position, 'up')}
                            type="arrowUp" size="m" color="text"/>
                    )}
                    {position > 0 && position < totalPosition - 1 && (
                        <>
                            <EuiIcon
                                onClick={() => changeOrder(position, 'down')}
                                type="arrowDown" size="m" color="text"/>
                            <EuiIcon
                                onClick={() => changeOrder(position, 'up')}
                                type="arrowUp" size="m" color="text"/>
                        </>
                    )}
                </div>
            </TagsListWrapperStyled>
            <ServersListStyled className={isTableView ? "table" : ""}>
                {(getServersList()).map(item => {
                    if (isTableView) {
                        return null;
                    } else {
                        return <MongoItem
                            key={item._id}
                            item={item}
                        />;
                    }
                })}
                {canAddNewServers && (
                    <NewServerItem/>
                )}
            </ServersListStyled>
        </WrapperStyled>
    );
};

export default ServerMongoListWithChildren;


const MongoItem = ({item}) => {
    const {
        name,
        agentPushedTheData,
        timeAgo,
        metric,
        classes,
        totalDbUsage,
        totalDbUsageFormated,
        availableConnections,
        connections,
        totalSizeFormated
    } = item;

    let alarmsCount = null;
    if (item && item.alarms && item.alarms.length) {
        alarmsCount = item.alarms.filter(alarm => alarm.status === 1).length;
    }

    return (
        <ServersItemStyled className={classes}>
            <Link to={`/mongo/${item._id}`} className={"server-link"}/>
            <div className="status color-1">
                <TagsMIniColors
                    style={{position: "absolute"}}
                    serverTags={item.tags}
                />
                {alarmsCount && (
                    <div className="alarms-oodz4">
                        <span className="bibl">Alarms:</span> {alarmsCount}
                    </div>
                )}
                <div className="server-status"/>
                <div className="last-metric">
                    {timeAgo}
                </div>
            </div>
            <h4><span className="label">Name:</span>{name}</h4>
            <div className=""><span
                className="label">Hosts:</span>{item.numberOfHosts}</div>
            {agentPushedTheData && (
                <div className="server-data">
                    <div className="storage">
                        <FillBar
                            name={"Storage"}
                            total={totalSizeFormated.value}
                            totalUnit={totalSizeFormated.unit}
                            current={totalDbUsageFormated.value}
                            currentUnit={totalDbUsageFormated.unit}
                            originalTotal={metric.totalSize}
                            originalCurrent={totalDbUsage}
                        />
                    </div>
                    <div className="connections">
                        <FillBar
                            name={"Connections"}
                            totalUnit={""}
                            currentUnit={""}
                            total={availableConnections}
                            current={connections}
                        />
                    </div>
                </div>
            )}
        </ServersItemStyled>);
};
