import { makeAutoObservable } from 'mobx';
import fetchService from '../servises/fetch.service';

class UptimeChecksStore {
    constructor() {
        makeAutoObservable(this);
    }

    uptimeChecksList = [];

    async getUptimeChecks(uptimeId) {
        const response = await fetchService.authenticatedGet(
            `/uptimeChecks/${ uptimeId }`);
        const data = response.data.data_o;
        if (data) {
            if (data) {
                this.uptimeChecksList = data.reverse();
            }
        }
    }
}

const uptimeCheckStore = new UptimeChecksStore();

export default uptimeCheckStore;