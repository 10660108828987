import React, { useEffect, useState } from 'react';
import serverStore from '../../../stores/server.store';
import uptimeStore from '../../../stores/uptime.store';
import Header from '../../components/header/Header';
import LoaderComponent from '../../components/Loader.component';
import uptimeCheckStore from '../../../stores/uptimeChecks.store';
import UptimeItemWrapper from './components/UptimeItem';
import mongoDbStore from '../../../stores/mongodb.store';

const UptimeItemPage = props => {
    document.title = 'Monitors - SyAgent';
    const { uptimeId } = props.match.params;

    const [loader, setLoader] = useState(true);

    useEffect(async () => {
        const getServersData = async () => {
            await Promise.all([
                serverStore.getServers(),
                uptimeStore.getUptime(),
                mongoDbStore.getMongoDb(),
                uptimeCheckStore.getUptimeChecks(uptimeId)
            ]);
        };
        await getServersData();
        setLoader(false);
    }, [uptimeId]);

    useEffect(() => {
        const timer = setInterval(async () => {
            await Promise.all([
                uptimeCheckStore.getUptimeChecks(uptimeId)
            ]);
        }, 60000);
        return () => clearInterval(timer);
    }, [uptimeId]);

    return (
        <main className="servers">
            <Header/>
            { loader && (
                <LoaderComponent/>
            ) }

            { !loader && (
                <UptimeItemWrapper
                    uptimeId={ uptimeId }
                />
            ) }
        </main>
    );
};

export default UptimeItemPage;