import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import LoaderComponent from '../../components/Loader.component';
import serverStore from '../../../stores/server.store';
import ServersDashboard from './components/ServersDashboard';
import tagsStore from '../../../stores/tags.store';
import uptimeStore from '../../../stores/uptime.store';
import mongoDbStore from '../../../stores/mongodb.store';

const ServersPage = () => {
    document.title = 'Servers - SyAgent';

    const [loader, setLoader] = useState(true);

    useEffect(async () => {
        const getsData = () => {
            return Promise.all([
                serverStore.getServers(),
                tagsStore.getTags(),
                uptimeStore.getUptime(),
                mongoDbStore.getMongoDb()
            ]);
        };
        await getsData();
        setLoader(false);
    }, []);

    return (
        <main className="servers">
            <Header/>
            { loader && (
                <LoaderComponent/>
            ) }
            { !loader && (
                <ServersDashboard/>
            ) }
        </main>
    );
};

export default ServersPage;