import React from 'react';
import utilsService from '../../../servises/utils.service';
import styled from 'styled-components';

const AlarmStyled = styled.div`

`;

const AlarmPewkaStyled = styled.div`
  margin-bottom: 10px;

  label {
    color: rgba(255, 255, 255, 0.65);
    font-size: 11px;
    margin-right: 3px;
  }

  span {
    &.info {
      color: rgb(0, 128, 213);
    }

    &.warning {
      color: #f9be49;
    }

    &.critical {
      color: #F86B63;
    }
  }

  .plla {
    line-height: 1.6;
  }
`;

const AlarmMoreInfo = ({ alarm, severity, text }) => {
    let monitorTypeLabel = 'Server';
    if (alarm.monitorType === 'uptime') {
        monitorTypeLabel = 'Uptime';
    }

    return (
        <AlarmStyled>
            <AlarmPewkaStyled>
                <label>
                    Date:
                </label>
                <span>
                                { utilsService.dateMeWithTime(alarm.createdAt) }
                            </span>
            </AlarmPewkaStyled>
            <AlarmPewkaStyled>
                <label>
                    { monitorTypeLabel }:
                </label>
                <span>
                                { alarm.serverName }
                            </span>
            </AlarmPewkaStyled>
            <AlarmPewkaStyled>
                <label>
                    Severity:
                </label>
                <span className={ severity.classes }>
                                { severity.text }
                            </span>
            </AlarmPewkaStyled>
            <AlarmPewkaStyled>
                <label>
                    Message:
                </label>
                <span className="plla">
                                { text }
                            </span>
            </AlarmPewkaStyled>
        </AlarmStyled>
    );
};

export default AlarmMoreInfo;
