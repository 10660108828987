import React from 'react';
import { useObserver } from 'mobx-react-lite';
import CreateServerObserved from './CreateServerObserved';
import tagsStore from '../../../../stores/tags.store';
import serverStore from '../../../../stores/server.store';
import uptimeStore from '../../../../stores/uptime.store';
import mongoDbStore from '../../../../stores/mongodb.store';

const CreateServerWrapper = () => {
    return useObserver(() => {
            return (<CreateServerObserved
                tags={ tagsStore.tags }
                serverCreated={ serverStore.serverCreated }
                canAddNewServers={ serverStore.canAddNewServers }
                canAddNewUptime={ uptimeStore.canAddNewUptime }
                canAddNewMongoDb={ mongoDbStore.canAddNewMongoDb }
            />);
        }
    );
};

export default CreateServerWrapper;