import React from 'react';
import styled from 'styled-components';

const WrapperStyled = styled.div`

  h4 {
    margin: 40px 0 20px 0;
    font-size: 16px;
    font-weight: 600;
  }

  .items-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
    //justify-items: center;
    align-items: start;
  }

  .ag-item {
    display: grid;
    justify-items: left;
  }

  .ag-list {
    display: grid;
    justify-items: left;
  }

  label {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .ag-item-2 {
    padding: 2px 2px;
  }
`;

const ServerAgentCollected = () => {
    return (
        <WrapperStyled>
            <h4>Monitored Data</h4>
            <div className="items-list">
                { _LIST.map((item, idx) => {
                    return (
                        <div
                            key={ 'ddzea414' + idx }
                            className="ag-item">
                            <label>
                                { item.group }
                            </label>

                            <div className="ag-list">
                                { item.list.map((agItem, idx2) => {
                                    return (
                                        <div
                                            key={ 'zdiiiawd' + idx2 }
                                            className="ag-item-2">
                                            { agItem }
                                        </div>
                                    );
                                }) }
                            </div>
                        </div>
                    );
                }) }
            </div>
        </WrapperStyled>
    );
};

export default ServerAgentCollected;

const _LIST = [
    {
        group: 'System',
        list: ['System uptime', 'System load', 'IO load']
    },
    {
        group: 'OS',
        list: [
            'OS kernel',
            'OS name',
            'OS architecture']
    },
    {
        group: 'CPU',
        list: ['CPU identifier', 'CPU cores', 'CPU frequency', 'CPU load']
    },
    {
        group: 'Process',
        list: [
            'Process count',
            'Process array']
    },
    {
        group: 'RAM',
        list: [
            'RAM total',
            'RAM usage',
            'SWAP total',
            'SWAP usage']
    },
    {
        group: 'Disk',
        list: [
            'Disk array',
            'Disk total',
            'Disk usage']
    },
    {
        group: 'Network',
        list: [
            'Connection count',
            'NIC identifier',
            'IPv4 address',
            'IPv6 address',
            'RX since boot',
            'TX since boot',
            'RX currently',
            'TX currently']
    },
    {
        group: 'File',
        list: [
            'File handle count',
            'File handle limit']
    },
    {
        group: 'Misc',
        list: [
            'Agent version',
            'Session count']
    }

];