import React from 'react';
import styled from 'styled-components';

const _LIST = [
    {
        date: '',
        text: 'From now alarms notifications settings are not common for all servers, they are now per server. To edit the settings proceed to server edit section'
    },
    {
        text: 'Tags (labels) added, you can mark every server with several tags. Can be done in edit server or server create'
    },
    {
        text: 'New Donation and API section'
    }
];

const Styles = styled.div`
  h3 {
    font-size: 28px;
  }
`;

const StylesTextWrapper = styled.div`
  padding: 10px;
`;

const StylesText = styled.p`
  margin: 5px 0;
`;

const Changes = ({ user }) => {
    return (
        <Styles>
            <h3>Changes</h3>

            <StylesTextWrapper>
                { _LIST.map((item, idx) => {
                    return (<StylesText
                        key={ `dkkkawkdkaw_${ idx }` }>{ item.text }</StylesText>);
                }) }
            </StylesTextWrapper>
        </Styles>
    );
};

export default Changes;