import React, { useCallback, useState } from 'react';
import Header from '../../components/header/Header';
import LoaderComponent from '../../components/Loader.component';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import userStore from '../../../stores/user.store';
import {
    DangerButtonStyled,
    PrimaryButtonStyled
} from '../../../style/components/button.styled';
import { withRouter } from 'react-router';
import ChangePassword from './components/ChangePassword';
import MenuSettingsComponent
    from '../../components/menuSettings/MenuSettings.component';

const Styles = styled.div`
  font-size: 20px;
  margin: 10px 20px;
`;

const DeleteServerZoneStyled = styled.div`
  text-align: center;
  margin: 20px 10px;

  .are-you-sure {
    margin-bottom: 20px;
  }
  
  h2{
    margin-bottom: 20px;
  }
`;

const ProfilePage = props => {
    document.title = 'Account Settings - SyAgent';

    const [loader, setLoader] = useState(false);

    return (
        <main>
            <Header/>
            <Styles>
                <SettingsPageWrapper
                    history={ props.history }
                />
            </Styles>
        </main>
    );
};

const SettingsPageWrapper = props => {
    return useObserver(() => (
        <SettingsPage
            history={ props.history }
            user={ userStore.user }
        />
    ));
};

const SettingsPage = props => {
    const { user, history } = props;
    const [areYouSureState, setAreYouSureState] = useState(false);
    const [deleteSending, setDeleteSending] = useState(false);

    const handleDelete = useCallback(async e => {
        if (deleteSending) {
            return;
        }

        setDeleteSending(true);
        await userStore.deleteUser();
        history.push('/login');

    }, [deleteSending]);

    return (
        <>
            { !user && (
                <LoaderComponent/>
            ) }

            { user && (
                <MenuSettingsComponent
                    title={ 'Account Settings' }
                    menu={ [
                        {
                            title: 'Password'
                        },
                        {
                            title: 'Delete account'
                        }
                    ] }
                    content={ [
                        <ChangePassword
                            user={ user }
                        />,
                        <DeleteServerZoneStyled>
                            <h2>Delete Account</h2>
                            { !areYouSureState && (
                                <DangerButtonStyled
                                    onClick={ () => setAreYouSureState(true) }
                                >
                                    Delete Account
                                </DangerButtonStyled>
                            ) }

                            { areYouSureState && (
                                <>
                                    <div
                                        className="are-you-sure">Are you sure?
                                    </div>
                                    <DangerButtonStyled
                                        onClick={ handleDelete }
                                    >
                                        Yes
                                    </DangerButtonStyled>
                                    <PrimaryButtonStyled
                                        onClick={ () => setAreYouSureState(
                                            false) }
                                    >
                                        No
                                    </PrimaryButtonStyled>
                                </>
                            ) }
                        </DeleteServerZoneStyled>
                    ] }
                />
            ) }
        </>
    );
};

export default withRouter(ProfilePage);