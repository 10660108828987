import React from 'react';
import styled from 'styled-components';
import ServersList from './ServersList';
import {
    BoxedResponsiveStyled, ServersListStyled
} from "../../../../style/components/common.styled";
import AlarmsWrapperWidget from './serverWidgets/AlarmsWrapperWidget';
import AlarmsCountWrapperWidget from './serverWidgets/AlarmsCountWidget';
import UsageWidget from './serverWidgets/UsageWidget';
import { EuiIcon } from '@elastic/eui';

const PizdiharkStyled = styled.section`

`;

const ServersDashboardStyled = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 5px;
  margin-top: 0;
  grid-gap: 8px;

  //background: rgb(29,30,36);
  //box-shadow: rgb(0 0 0 / 28%) 0px 0.9px 4px, rgb(0 0 0 / 21%) 0px 2.6px 8px, rgb(0 0 0 / 18%) 0px 5.7px 12px, rgb(0 0 0 / 14%) 0px 15px 15px;
  //border-inline-start: 1px solid rgba(52,55,65,0.7);

  @media (max-width: 1025px) {
    display: block;
  }
`;

const RightDStyled = styled.section`
  display: grid;
  grid-template-rows: max-content;



   @media (max-width: 600px) {
    display: block;
  }
`;

const AlarmsAndSpikesStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  grid-template-rows: max-content;


   @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const ServersDashboard = () => {
    return (
        <ServersDashboardStyled>
            <ServersListStyled>
                <ServersList/>
            </ServersListStyled>
            <RightDStyled>
                <AlarmsAndSpikesStyled className={ 'alarmito' }>
                    <BoxedResponsiveStyled>
                        <h3><EuiIcon type="alert" size="m" /> Alarms</h3>
                        <AlarmsCountWrapperWidget/>
                    </BoxedResponsiveStyled>
                    <BoxedResponsiveStyled>
                        <h3><EuiIcon type="apmTrace" size="m" /> Usage</h3>
                        <UsageWidget/>
                    </BoxedResponsiveStyled>
                </AlarmsAndSpikesStyled>
                <BoxedResponsiveStyled className={ 'without-margin' } style={{height: "330px"}}>
                    <AlarmsWrapperWidget/>
                </BoxedResponsiveStyled>
            </RightDStyled>
        </ServersDashboardStyled>
    );
};

export default ServersDashboard;
