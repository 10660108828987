import React from 'react';
import styled from 'styled-components';

const _GETTING_STARTED = [
    {
        title: 'Base URL',
        value: 'https://openapi.syagent.com'
    },
    {
        title: 'Auth',
        value: '?apiKey=YOUR_API_KEY'
    },
    {
        title: 'Example',
        value: 'https://openapi.syagent.com/me?apiKey=YOUR_API_KEY'
    }
];

const _ENDPOINTS = [
    {
        title: 'User',
        value: 'GET /me'
    },
    {
        title: 'Servers',
        value: 'GET /servers'
    },
    {
        title: 'Server by id',
        value: 'GET /servers/:SERVER_ID'
    },
    {
        title: 'Create server (Only for BIG supporters)',
        value: 'POST /servers',
        payload: { name: 'SERVER NAME' }
    },
    {
        title: 'Update server (Only for BIG supporters)',
        value: 'PUT /servers/:SERVER_ID',
        payload: { name: 'SERVER NAME' }
    },
    {
        title: 'Delete server (Only for BIG supporters)',
        value: 'DELETE /servers/:SERVER_ID'
    },
    {
        title: 'Alarms',
        value: 'GET /alarms'
    },
    {
        title: 'Alarms by server id',
        value: 'GET /alarms/:SERVER_ID'
    },
    {
        title: 'Change alarm status',
        value: 'PUT /alarms/:ALARM_ID',
        payload: { status: 1 }
    },
    {
        title: 'Metrics',
        value: 'GET /metrics/:SERVER_ID'
    },
    {
        title: 'Processes',
        value: 'GET /processes/:SERVER_ID'
    }
];

const Styles = styled.div`
  h3 {
    margin: 50px 0 10px 0;
  }
`;

const ApiDocItemStyles = styled.div`
  display: block;
  background-color: #122330;
  border-radius: 2px;
  //border: 1px solid rgb(0, 128, 213);
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, serif;

  legend {
    background-color: #1f313e;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
  }

  p {
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
  }
`;

const DescStyles = styled.div`
  display: grid;
  grid-gap: 20px;

  .code {
    margin: 20px;
    padding: 10px;
    background-color: #1b3140;
  }

  h4 {
    display: block;
    margin: 20px 0 10px 20px;
  }
`;

const ApiDocs = () => {
    return (
        <Styles>
            <h3>Getting started</h3>
            <DescStyles>
                { _GETTING_STARTED.map((gt, idx) => {
                    return (
                        <ApiDocItem
                            key={ `pwcz_${ idx }` }
                            item={ gt }
                        />
                    );
                }) }
            </DescStyles>
            <h3>Endpoints</h3>
            <DescStyles>
                { _ENDPOINTS.map((gt, idx) => {
                    return (
                        <ApiDocItem
                            key={ `ddpwcz_${ idx }` }
                            item={ gt }
                        />
                    );
                }) }
            </DescStyles>
        </Styles>
    );
};

export default ApiDocs;

const ApiDocItem = ({ item }) => {
    return (
        <ApiDocItemStyles>
            <legend>
                { item.title }
            </legend>
            <p>{ item.value }</p>

            { item.payload && (
                <>
                    <h4>Body payload</h4>
                    <pre className="code"><code>{ JSON.stringify(item.payload,
                        null, 2
                    ) }</code></pre>
                </>
            ) }
        </ApiDocItemStyles>
    );
};
