import React, { useCallback, useState } from 'react';
import { FooterButtons } from '../../ServerEdit.page';
import serverStore from '../../../../../stores/server.store';
import styled from 'styled-components';
import { EuiButton, EuiFieldText, EuiFormRow } from '@elastic/eui';
import { addToast } from '../../../../components/toasts/Toast';

const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: end;
  grid-gap: 30px;

  .left {
    text-align: left;
    margin-bottom: 4px;
  }
`;

const EditServerName = ({ server }) => {
    const [serverName, setServerName] = useState(server.name);
    const [isSendingSave, setIsSendingSave] = useState(false);

    const handleSubmit = useCallback(async () => {
        if (isSendingSave) {
            return;
        }

        setIsSendingSave(true);
        await serverStore.updateServer(server._id, serverName);
        addToast(
            'Server name changed', 'suc');

        setIsSendingSave(false);
    }, [isSendingSave, serverName]);

    return (
        <Styles>
            <EuiFormRow label="Server Name">
                <EuiFieldText
                    value={ serverName }
                    isInvalid={ false }
                    onChange={ e => setServerName(
                        e.target.value) }
                />
            </EuiFormRow>

            <FooterButtons className={ 'left' }>
                <EuiButton
                    isDisabled={ !serverName }
                    size="s"
                    onClick={ handleSubmit }
                    fill
                    isLoading={ isSendingSave }
                >Save
                </EuiButton>
            </FooterButtons>
        </Styles>
    );
};

export default EditServerName;
