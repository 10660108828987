import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import { useObserver } from 'mobx-react-lite';
import tagsStore from '../../../stores/tags.store';
import serverStore from '../../../stores/server.store';
import styled from 'styled-components';
import {
    BoxedResponsiveStyled, GoBackStyled,
    RunCodeStyled
} from '../../../style/components/common.styled';
import EditServerName from './components/serverEdit/EditServerName';
import LoaderComponent from '../../components/Loader.component';
import TagsModal from '../../components/tags/TagsModal';
import TagsList from '../../components/tags/TagsList';
import AlarmsSettings from '../../components/alarmsSettings/AlarmsSettings';
import alarmsStore from '../../../stores/alarms.store';
import {
    EuiSpacer,
    EuiButton, EuiCodeBlock, EuiCopy
} from '@elastic/eui';
import { addToast } from '../../components/toasts/Toast';
import uptimeStore from '../../../stores/uptime.store';
import { useHistory } from 'react-router-dom';
import { MONITOR_TYPES } from './ServersCreate.page';
import mongoDbStore from "../../../stores/mongodb.store";

export const StyledEditWrapper = styled.div`
  width: 1000px;
  display: grid;
  grid-gap: 20px;
  margin: 10px auto;
  min-height: 500px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 2%;
  }

  h1 {
    font-size: 36px;
    text-align: center;
  }

  h3 {
    text-align: left;
    font-size: 26px;
  }

  .bonya {
    margin-top: 40px;
  }
`;

export const EditZoneStyled = styled.div`
  border: 1px solid rgb(255 255 255 / 25%);
  border-radius: 4px;
  padding: 14px;
`;

export const TagsWrapperStyled = styled.div`
  padding: 10px 0;
`;

export const DeleteServerZoneStyled = styled.div`
  text-align: center;
  margin-bottom: 20px !important;

  border: 1px solid rgb(255 77 79 / 40%);
  border-radius: 4px;
  padding: 14px;

  .are-you-sure {
    margin-bottom: 20px;
  }
`;

export const FooterButtons = styled.div`
  text-align: center;
`;

const DeleteAgentStyled = styled.div`
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid rgb(255 255 255 / 25%);
  border-radius: 4px;
  padding: 14px;
`;

const ServerEditPage = props => {
    document.title = 'Server Edit - SyAgent';
    const { serverId } = props.match.params;

    const redirect = () => {
        props.history.push('/servers');
    };

    return useObserver(() => (
        <Observed
            tags={ tagsStore.tags }
            serverId={ serverId }
            handleRedirect={ redirect }
            servers={ serverStore.serversListObj }
            serverAlarmsSettingsObj={ alarmsStore.alarmsSettingsByServer }
        />
    ));
};

const Observed = (props) => {
    const {
        serverId,
        servers,
        tags,
        handleRedirect,
        serverAlarmsSettingsObj
    } = props;

    const history = useHistory();
    const [loader, setLoader] = useState(true);

    const server = servers[serverId];
    const serverAlarmsSettings = serverAlarmsSettingsObj[serverId];

    useEffect(async () => {
        const getsData = () => {
            return Promise.all([
                serverStore.getServers(),
                tagsStore.getTags(),
                alarmsStore.getAlarmsSettings(serverId),
                uptimeStore.getUptime(),
                mongoDbStore.getMongoDb()
            ]);
        };
        await getsData();
        setLoader(false);
    }, []);

    const goSomewhere = e => {
        e.preventDefault();
        history.push(`/servers/${ serverId }`);
    };

    const [areYouSureState, setAreYouSureState] = useState(false);
    const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
    const [deleteSending, setDeleteSending] = useState(false);

    const handleDelete = useCallback(async e => {
        if (deleteSending) {
            return;
        }

        setDeleteSending(true);
        await serverStore.deleteServer(server._id);
        addToast(
            'Server deleted', 'suc');
        handleRedirect();

    }, [deleteSending]);

    return (
        <main className="servers">
            { loader && (
                <LoaderComponent/>
            ) }
            { !loader && (
                <>
                    <Header/>
                    <StyledEditWrapper>
                        <GoBackStyled>
                            <EuiButton onClick={ goSomewhere }
                                       size="s"
                                       iconType={ 'returnKey' }
                                       color={ 'text' }>
                                Go back to Server
                            </EuiButton>
                        </GoBackStyled>
                        <BoxedResponsiveStyled>
                            <h1>Server management</h1>

                            <EditZoneStyled className="bonya">
                                <h3>Edit server</h3>
                                { server && (
                                    <EditServerName server={ server }
                                    />
                                ) }
                            </EditZoneStyled>
                            <EditZoneStyled className="bonya">
                                <h3>Tags (select)</h3>
                                <TagsWrapperStyled>
                                    { server && (
                                        <TagsList
                                            serverId={ serverId }
                                            serverTags={ server.tags }
                                            monitorType={ MONITOR_TYPES.SERVER }
                                        />
                                    ) }
                                </TagsWrapperStyled>
                                <EuiButton
                                    size="s"
                                    onClick={ () => setIsTagsModalOpen(true) }
                                    fill
                                >
                                    Edit or Add tags
                                </EuiButton>

                            </EditZoneStyled>
                            <EditZoneStyled className="bonya">
                                { server && serverAlarmsSettings && (
                                    <AlarmsSettings
                                        serverAlarmsSettings={ serverAlarmsSettings }/>
                                ) }
                            </EditZoneStyled>
                            <DeleteAgentStyled className="bonya">
                                <h3>Agent</h3>
                                <RunCodeStyled>
                                    To remove agent run
                                    <EuiSpacer size="l"/>
                                    <EuiCodeBlock language="jsx" fontSize="m"
                                                  paddingSize="m">
                                        rm -R /etc/syAgent && (crontab -u
                                        syAgent -l | grep -v
                                        "/etc/syAgent/sh-agent.sh") |
                                        crontab -u
                                        syAgent - && userdel syAgent
                                    </EuiCodeBlock>
                                    <EuiSpacer size="l"/>

                                    <EuiCopy
                                        textToCopy={ 'rm -R /etc/syAgent && (crontab -u syAgent -l | grep -v "/etc/syAgent/sh-agent.sh") | crontab -u syAgent - && userdel syAgent' }>
                                        { copy => (
                                            <EuiButton onClick={ copy }
                                                       iconType={ 'copyClipboard' }
                                                       color={ 'success' }>Click
                                                to
                                                copy</EuiButton>
                                        ) }
                                    </EuiCopy>
                                </RunCodeStyled>
                            </DeleteAgentStyled>
                            <DeleteServerZoneStyled className="bonya">
                                <h3>Danger zone</h3>
                                { !areYouSureState && (
                                    <EuiButton
                                        size="s"
                                        iconType={ 'trash' }
                                        color="danger"

                                        onClick={ () => setAreYouSureState(
                                            true) }
                                        fill
                                    >Delete Server
                                    </EuiButton>
                                ) }

                                { areYouSureState && (
                                    <>
                                        <div
                                            className="are-you-sure">Are you
                                            sure?
                                        </div>
                                        <EuiButton
                                            size="s"
                                            color="danger"

                                            onClick={ handleDelete }
                                            fill
                                        >
                                            Yes
                                        </EuiButton>

                                        <EuiButton
                                            size="s"
                                            onClick={ () => setAreYouSureState(
                                                false) }
                                            fill
                                        >
                                            No
                                        </EuiButton>
                                    </>
                                ) }
                            </DeleteServerZoneStyled>
                        </BoxedResponsiveStyled>
                    </StyledEditWrapper>
                </>
            ) }

            { isTagsModalOpen && (
                <TagsModal close={ () => setIsTagsModalOpen(false) }/>
            ) }
        </main>
    );
};

export default ServerEditPage;
