import React, { useState } from 'react';
import styled from 'styled-components';
import LoaderComponent from '../../../../components/Loader.component';
import spikesStore from '../../../../../stores/spikes.store';
import SpikesChart from './SpikesChart';
import { Icon } from '@blueprintjs/core';
import {
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiTitle
} from '@elastic/eui';

const ShowSpikeStyled = styled.div`
  color: #1890ff;
  cursor: pointer;
  font-size: 14px;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 3px;

  svg{
    path{
      fill: #1890ff;
    }
  }
`;

const ShowSpikeWrapperStyled = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const ShowSpike = ({ type, serverId }) => {
    const [showDrawler, setShowDrawler] = useState(false);
    const [loader, setLoader] = useState(true);

    const getDataAndShowDrawler = async () => {
        setShowDrawler(true);
        setLoader(true);
        await spikesStore.getSpikes(serverId, type);
        setLoader(false);
    };

    return (
        <ShowSpikeWrapperStyled>
            <ShowSpikeStyled
                onClick={ () => getDataAndShowDrawler() }
            >
                Spikes
                <Icon icon="grouped-bar-chart" size={ 16 } indent="none"/>
            </ShowSpikeStyled>

            {showDrawler && (
                <EuiFlyout
                    size="l"
                    ownFocus={true}
                    outsideClickCloses={true}
                    onClose={()=>setShowDrawler(false)}
                >
                    <EuiFlyoutHeader hasBorder>
                        <EuiTitle>
                            <h2>{`${ type.toUpperCase() } spikes`}</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>
                    <EuiFlyoutBody>
                        { loader && (
                            <LoaderComponent/>
                        ) }

                        { !loader && (
                            <SpikesChart
                                type={ type }
                                serverId={ serverId }
                                data={ spikesStore.spikesByServerId[serverId +
                                type] }
                            />
                        ) }
                    </EuiFlyoutBody>
                </EuiFlyout>
            )}


            {/*{ showDrawler && (*/}
            {/*    <MainDrawler*/}
            {/*        onClose={ () => setShowDrawler(false) }*/}
            {/*        title={ ` ${ type.toUpperCase() } spikes` }*/}
            {/*    >*/}
            {/*        { loader && (*/}
            {/*            <LoaderComponent/>*/}
            {/*        ) }*/}

            {/*        { !loader && (*/}
            {/*            <SpikesChart*/}
            {/*                type={ type }*/}
            {/*                serverId={ serverId }*/}
            {/*                data={ spikesStore.spikesByServerId[serverId +*/}
            {/*                type] }*/}
            {/*            />*/}
            {/*        ) }*/}
            {/*    </MainDrawler>*/}
            {/*) }*/}
        </ShowSpikeWrapperStyled>
    );
};

export default ShowSpike;