import userStore from '../stores/user.store';
import appSettings from '../constants/settings.constants';

const fetchMode = { mode: 'cors' };

class FetchService {
    authenticatedGet = async url => {
        const headers = { 'Authorization': `Bearer ${ userStore.userToken }` };
        const request = new Request(`${ appSettings.apiUrl }${ url }`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        );
    };

    authenticatedPost = async (url, body) => {
        const headers = {
            'Authorization': `Bearer ${ userStore.userToken }`,
            'Content-Type': 'application/json'
        };
        const request = new Request(`${ appSettings.apiUrl }${ url }`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        );
    };

    authenticatedDelete = async url => {
        const headers = { 'Authorization': `Bearer ${ userStore.userToken }` };
        const request = new Request(`${ appSettings.apiUrl }${ url }`, {
            method: 'DELETE',
            headers: headers
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        );
    };

    authenticatedPut = async (url, body) => {
        const headers = {
            'Authorization': `Bearer ${ userStore.userToken }`,
            'Content-Type': 'application/json'
        };
        const request = new Request(`${ appSettings.apiUrl }${ url }`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        );
    };

    authenticatedPatch = async (url, body) => {
        const headers = {
            'Authorization': `Bearer ${ userStore.userToken }`,
            'Content-Type': 'application/json'
        };
        const request = new Request(`${ appSettings.apiUrl }${ url }`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        );
    };

    patch = async (url, body) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        const request = new Request(`${ appSettings.apiUrl }${ url }`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        );
    };
    post = async (url, body) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        const request = new Request(`${ appSettings.apiUrl }${ url }`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        );
    };
    get = async url => {
        const headers = {
            'Content-Type': 'application/json'
        };
        const request = new Request(`${ appSettings.apiUrl }${ url }`, {
            method: 'GET',
            headers: headers
        });

        return fetch(request, fetchMode).then((response) => {
                if (response) {
                    return (this.checkResponse(response));
                }
            }
        );
    };

    errorHandler = error => {
        if (error.status === 401) {
            window.location.reload(true);
        } else {
            return error;
        }
    };

    checkResponse = response => {
        const status = response.status;
        if (status === 200) {
            return (response.json());
        }else {
            return (response.json());
        }
    };
}

const fetchService = new FetchService();
export default fetchService;
