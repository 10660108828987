import React from 'react';
import CreateServerWrapper from './createNewMonitor/CreateServerWrapper';

export const MONITOR_TYPES = {
    SERVER: 'SERVER',
    HEARTBEAT: 'UPTIME',
    MONGO: 'MONGO'
};

const ServersCreatePage = () => {
    document.title = 'Create New Monitor - SyAgent';

    return (<CreateServerWrapper/>);

};

export default ServersCreatePage;