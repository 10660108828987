export const spikesTypes = {
    CPU: "cpu",
    RAM: "ram",
    SESSIONS: "sessions",
    DISK: "disk",
    CONNECTIONS: "connections"
};

export const alarmTypes = {
    SERVER_STOPPED: "server_stopped_getting_data",
    SESSIONS_HIGH: "sessions_high",
    CONNECTION_HIGH: "connections_high",
    HIGH_RAM_80: "high_ram_80",
    HIGH_RAM_90: "high_ram_90",
    HIGH_DISK_80: "high_disk_80",
    HIGH_DISK_90: "high_disk_90",
    RX_HIGH: "rx_high",
    TX_HIGH: "tx_high"
};

export const alarmsSettingsTypes = [
    {
        title: "CPU usage 100%",
        key: "cpu_high"
    },
    {
        title: "Increase sessions",
        key: "sessions_high"
    },
    {
        title: "Increase connections",
        key: "connections_high"
    },
    {
        title: "Memory usage 80%",
        key: "high_ram_80"
    },
    {
        title: "Memory usage 90%",
        key: "high_ram_90"
    },
    {
        title: "Disk usage 80%",
        key: "high_disk_80"
    },
    {
        title: "Disk usage 90%",
        key: "high_disk_90"
    },
    {
        title: "Incoming traffic is high",
        key: "rx_high"
    },
    {
        title: "Outgoing traffic is high",
        key: "tx_high"
    }
];

export const serverFilterTypes = {
    createdAt: {
        val: "createdAt",
        iconType: "tokenDate",
        color: "#19362e",
        label: "Created Date"
    },
    alarms: {
        val: "alarms",
        iconType: "alert",
        color: "#19362e",
        label: "Total alarms"
    },
    name: {
        val: "name",
        iconType: "tokenNamespace",
        color: "#19362e",
        label: "Monitor name"
    },
    tags: {
        val: "tags",
        iconType: "tag",
        color: "#19362e",
        label: "Tags"
    },
    load_cpu: {
        val: "load_cpu",
        iconType: "compute",
        color: "tint2",
        label: "CPU usage"
    },
    ram_usage: {
        val: "ram_usage",
        iconType: "compute",
        color: "tint2",
        label: "Memory usage"
    },
    disk_usage: {
        val: "disk_usage",
        iconType: "compute",
        color: "tint2",
        label: "Disk usage"
    }
};

export const uptimeFilterTypes = {
    createdAt: {
        val: "createdAt",
        iconType: "tokenDate",
        color: "#19362e",
        label: "Created Date"
    },
    alarms: {
        val: "alarms",
        iconType: "alert",
        color: "#19362e",
        label: "Total alarms"
    },
    name: {
        val: "name",
        iconType: "tokenNamespace",
        color: "#19362e",
        label: "Monitor name"
    },
    tags: {
        val: "tags",
        iconType: "tag",
        color: "#19362e",
        label: "Tags"
    },
    interval: {
        val: "interval",
        val_2: "customInterval",
        iconType: "timeRefresh",
        color: "tint1",
        label: "Interval"
    },
    scheduleTo: {
        val: "scheduleTo",
        iconType: "timeRefresh",
        color: "tint1",
        label: "Schedule to"
    },
};
