import React from 'react';
import '../../style/loader.css';

const LoaderComponent = () => {
    return (
        <div className='loader-wrapper'>
            <div className="container">
                <div className="dot-container">
                    <div className="dot"/>
                    <div className="dot"/>
                    <div className="dot"/>
                </div>
                <div className="dot-container">
                    <div className="dot"/>
                    <div className="dot"/>
                    <div className="dot"/>
                </div>
                <div className="dot-container">
                    <div className="dot"/>
                    <div className="dot"/>
                    <div className="dot"/>
                </div>
            </div>

        </div>
    );
};

export default LoaderComponent;