class ValidationsService {
    validatePassword(pass) {
        const result = {
            isValid: false,
            message: 'Password length must be more than 6'
        };
        if (pass && pass.length > 6) {
            result.isValid = true;
        }

        return result;
    }

    validateUrl(url) {
        const res = url.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if (res == null) {
            return false;
        } else {
            return true;
        }
    }

    validateIp(ip) {
        const ipv4 = ip.match(
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/);
        if (ipv4) {
            return true;
        }

        const ipv6 = ip.split(':'); // 2a01:4f8:c2c:4722::/64
        if (ipv6.length > 4) {
            return true;
        }

        return false;
    }
}

const validationService = new ValidationsService();
export default validationService;