import styled from 'styled-components';

export const BoxedStyled = styled.section`
  margin: 10px auto;
  width: 500px;
  padding: 10px 0;
  border-radius: 6px;

  background-color: rgb(29, 30, 36);
  box-shadow: rgb(0 0 0 / 28%) 0px 0.9px 4px, rgb(0 0 0 / 21%) 0px 2.6px 8px, rgb(0 0 0 / 18%) 0px 5.7px 12px, rgb(0 0 0 / 14%) 0px 15px 15px;

`;

export const BoxedResponsiveStyled = styled.section`
  margin: 10px auto;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  min-height: 200px;
  //box-shadow: 0 0 0 1px rgba(16,22,26,.4), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0);
  //
  //border: 1px solid rgb(52, 55, 65);
  //background-color: rgb(20, 21, 25);
  background-color: rgb(29,30,36);
  box-shadow: rgb(0 0 0 / 28%) 0px 0.9px 4px, rgb(0 0 0 / 21%) 0px 2.6px 8px, rgb(0 0 0 / 18%) 0px 5.7px 12px, rgb(0 0 0 / 14%) 0px 15px 15px;
  
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px, rgba(0, 0, 0, 0.243) 0px 3.6px 13px, rgba(0, 0, 0, 0.21) 0px 8.4px 23px, rgba(0, 0, 0, 0.176) 0px 23px 35px;
    transform: translateY(-2px);
  }
  
  h3{
    font-size: 18px;
    margin: 0 0 10px 0;
    display: grid;
    align-items: flex-start;
    grid-template-columns: auto 1fr;
    grid-gap: 5px;
  }
  
  &.without-margin{
    margin-top: 0;
  }
`;

export const ServersListStyled = styled.section`
  margin: 10px auto;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  min-height: 200px;
  
  h3{
    font-size: 18px;
    margin: 0 0 10px 0;
    display: grid;
    align-items: flex-start;
    grid-template-columns: auto 1fr;
    grid-gap: 5px;
  }
  
  &.without-margin{
    margin-top: 0;
  }
`;

export const MarginTopStyled = styled.div`
  margin: 17px 0;
`;

export const SoonStyled = styled.div`
  text-align: center;
  font-size: 24px;
  display: grid;
  align-content: center;
  justify-content: center;
  height: 100%;
`;

export const EmptyWidgetStyled = styled.div`
  text-align: center;
  font-size: 15px;
`;

export const CodeAreaStyled = styled.pre`
  white-space: pre-wrap;
  background-color: black;
  color: white;
  font-size: medium;
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
  width: 100%;
  display: inline-block;

  line-height: 1.4;
  margin: 10px 0 25px;
  padding: 10px;

   @media (max-width: 600px) {
    word-break: break-all;
  }
`;

export const RunCodeStyled = styled.div`
  text-align: center;
  margin: 20px;

  .done {

  }
`;

export const FlowStyled = styled.div`
  width: 540px;
  margin: 40px auto;
  font-size: 17px;

  h3 {
    font-size: 23px;
    text-align: center;
  }

  ul {
    margin: 10px 0;
    list-style: auto;
    text-align: left;
  }

  li {
    margin: 9px 0;
    line-height: 1.3;
  }


   @media (max-width: 600px) {
    width: 100%;
    padding: 10px 40px;
  }
`;

export const GoBackStyled = styled.div`
  
`;
