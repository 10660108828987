import React, { useState } from "react";
import styled from "styled-components";
import ModalAlarm from "../../../../components/modal/NewModalAlarm";
import utilsService from "../../../../../servises/utils.service";
import {
    EmptyWidgetStyled
} from "../../../../../style/components/common.styled";
import AlarmMoreInfo from "../../../../components/alarmsMoreInfo/AlarmMoreInfo";
import alarmsStore from "../../../../../stores/alarms.store";
import { EuiIcon, EuiToolTip } from "@elastic/eui";

const StylesWrapper = styled.div`

`;

const HeaderStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const MarkAllAsRead = styled.div`
  cursor: pointer;
`;

const Styles = styled.div`
  height: 280px;
  overflow: auto;

  @media (max-width: 600px) {
    height: auto;
  }
`;

const ItemGroupStyles = styled.div`
  label {
    color: rgba(255, 255, 255, 0.65);
    font-size: 11px;
    margin: 7px 3px;
    display: block;
  }
`;

const AlarmItemStyled = styled.div`
  display: grid;
  grid-template-columns: 21px auto;
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  margin: 4px 0;
  padding: 6px 0;
  border-radius: 4px;
  position: relative;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    background: transparent;
  }

  &.read {
    background: rgba(255, 255, 255, 0.01);
  }
`;

const AlarmItemTextStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  span {
    margin-right: 3px;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.65);
  }

  .cont {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.85);
  }
`;

const SeverityStyled = styled.div`
  position: relative;
  display: grid;
  align-self: center;
  height: 6px;

  &:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 30px;
    //top: 4px;
    top: 18%;
    margin-left: 4px;
  }

  &.info {
    &:before {
      background: rgb(0, 128, 213) !important;
    }
  }

  &.warning {
    &:before {
      background: #f9be49 !important;
    }
  }

  &.critical {
    &:before {
      background: #F86B63 !important;
    }
  }

  &.read {
    &:before {
      background: gray !important;
    }
  }
`;

const AlarmItemDataStyles = styled.div`
  color: rgba(255, 255, 255, 0.65);
  span {
    margin-right: 3px;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.65);
  }
`;

// createdAt: 1610919190704
// createdAt_po: "2021-01-17T21:31:19.518Z"
// message: null
// messageType: "server_stopped_getting_data"
// serverId: "5ffc6cb0786828181850e5c3"
// severity: 30
// status: 1
// updatedAt: 1610919190704
// updatedAtN: "2021-01-17T21:31:19.518Z"
// __v: 0
// _id: "6004ad16b8b69d33b4086631"

const AlarmsWidget = ({ alarms, server, hideTitle }) => {
    const [showModal, setShowModal] = useState(false);
    const [alarmForModal, setAlarmForModal] = useState(null);

    const showAlarm = (alarm, text, server, severity) => {
        setAlarmForModal({ alarm, text, server, severity });
        setShowModal(true);
    };

    const checkIfAlarms = () => {
        let verdict = false;

        if (alarms) {
            // can be generic? Of course, but I wanna see what am I doing
            if (alarms.today && alarms.today.length > 0) {
                verdict = true;
            }
            if (alarms.rest && alarms.rest.length > 0) {
                verdict = true;
            }
            if (alarms.lastWeek && alarms.lastWeek.length > 0) {
                verdict = true;
            }
        }

        return verdict;
    };

    const haveAlarms = checkIfAlarms();

    const markAllAlarmsAsReadHandle = () => {
        const ids = [];

        if (alarms.today && alarms.today.length > 0) {
            alarms.today.forEach(al => {
                ids.push(al._id);
            });
        }
        if (alarms.rest && alarms.rest.length > 0) {
            alarms.rest.forEach(al => {
                ids.push(al._id);
            });
        }
        if (alarms.lastWeek && alarms.lastWeek.length > 0) {
            alarms.lastWeek.forEach(al => {
                ids.push(al._id);
            });
        }

        alarmsStore.setAllAlarmStatusRead(ids);
    };

    return (
        <StylesWrapper>
            <HeaderStyled>
                { !hideTitle && (
                    <h3><EuiIcon type="alert" size="m"/> Alarms</h3>
                ) }

                { haveAlarms && (
                    <MarkAllAsRead
                        onClick={ markAllAlarmsAsReadHandle }
                    >
                        Mark all as read
                    </MarkAllAsRead>
                ) }
            </HeaderStyled>
            <Styles>
                { haveAlarms && (
                    <>{ haveAlarms && alarms.today.length > 0 &&
                        (<ItemGroupStyles>
                            <label>Last 24 hours</label>
                            { haveAlarms && alarms.today.map(alarm => {
                                return (
                                    <AlarmItemO
                                        key={ alarm._id }
                                        data={ alarm }
                                        showAlarm={ showAlarm }
                                        server={ server }
                                    />
                                );
                            }) }
                        </ItemGroupStyles>)
                    }

                        { haveAlarms && alarms.lastWeek.length > 0 && (
                            <ItemGroupStyles>
                                <label>Last Week</label>
                                { haveAlarms && alarms.lastWeek.map(alarm => {
                                    return (
                                        <AlarmItemO
                                            key={ alarm._id }
                                            data={ alarm }
                                            showAlarm={ showAlarm }
                                            server={ server }
                                        />
                                    );
                                }) }
                            </ItemGroupStyles>
                        ) }

                        { haveAlarms && alarms.rest.length > 0 && (
                            <ItemGroupStyles>
                                <label>Old Alarms</label>
                                { haveAlarms && alarms.rest.map(alarm => {
                                    return (
                                        <AlarmItemO
                                            key={ alarm._id }
                                            data={ alarm }
                                            showAlarm={ showAlarm }
                                            server={ server }
                                        />
                                    );
                                }) }
                            </ItemGroupStyles>
                        ) }
                    </>
                ) }

                { !haveAlarms && (
                    <EmptyWidgetStyled>
                        Yay, no alarms
                    </EmptyWidgetStyled>
                ) }

                { showModal && (
                    <ModalAlarm
                        data={ alarmForModal }
                        close={ () => setShowModal(false) }
                    />
                ) }
            </Styles>
        </StylesWrapper>
    );
};

export default AlarmsWidget;

const AlarmItemO = ({ data, showAlarm, server }) => {
    return (
        <AlarmItemStyled
            className={ data.statusClass }
            key={ data._id }
            onClick={ () => showAlarm(data, data.text, server,
                data.severityData
            ) }
        >
            <SeverityStyled
                className={ `${ data.severityData.classes } ${ data.statusClass }` }/>
            <AlarmItemTextStyled>
                <AlarmItemDataStyles>
                    <div>
                        <span>{ utilsService.timeMe(data.createdAt) }</span><span>{ utilsService.dateMe(data.createdAt) }</span>
                    </div>
                    <div>{data.serverName}</div>
                </AlarmItemDataStyles>


                <EuiToolTip
                    position="left"
                    content={
                        <AlarmTooltip
                            alarm={ data }
                            severity={ data.severityData }
                            text={ data.text }
                        />
                    }
                >
                    <div className="cont">{ data.text }</div>
                </EuiToolTip>


                {/*<Tooltip2 className={ '' } minimal={ true }*/ }
                {/*          content={*/ }
                {/*              <AlarmTooltip*/ }
                {/*                  alarm={ data }*/ }
                {/*                  severity={ data.severityData }*/ }
                {/*                  text={ data.text }*/ }
                {/*              /> }*/ }
                {/*>*/ }
                {/*    <div className="cont">{ data.text }</div>*/ }
                {/*</Tooltip2>*/ }

            </AlarmItemTextStyled>

        </AlarmItemStyled>
    );
};

const AlarmTooltip = ({ alarm, severity, text }) => {
    return (
        <AlarmMoreInfo
            alarm={ alarm }
            severity={ severity }
            text={ text }
        />
    );
};
