import React from "react";
import {
    LineChart,
    AreaChart,
    Line,
    Area,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import styled from "styled-components";;

export const BaseChartStyled = styled.div`

`;

export const TooltipshiChartStyled = styled.div`
  //background: #0e1b25;
  background-color: rgb(52,55,65);
  
  div{
    margin: 5px;
    padding: 5px;
  }
`;

const BaseLineChart = ({ data }) => {
    const dataKey = data.key;

    return (
        <BaseChartStyled>
            <ResponsiveContainer width={ "100%" } height={ 300 }>
                <AreaChart data={ data.data }>
                    {/*<CartesianGrid strokeDasharray="3 3" />*/}
                    {/*<XAxis dataKey="name" />*/}
                    {/*<YAxis />*/}
                    {/*<Tooltip />*/}
                    {/*<Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />*/}
                    {/*<Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />*/}
                    {/*<Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />*/}
                    {/*<Line type="monotone" dataKey=data.key stroke="#8884d8"/>*/ }
                    {/*<Line type="monotone" dataKey={ dataKey } stroke="rgb(80, 188, 94)"/>*/ }
                    <Area type="monotone" dataKey="total" stroke="#8884d8" fill="rgb(52, 55, 65)" unit={ data.ext }/>
                    <Area type="monotone" dataKey={ dataKey } stroke="rgb(0, 128, 213)" fill="rgb(0, 128, 213)" unit={ data.ext }/>

                    {/*<Line type="monotone" dataKey={ dataKey } stroke="rgb(0, 128, 213)" unit={ data.ext }/>*/}
                    {/*<Line type="monotone" dataKey="total" stroke="#8884d8" unit={ data.ext }/>*/}
                    <CartesianGrid strokeDasharray="3 3" stroke="rgb(52,55,65)"/>
                    <XAxis dataKey="name" stroke={ "rgba(255, 255, 255, 0.65)" }/>
                    <Tooltip content={ <CustomTooltip/> }/>
                    <YAxis stroke={ "rgba(255, 255, 255, 0.65)" }  unit={ data.ext }/>
                </AreaChart>
            </ResponsiveContainer>
        </BaseChartStyled>
    );
};

// background: rgb(0, 128, 213);

export default BaseLineChart;

function CustomTooltip(props) {
    const { payload, label, active } = props;

    if (active) {
        return (
            <TooltipshiChartStyled className="custom-tooltip">
                { payload && payload.length > 0 && (
                    payload.map((relod, idx) => {
                        return (
                            <div key={ idx + "dddw200" }>
                                <p className="label">{ `${ relod.name } : ${ relod.value } ${ relod.unit }` }</p>
                            </div>
                        );
                    })
                ) }
            </TooltipshiChartStyled>
        );
    }

    return null;
}
