import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '@fluentui/react';
import { Link } from 'react-router-dom';
import userStore from '../../../stores/user.store';
import { DangerButtonStyled } from '../../../style/components/button.styled';

const Styles = styled.div`

`;

const WrapperStyles = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  grid-gap: 20px;

  .kzpd {
    width: 300px;
    background-color: #122330;
    border-radius: 2px;
    text-align: center;
    padding: 9px 0;
  }
`;

const NoMoneyNoHoneyStyles = styled.div`

`;

const ApiKeyStyles = styled.div`

`;

const GenerateApiKeyStyles = styled.div`

`;

const ApiKey = ({ sub, apiKey }) => {
    let key = apiKey ? apiKey : 'No API key';
    let canGenerateApiKey = false;
    if (sub && sub.status === 'ACTIVE') {
        if (sub.subscriptionNumber > 3 && sub.subscriptionNumber < 12) {
            canGenerateApiKey = true;
        }
    }

    return (
        <Styles>
            <h3>API key</h3>

            { !canGenerateApiKey && (
                <NoMoneyNoHoneyStyles>
                    In order to get apiKey you need to be supporter with API
                    access. To support - <Link to={ '/donate' }>Donate</Link>
                </NoMoneyNoHoneyStyles>
            ) }

            { canGenerateApiKey && (
                <WrapperStyles>
                    <div className="kzpd">
                        { key }
                    </div>
                    { apiKey && (
                        <ApiKeyStyles>
                            <DangerButtonStyled
                                onClick={ () => userStore.deleteApiKey() }
                            >
                                Delete
                            </DangerButtonStyled>
                        </ApiKeyStyles>
                    ) }

                    { !apiKey && (
                        <GenerateApiKeyStyles>
                            <PrimaryButton
                                text={ 'Generate' }
                                onClick={ () => userStore.generateApiKey() }
                            />
                        </GenerateApiKeyStyles>
                    ) }
                </WrapperStyles>
            ) }
        </Styles>
    );
};

export default ApiKey;