import React from 'react';
import ServerStatus from '../../../components/metrics/ServerStatus';
import mongoDbStore from '../../../../stores/mongodb.store';
import { useObserver } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { BoxedResponsiveDashboardStyled, ColumnStyled, ServerNameStyled } from './ServerItem2';
import styled from 'styled-components';
import { EuiIcon } from '@elastic/eui';
import ServerComInfo from './ServerComInfo';
import FillBar from '../../../components/metrics/FillBar';
import utilsService from '../../../../servises/utils.service';
import AlarmsWidget from './serverWidgets/AlarmsWidget';
import DbItemComponent from './mongo/DbComponent';
import CollectionItemComponent from './mongo/CollectionItemComponent';
import alarmsStore from '../../../../stores/alarms.store';
import mongoDbMetricsStore from '../../../../stores/mongoMetrics.store';
import { getConnectionsData } from './mongo/CommonFuncs';
import TinyLineChart from '../../../components/charts/TinyLineChart';

const MongoItemWrapper = props => {
    return useObserver(() => (
        <>
            <ServerStatus
                server={ mongoDbStore.mongoDbListObj[props.serverId] }
                url={ 'mongo' }
            />
            <MongoItem
                server={ mongoDbStore.mongoDbListObj[props.serverId] }
                alarms={ alarmsStore.alarmsByServerObj[props.serverId] }
                metrics={ mongoDbMetricsStore.mongoDbMetrics }
            />
        </>
    ));
};

const MainPageGridStyled = styled.section`
  display: grid;
  padding: 1px 10px;
  margin-top: 8px;
  grid-gap: 8px;
  grid-template-columns: 4fr 2fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  .pewka-dzkkdkd {
    display: grid;
    grid-gap: 8px;

    @media (max-width: 600px) {
      display: block;
    }
  }

  .llddeeassdd {
    background: #0e1b25;
    height: 32px;
  }

  .llddeeassdd-s {

  }

  .leftrrr {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
`;

const DbsStyled = styled.section`
  display: grid;
  padding: 1px 10px;
  margin-top: 8px;
  grid-gap: 8px;
  grid-template-columns: repeat(6, 1fr);
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export default MongoItemWrapper;

const MongoItem = props => {
    const history = useHistory();

    const { server, alarms, metrics } = props;
    const {
        networkBytesIn,
        networkBytesOut,
        metric, networkNumRequests, totalSizeFormated, totalDbUsageFormated, totalSizeMb, totalDbUsage
    } = server;
    const { serverStatus, dataBases, dbsStats } = metric;
    const { connections, repl, storageEngine } = serverStatus;

    if (server) {
        document.title = `${ server.name } - SyAgent`;
    } else {
        history.push('/404');
    }

    const connectionsChart = getConnectionsData(metrics);

    return (
        <div className="mongo">
            <ServerNameStyled>{ server.name }</ServerNameStyled>
            <MainPageGridStyled>
                <div className="leftrrr">
                    <ColumnStyled className="column petra">
                        <BoxedResponsiveDashboardStyled
                            className="column-body">
                            <h3><EuiIcon type="securitySignal" size="m"/>Database Info</h3>
                            { generateMongoInfo(server).map((item, index) => (
                                <ServerComInfo
                                    key={ 'daw444lldadawd' + index }
                                    name={ item.name }
                                    data={ item.data }
                                />)) }
                        </BoxedResponsiveDashboardStyled>

                        <BoxedResponsiveDashboardStyled
                            className="column-body">
                            <h3><EuiIcon type="memory" size="m"/>Connections</h3>
                            <FillBar
                                name={ 'Connections:' }
                                total={ connections.available }
                                totalUnit={ '' }
                                current={ connections.current }
                                currentUnit={ '' }
                                originalTotal={ connections.available }
                                originalCurrent={ connections.current }
                            />
                            { connectionsChart && (
                                <div style={ { marginTop: '40px' } }>
                                    <TinyLineChart
                                        data={ connectionsChart }
                                    />
                                </div>
                            ) }
                        </BoxedResponsiveDashboardStyled>
                    </ColumnStyled>

                    <ColumnStyled className="column">
                        <BoxedResponsiveDashboardStyled
                            className="column-body">
                            <h3><EuiIcon type="storage" size="m"/>Storage</h3>
                            <ServerComInfo
                                name={ 'Storage engine' }
                                data={ storageEngine.name }
                            />
                            <ServerComInfo
                                name={ 'Persistent' }
                                data={ storageEngine.persistent ? 'Yes' : 'No' }
                            />

                            <FillBar
                                name={ 'Usage:' }
                                total={ totalSizeFormated.value }
                                totalUnit={ totalSizeFormated.unit }
                                current={ totalDbUsageFormated.value }
                                currentUnit={ totalDbUsageFormated.unit }
                                originalTotal={ totalSizeMb }
                                originalCurrent={ totalDbUsage / 1024 / 1024 }
                            />
                        </BoxedResponsiveDashboardStyled>
                        <BoxedResponsiveDashboardStyled
                            className="column-body">
                            <h3><EuiIcon type="globe" size="m"/>Network</h3>
                            <ServerComInfo
                                name={ 'Number of requests' }
                                data={ networkNumRequests }
                            />
                            <ServerComInfo
                                name={ 'Data Received' }
                                data={ `${ networkBytesIn.value } ${ networkBytesIn.unit }` }
                            />

                            <ServerComInfo
                                name={ 'Data Sent' }
                                data={ `${ networkBytesOut.value } ${ networkBytesOut.unit }` }
                            />


                        </BoxedResponsiveDashboardStyled>
                    </ColumnStyled>

                    <ColumnStyled className="column">
                        <BoxedResponsiveDashboardStyled
                            className="column-body">
                            <h3><EuiIcon type="memory" size="m"/>Memory</h3>
                            <FillBar
                                name={ 'Usage:' }
                                total={ 0 }
                                totalUnit={ 'mb' }
                                current={ 0 }
                                currentUnit={ 'mb' }
                                originalTotal={ 0 }
                                originalCurrent={ 0 }
                            />
                            <FillBar
                                name={ 'Virtual:' }
                                total={ 0 }
                                totalUnit={ 'mb' }
                                current={ 0 }
                                currentUnit={ 'mb' }
                                originalTotal={ 0 }
                                originalCurrent={ 0 }
                            />
                        </BoxedResponsiveDashboardStyled>
                        <BoxedResponsiveDashboardStyled
                            className="column-body">
                            <h3><EuiIcon type="compute" size="m"/>Op counters</h3>
                            { generateOpCounters(server).map((item, index) => (
                                <ServerComInfo
                                    key={ 'daw444lldadawd' + index }
                                    name={ item.name }
                                    data={ item.data }
                                />)) }
                        </BoxedResponsiveDashboardStyled>
                    </ColumnStyled>

                    <ColumnStyled className="column">
                        <BoxedResponsiveDashboardStyled
                            className="column-body">
                            <h3><EuiIcon type="mapMarker" size="m"/>Replication</h3>
                            <ServerComInfo
                                name={ 'Primary' }
                                data={ repl.primary }
                            />
                            { repl.hosts.filter((host => host !== repl.primary)).map((host, index) => {
                                return (
                                    <ServerComInfo
                                        key={ 'daw44433adawd' + index + host }
                                        name={ 'Secondary' }
                                        data={ host }
                                    />);
                            }) }
                            { repl.tags && (
                                <>
                                    <ServerComInfo
                                        name={ 'NodeType' }
                                        data={ repl.tags.nodeType }
                                    />
                                    <ServerComInfo
                                        name={ 'Provider' }
                                        data={ repl.tags.provider }
                                    />
                                    <ServerComInfo
                                        name={ 'Region' }
                                        data={ repl.tags.region }
                                    />
                                    <ServerComInfo
                                        name={ 'Workload Type' }
                                        data={ repl.tags.workloadType }
                                    />
                                </>
                            ) }
                        </BoxedResponsiveDashboardStyled>
                    </ColumnStyled>
                </div>
                <div className="rightttt">
                    <ColumnStyled className="column">
                        <BoxedResponsiveDashboardStyled
                            className="column-body">

                            <AlarmsWidget
                                alarms={ alarms }
                                server={ server }
                            />
                        </BoxedResponsiveDashboardStyled>
                    </ColumnStyled>
                </div>
            </MainPageGridStyled>
            <ServerNameStyled>Databases</ServerNameStyled>
            <DbsStyled>
                { dataBases.map((db, index) => {
                    const dbInfo = dbsStats[db.name];
                    return (
                        <DbItemComponent
                            key={ 'daw444lldadawd' + db.name + index }
                            db={ db }
                            dbInfo={ dbInfo }
                        />
                    );
                }) }
            </DbsStyled>
            <ServerNameStyled>Collections</ServerNameStyled>
            <DbsStyled>
                { dataBases.map((db, index) => {
                    const dbInfo = dbsStats[db.name];
                    return (
                        <CollectionItemComponent
                            key={ 'daw444lldadawd' + db.name + index }
                            db={ db }
                            dbInfo={ dbInfo }
                        />
                    );
                }) }
            </DbsStyled>
        </div>
    );
};

const generateMongoInfo = (server) => {
    const { metric, numberOfHosts } = server;
    const { serverStatus } = metric;

    return [
        {
            name: 'Host',
            data: serverStatus.host
        },
        {
            name: 'Version',
            data: serverStatus.version
        },
        {
            name: 'Data since',
            data: server.timeAgo
        },
        {
            name: 'Uptime',
            data: utilsService.formatSecondsToDate(serverStatus.uptime)
        },
        {
            name: 'Number of hosts',
            data: numberOfHosts
        },
        {
            name: 'Number of databases',
            data: metric.dataBases.length
        }
    ];
};

const generateOpCounters = (server) => {
    const { metric } = server;
    const { serverStatus } = metric;

    return [
        {
            name: 'Insert',
            data: serverStatus.opcounters.insert
        },
        {
            name: 'Query',
            data: serverStatus.opcounters.query
        },
        {
            name: 'Update',
            data: serverStatus.opcounters.update
        },
        {
            name: 'Delete',
            data: serverStatus.opcounters.delete
        },
        {
            name: 'Get more',
            data: serverStatus.opcounters.getmore
        },
        {
            name: 'Command',
            data: serverStatus.opcounters.command
        }
    ];
};

// what to show

// dbs  with collections

// replication - hosts, primary, TAGS
// opcounters - insert, query, update, delete, getmore, command
// memory - mapped, virtual, resident
// Common info - host, uptime, version, num of hosts, num of dbs
// Connections - total, active, available
// Storage - total, used, free, engine, persistent
// network - Total in, total out, number requests
// Alarms