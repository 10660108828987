import React, { useCallback, useEffect, useState } from 'react';
import { FormItem } from '../../auth/Auth.styled';
import { PrimaryButton, TextField } from '@fluentui/react';
import {
    BoxedStyled,
    CodeAreaStyled,
    FlowStyled,
    RunCodeStyled
} from '../../../../style/components/common.styled';
import serverStore from '../../../../stores/server.store';
import { GreenButtonStyled } from '../../../../style/components/button.styled';
import TagsList from '../../../components/tags/TagsList';
import { Link, useHistory } from 'react-router-dom';
import ServerAgentCollected from '../components/ServerAgentCollected';
import styled from 'styled-components';
import alarmsStore from '../../../../stores/alarms.store';
import {
    EuiButton,
    EuiCodeBlock, EuiCopy,
    EuiFieldText,
    EuiFormRow,
    EuiSpacer
} from '@elastic/eui';
import {MONITOR_TYPES} from "../ServersCreate.page";

const TagsListStyled = styled.div`
  border: 1px solid rgb(255 255 255 / 25%);
  border-radius: 4px;
  padding: 14px;
  margin: 33px 0;

  h3 {
    font-size: 24px;
    padding-bottom: 12px;
  }

  > div {
    margin-bottom: 17px;
  }
`;

const BoxedStyledOwn = styled(BoxedStyled)`
  width: 750px;

   @media (max-width: 600px) {
    width: 100%;
  }
`;

const CreateNewServerComponent = ({ setIsTagsModalOpen }) => {
    const history = useHistory();

    const [serverName, setServerName] = useState('');
    const [creationEnabled, setCreationEnabled] = useState(true);
    const [isSending, setIsSending] = useState(false);

    const handleSubmit = useCallback(async () => {
        if (isSending || serverName === '') {
            return;
        }

        setCreationEnabled(false);
        setIsSending(true);

        const server = await serverStore.createServer(serverName);
        await alarmsStore.getAlarmsSettings(server._id);

        setIsSending(false);

    }, [isSending, serverName]);

    useEffect(() => {
        return () => {
            serverStore.serverCreated = null;
        };
    }, []);

    return (
        <BoxedStyledOwn>
            <FormItem>
                <EuiFormRow label="Server Name">
                    <EuiFieldText
                        value={ serverName }
                        isInvalid={ !serverName }
                        disabled={ !creationEnabled }
                        onChange={ e => setServerName(e.target.value) }
                    />
                </EuiFormRow>
            </FormItem>

            { creationEnabled && (
                <FormItem>
                    <EuiButton
                        isDisabled={ !serverName }
                        size="s"
                        color="success"
                        onClick={ handleSubmit }
                        isLoading={ isSending }
                        fill
                    >
                        Add new server
                    </EuiButton>
                </FormItem>
            ) }

            <FlowStyled>
                <h3>How does it work?</h3>
                <ul>
                    <li>Create server name</li>
                    <li>Copy and run code you will get soon to Install <a
                        href="https://github.com/syagent/agent-2"
                        rel="noreferrer"
                        target="_blank">the Agent</a> (The code is
                        OPEN, you can check what
                        is monitored)
                    </li>
                    <li>Wait for data</li>
                    <li>Profit..</li>
                </ul>
            </FlowStyled>

            { serverStore.serverCreated && (
                <RunCodeStyled>
                    Run following code as root in your server to install the
                    agent

                    <EuiSpacer size="l"/>
                    <EuiCodeBlock language="jsx" fontSize="m"
                                  paddingSize="m">
                        wget -N --no-check-certificate
                        https://raw.githubusercontent.com/syagent/agent-2/main/install.sh
                        && bash install.sh { serverStore.serverCreated._id }
                    </EuiCodeBlock>
                    <EuiSpacer size="l"/>


                    <EuiCopy
                        textToCopy={ `wget -N --no-check-certificate https://raw.githubusercontent.com/syagent/agent-2/main/install.sh && bash install.sh ${ serverStore.serverCreated._id }` }>
                        { copy => (
                            <EuiButton onClick={ copy }
                                       iconType={ 'copyClipboard' }
                                       color={ 'success' }>Click
                                to
                                copy</EuiButton>
                        ) }
                    </EuiCopy>

                    <TagsListStyled>
                        <h3>Select tags</h3>
                        <TagsList
                            serverId={ serverStore.serverCreated._id }
                            serverTags={ serverStore.serverCreated.tags }
                            isServerCreated={ true }
                            monitorType={ MONITOR_TYPES.SERVER }
                        />

                        <EuiButton
                            size="s"
                            onClick={ () => setIsTagsModalOpen(true) }
                            fill
                        >
                            Edit or Add tags
                        </EuiButton>
                    </TagsListStyled>

                    <div className="done">
                        <EuiButton
                            size="s"
                            color="success"
                            onClick={ e => {
                                e.preventDefault();
                                history.push('/servers');
                            } }
                            fill
                        >
                            Done
                        </EuiButton>
                    </div>

                    <ServerAgentCollected/>
                </RunCodeStyled>
            ) }

        </BoxedStyledOwn>
    );
};

export default CreateNewServerComponent;