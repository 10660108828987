import styled from 'styled-components';

const BaseButton = styled.button`
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.65);
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3);
  outline: 0;

  &:active, &:focus {
    outline: 0;
  }
`;

export const PrimaryButtonStyled = styled(BaseButton)`
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  &:hover {
    color: #fff;
    background-color: #096dd9;
    border-color: #096dd9;
  }

  &.loader {
    color: transparent !important;
    pointer-events: none;
    position: relative;
    text-shadow: none;

    &:after {
      -webkit-animation: spinAround .5s infinite linear;
      animation: spinAround .5s infinite linear;
      border: 2px solid #dbdbdb;
      border-radius: 50%;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      border-color: transparent transparent #fff #fff !important;
    }
  }
`;

export const DangerButtonStyled = styled(BaseButton)`
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  &:hover {
    color: #fff;
    background-color: #d9363e;
    border-color: #d9363e;
  }

  &.loader {
    color: transparent !important;
    pointer-events: none;
    position: relative;
    text-shadow: none;

    &:after {
      -webkit-animation: spinAround .5s infinite linear;
      animation: spinAround .5s infinite linear;
      border: 2px solid #dbdbdb;
      border-radius: 50%;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      border-color: transparent transparent #fff #fff !important;
    }
  }
`;

export const PrimaryLinkedButtonStyled = styled(BaseButton)`
  color: #1890ff;
  border-color: transparent;
  background: transparent;

  &:hover {
    color: #096dd9;
  }
`;

export const GreenButtonStyled = styled(BaseButton)`
  color: #fff;
  background: rgb(80, 188, 94);
  border-color: rgb(80, 188, 94);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  &:hover {
    color: #fff;
    background-color: hsl(128deg 28% 53%);
    border-color: hsl(128deg 28% 53%);
  }

  &.loader {
    color: transparent !important;
    pointer-events: none;
    position: relative;
    text-shadow: none;

    &:after {
      -webkit-animation: spinAround .5s infinite linear;
      animation: spinAround .5s infinite linear;
      border: 2px solid #dbdbdb;
      border-radius: 50%;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      border-color: transparent transparent #fff #fff !important;
    }
  }

  &.clicked {
    background: rgb(0 141 18);
  }

  &.disabled {
    background: rgba(255, 255, 255, 0.55);
    color: #000;
    border-color: rgba(255, 255, 255, 0.55);
    cursor: default;
  }
`;