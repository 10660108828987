import React from 'react';
import styled from 'styled-components';
import { Logo2Icon } from '../../style/svg';

const Styles = styled.div`
    
`;

const Logo = ({ width, height }) => {
    return (
        <Styles>
            {/*<Logo2Icon/>*/}
        </Styles>
    );
};

export default Logo;