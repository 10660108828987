import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import serverStore from '../../../../stores/server.store';
import styled from 'styled-components';
import {
    BoxedResponsiveStyled
} from '../../../../style/components/common.styled';
import ServerStatus from '../../../components/metrics/ServerStatus';
import ServerInfoWidget from './serverWidgets/ServerInfoWidget';
import ServerMemoryWidget from './serverWidgets/ServerMemoryWidget';
import ServerCpuWidget from './serverWidgets/ServerCpuWidget';
import ServerNetworkWidget from './serverWidgets/ServerNetworkWidget';
import ServerProcessesListWidget
    from './serverWidgets/ServerProcessesListWidget';
import ServerProcessesWidget from './serverWidgets/ServerProcessesWidget';
import ServerDisksWidget from './serverWidgets/ServerDisksWidget';
import ServerNoDataRecieved from './ServerNoDataRecieved';
import alarmsStore from '../../../../stores/alarms.store';
import AlarmsWidget from './serverWidgets/AlarmsWidget';
import ServerItemChart from './ServerItemChart';
import metricsStore from '../../../../stores/metrics.store';
import ServerLocationWidget from './serverWidgets/ServerLocationWidget';
import ShowSpike from './spikes/ShowSpike';
import { spikesTypes } from '../../../../constants/data.types';
import { EuiIcon } from '@elastic/eui';

const ServerItemWrapper = props => {
    return useObserver(() => (
        <>
            <ServerStatus
                server={ serverStore.serversListObj[props.serverId] }
            />
            <ServerItem
                processes={ serverStore.serverProcesses }
                server={ serverStore.serversListObj[props.serverId] }
                alarms={ alarmsStore.alarmsByServerObj[props.serverId] }
            />
            <ServerItemChart
                server={ serverStore.serversListObj[props.serverId] }
                cpu={ metricsStore.cpu }
                ram={ metricsStore.ram }
                net={ metricsStore.net }
                disk={ metricsStore.disk }
                connections={ metricsStore.connections }
                processes={ metricsStore.processes }
                sessions={ metricsStore.sessions }
                file_handles={ metricsStore.file_handles }
                sinceSelected={ metricsStore.since }
                isLoading={ metricsStore.selectorLoading }
            />
        </>
    ));
};

export default ServerItemWrapper;

export const BoxedResponsiveDashboardStyled = styled(BoxedResponsiveStyled)`
  margin: 0 auto 10px auto;

  &.network {
    //height: 662px;
  }

  h3 {
    position: relative;
    display: grid;
    align-items: flex-start;
    grid-template-columns: auto 1fr;
    grid-gap: 5px;
  }
`;

export const ServerNameStyled = styled.div`
  font-size: 20px;
  margin: 10px 20px;
`;

const ServerDashboardStyled = styled.section`
  display: grid;
  grid-template-columns: 4fr 2fr;
  padding: 1px 10px;
  margin-top: 8px;
  grid-gap: 8px;

  @media (max-width: 1025px) {
    grid-template-columns: 1fr;
  }

  .left {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

     @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  .right {
    display: grid;
    grid-template-rows: 1fr 1fr;

     @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .column-body {
      //height: 326px;
    }
  }

  .column-body {
    padding: 10px;
  }

`;

export const ColumnStyled = styled.div`
  height: 685px;
  display: grid;

  &.petra {
    grid-template-rows: 2fr 1fr;

    .column-body {
      //margin: 10px auto;
    }
  }

  &.column {

  }

  &.full {

  }
`;

const ServerItem = props => {
    const history = useHistory();

    const { server, processes, alarms } = props;

    if (server) {
        document.title = `${ server.name } - SyAgent`;
    } else {
        history.push('/404');
    }

    return (
        <>
            { server && (
                <ServerNameStyled>{ server.name }</ServerNameStyled>
            ) }
            <ServerDashboardStyled>
                { server && server.serverData.agentPushedTheData && (
                    <>
                        <div className="left">
                            <ColumnStyled className="column petra">
                                <BoxedResponsiveDashboardStyled
                                    className="column-body">
                                    <h3><EuiIcon type="securitySignal" size="m" /> Server</h3>
                                    <ServerInfoWidget
                                        server={ server }
                                    />
                                </BoxedResponsiveDashboardStyled>
                                <BoxedResponsiveDashboardStyled
                                    className="column-body">
                                    <h3><EuiIcon type="memory" size="m" /> Memory
                                        <ShowSpike
                                            type={ spikesTypes.RAM }
                                            serverId={ server._id }
                                        />
                                    </h3>
                                    <ServerMemoryWidget
                                        server={ server }
                                    />
                                </BoxedResponsiveDashboardStyled>
                            </ColumnStyled>
                            <ColumnStyled className="column petra">
                                <BoxedResponsiveDashboardStyled
                                    className="column-body">
                                    <h3><EuiIcon type="compute" size="m" /> Cpu
                                        <ShowSpike
                                            type={ spikesTypes.CPU }
                                            serverId={ server._id }
                                        />
                                    </h3>
                                    <ServerCpuWidget
                                        server={ server }
                                    />
                                </BoxedResponsiveDashboardStyled>
                                <BoxedResponsiveDashboardStyled
                                    className="column-body">
                                    <h3><EuiIcon type="createPopulationJob" size="m"  color="text"/> Processes</h3>
                                    <ServerProcessesWidget
                                        server={ server }
                                    />
                                </BoxedResponsiveDashboardStyled>
                            </ColumnStyled>
                            <ColumnStyled className="column petra">
                                <BoxedResponsiveDashboardStyled
                                    className="column-body network">
                                    <h3><EuiIcon type="globe" size="m" /> Network</h3>
                                    <ServerNetworkWidget
                                        server={ server }
                                    />
                                </BoxedResponsiveDashboardStyled>
                                <BoxedResponsiveDashboardStyled
                                    className="column-body">
                                    <h3><EuiIcon type="mapMarker" size="m" /> Server Location</h3>
                                    <ServerLocationWidget
                                        server={ server }
                                    />
                                </BoxedResponsiveDashboardStyled>
                            </ColumnStyled>
                            <ColumnStyled className="column full">
                                <BoxedResponsiveDashboardStyled
                                    className="column-body">
                                    <h3>
                                        <EuiIcon type="storage" size="m" /> Disks
                                        <ShowSpike
                                            type={ spikesTypes.DISK }
                                            serverId={ server._id }
                                        />
                                    </h3>
                                    <ServerDisksWidget
                                        server={ server }
                                    />
                                </BoxedResponsiveDashboardStyled>
                            </ColumnStyled>
                        </div>
                        <div className="right">
                            <BoxedResponsiveDashboardStyled
                                className="column-body">
                                <AlarmsWidget
                                    alarms={ alarms }
                                    server={ server }
                                />
                            </BoxedResponsiveDashboardStyled>
                            <BoxedResponsiveDashboardStyled
                                className="column-body">
                                <h3><EuiIcon type="createPopulationJob" size="m"  color="text"/> Processes</h3>
                                <ServerProcessesListWidget
                                    server={ server }
                                    processes={ processes }
                                />
                            </BoxedResponsiveDashboardStyled>
                        </div>
                    </>
                ) }
            </ServerDashboardStyled>
            { server && !server.serverData.agentPushedTheData && (
                <ServerNoDataRecieved
                    server={ server }
                />
            ) }
        </>
    );
};