import fetchService from '../servises/fetch.service';
import { makeAutoObservable } from 'mobx';
import utilsService from '../servises/utils.service';
import userStore from './user.store';
import alarmsStore from './alarms.store';

class MongoDbStore {
    constructor() {
        makeAutoObservable(this);
    }

    mongoDbListObj = {};

    canAddNewMongoDb = false;

    async create(payload) {
        await fetchService.authenticatedPost(`/mongo`, payload);
    }

    async getMongoDb(isForce) {
        if (!isForce && Object.keys(this.mongoDbListObj).length > 0) {
            return;
        }
        const response = await fetchService.authenticatedGet(`/mongo`);
        const data = response.data.data_o;
        if (data) {
            data.forEach(item => {
                this.normalize(item);
            });
            this.mongoDbListObj = utilsService.normalizeApiData(data);
            this.mongoDbListObj = { ...this.mongoDbListObj };
        }
        this.detectIfCanAddNewServers();
    }

    async updateMongoTags(id, tagId, isAdd, isServerCreated) {
        const response = await fetchService.authenticatedPut(`/mongo/${ id }`,
            { tagId: tagId, isAdd }
        );

        const data = response.data.data_o;
        this.mongoDbListObj[data._id].tags = data.tags;
        this.mongoDbListObj = { ...this.mongoDbListObj };
    }

    async deleteServer(serverId) {
        await fetchService.authenticatedDelete(
            `/mongo/${ serverId }`);
        await this.getMongoDb(true);
    }

    detectIfCanAddNewServers() {
        const serversCount = Object.keys(this.mongoDbListObj).length;
        const subscriptionServers = userStore.user.subscription.mongo || 5;

        if (serversCount < subscriptionServers) {
            this.canAddNewMongoDb = true;
        } else {
            this.canAddNewMongoDb = false;
        }
    }

    normalize(server) {
        server.isMongo = true;
        server.createdAtToDisplay = utilsService.dateMe(server.createdAt);
        const metric = typeof server.mongometrics === 'object' ? server.mongometrics : null;
        server.agentPushedTheData = !!metric;

        server.metric = metric;

        server.oldDataFetched = false;

        if (metric && metric.createdAt) {
            if (metric.createdAt + 2500000 < Date.now()) {
                server.oldDataFetched = true;
            }
        }

        server.totalDbUsage = 0;
        if (metric && metric.dbsStats) {
            const keys = Object.keys(metric.dbsStats);
            keys.forEach(key => {
                const db = metric.dbsStats[key];
                server.totalDbUsage += db.dbStats.dataSize;
            });
        }

        server.totalDbUsageFormated = utilsService.formatBytes(server.totalDbUsage);

        server.classes = '';
        if (server.agentPushedTheData) {
            server.classes = 'normal';
        } else {
            server.classes = 'no-data';
        }
        if (server.oldDataFetched) {
            server.classes = 'old-data';
        }

        if (server.agentPushedTheData) {
            server.timeAgo = utilsService.timeAgo(metric.createdAt);
            server.totalSizeFormated = utilsService.formatBytes(metric.totalSize);
            server.totalSizeMb = metric.totalSizeMb;
            if (metric.serverStatus && metric.serverStatus.connections) {
                server.connections = metric.serverStatus.connections.current;
                server.availableConnections = metric.serverStatus.connections.available;
            }
            if (metric.serverStatus && metric.serverStatus.mem) {
                server.memResident = metric.serverStatus.mem.resident;
                server.memVirtual = metric.serverStatus.mem.virtual;
            }
            if (metric.serverStatus && metric.serverStatus.network) {
                server.networkBytesIn = utilsService.formatBytes(metric.serverStatus.network.bytesIn);
                server.networkBytesOut = utilsService.formatBytes(metric.serverStatus.network.bytesOut);
                server.networkNumRequests = metric.serverStatus.network.numRequests;
            }
            if (metric.serverStatus && metric.serverStatus.repl) {
                server.numberOfHosts = metric.serverStatus.repl.hosts.length;
            }
        } else {
            server.timeAgo = 'N/A';
        }

        alarmsStore.setAlarms(server);
    }
}

const mongoDbStore = new MongoDbStore();

export default mongoDbStore;
