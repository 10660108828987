import React from 'react';
import styled from 'styled-components';
import metricsStore from '../../../../stores/metrics.store';
import BaseChart from './chart/BaseChart';

const ServerDashboardChartsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1px 10px;
  margin-top: 0;
  grid-gap: 11px;

  @media (max-width: 1025px) {
    grid-template-columns: 1fr;
  }
`;

const ServerItemChart = props => {
    const { server, cpu, disk, net, ram, connections, processes, file_handles, sessions, sinceSelected, isLoading } = props;

    const handleSelectChange = e => {
        metricsStore.setSince(e.target.value, server._id);
    };

    return (
        <ServerDashboardChartsStyled>
            { server && server.serverData.agentPushedTheData && (
                <>
                    <BaseChart
                        title="CPU"
                        icon="compute"
                        isLoading={ isLoading }
                        selected={ sinceSelected }
                        handleSelectChange={ handleSelectChange }
                        data={ cpu }
                    />

                    <BaseChart
                        title="Memory"
                        icon="memory"
                        isLoading={ isLoading }
                        selected={ sinceSelected }
                        handleSelectChange={ handleSelectChange }
                        data={ ram }
                    />

                    <BaseChart
                        title="Network"
                        icon="globe"
                        isLoading={ isLoading }
                        selected={ sinceSelected }
                        handleSelectChange={ handleSelectChange }
                        data={ net }
                    />

                    <BaseChart
                        title="Disk"
                        isLoading={ isLoading }
                        icon="storage"
                        selected={ sinceSelected }
                        handleSelectChange={ handleSelectChange }
                        data={ disk }
                    />

                    <BaseChart
                        title="Connections"
                        isLoading={ isLoading }
                        icon="globe"
                        selected={ sinceSelected }
                        handleSelectChange={ handleSelectChange }
                        data={ connections }
                    />

                    <BaseChart
                        title="Processes"
                        isLoading={ isLoading }
                        icon="createPopulationJob"
                        selected={ sinceSelected }
                        handleSelectChange={ handleSelectChange }
                        data={ processes }
                    />

                    <BaseChart
                        title="File Handles"
                        isLoading={ isLoading }
                        icon="filebeatApp"
                        selected={ sinceSelected }
                        handleSelectChange={ handleSelectChange }
                        data={ file_handles }
                    />

                    <BaseChart
                        title="Sessions"
                        isLoading={ isLoading }
                        icon="sessionViewer"
                        selected={ sinceSelected }
                        handleSelectChange={ handleSelectChange }
                        data={ sessions }
                    />
                </>
            ) }
        </ServerDashboardChartsStyled>
    );
};

export default ServerItemChart;