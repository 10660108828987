import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

const WrapperStyled = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${ props => props.isBig
          ? 'repeat(auto-fit, minmax(50px, 1fr))'
          : 'repeat(auto-fit, minmax(5px, 1fr))' };
  z-index: 10;
  position: relative;
  grid-gap: 5px;
`;

const ItemStyled = styled.div`
  background: rgb(0, 128, 213);
  //height: 20px;
  height: ${ props => props.isBig ? '50px' : '20px' };
  border-radius: 2px;

  display: grid;
  align-content: center;
  text-align: center;

  &.pending {
    background: #848484;
  }

  &.ok {

  }

  &.fail {
    background: #F86B63;
  }
`;

const MiniPinyWidget = ({ uptime, uptimeChecks, isBig }) => {
    const checks = uptimeChecks ? uptimeChecks : uptime.uptimechecks;
    let firstCheck = null;
    if (checks && checks.length > 0) {
        firstCheck = checks[0];
    }

    const generatedChecks = useMemo(() => {
        return generateChecks(checks, uptime);
    }, [uptime, checks, firstCheck]);

    return (
        <WrapperStyled
            isBig={ isBig }
        >
            { generatedChecks.map((item, idx) => {
                return (
                    <Item
                        key={ item.date + idx }
                        item={ item }
                        isBig={ isBig }
                    />);
            }) }
        </WrapperStyled>
    );
};

export default MiniPinyWidget;

export const generateChecks = (checks, uptime, count = 15) => {
    const results = [];
    let interval = uptime.interval;
    if (uptime.isCustomInterval) {
        interval = uptime.customInterval;
    }

    const nextRun = uptime.scheduleTo;

    if (Array.isArray(checks)) {
        checks.forEach(check => {
            results.push({
                status: check.isValid ? 'ok' : 'fail',
                date: dayjs(check.createdAt).format('HH:mm DD MMM')
            });
        });

        const checksLength = checks.length;
        if (checksLength < count) {
            if(uptime.isEnabled){
                const counterToAddMoreChecks = count - checksLength;
                for (let j = 0; j < counterToAddMoreChecks; j++) {
                    let dateMS = j === 0 ? nextRun : nextRun + (interval * 1000 * j);
                    results.push({
                        status: 'pending',
                        date: dayjs(dateMS).format('HH:mm DD MMM')
                    });
                }
            }
        } else {
            results.push({
                status: 'pending',
                date: dayjs(nextRun).format('HH:mm DD MMM')
            });
        }
    } else {
        for (let i = 0; i < count; i++) {
            let dateMS = i === 0 ? nextRun : nextRun + (interval * 1000 * i);
            results.push({
                status: 'pending',
                date: dayjs(dateMS).format('HH:mm DD MMM')
            });
        }
    }

    return results;
};

const Item = ({ item, isBig }) => {
    return (
        <ItemStyled
            isBig={ isBig }
            title={ item.date }
            className={ item.status }
        >
            { isBig ? item.date : '' }
        </ItemStyled>
    );
};
