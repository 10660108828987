import React from 'react';
import { BoxedResponsiveDashboardStyled } from '../ServerItem2';
import ServerComInfo from '../ServerComInfo';
import utilsService from '../../../../../servises/utils.service';

const DbItemComponent = (props) => {
    const { dbInfo, db } = props;

    // dont forget size on disk
    const name = db.name;
    const sizeOnDisk = db.sizeOnDisk;
    const sizeOnDiskFormatted = utilsService.formatBytes(sizeOnDisk);

    return (
        <BoxedResponsiveDashboardStyled>
            { name }

            <ServerComInfo
                name={ 'Size on Disk' }
                data={ `${ sizeOnDiskFormatted.value } ${ sizeOnDiskFormatted.unit }` }
            />
            { dbInfo && (
                <DbComponentContent
                    data={ dbInfo }
                />
            ) }
        </BoxedResponsiveDashboardStyled>
    );
};

export default DbItemComponent;

const DbComponentContent = ({ data }) => {
    const storageSize = utilsService.formatBytes(data.dbStats.storageSize);
    const dataSize = utilsService.formatBytes(data.dbStats.dataSize);
    const avgObjSize = utilsService.formatBytes(data.dbStats.avgObjSize);

    return (
        <>
            <ServerComInfo
                name={ 'Storage size' }
                data={ `${ storageSize.value } ${ storageSize.unit }` }
            />
            <ServerComInfo
                name={ 'Data size' }
                data={ `${ dataSize.value } ${ dataSize.unit }` }
            />
            <ServerComInfo
                name={ 'Average Object size' }
                data={ `${ avgObjSize.value } ${ avgObjSize.unit }` }
            />
            <ServerComInfo
                name={ 'Number of collections' }
                data={ data.dbStats.collections }
            />
            <ServerComInfo
                name={ 'Number of Indexes' }
                data={ data.dbStats.indexes }
            />
            <ServerComInfo
                name={ 'Number of Objects' }
                data={ data.dbStats.objects }
            />
        </>
    );
};

// {
//     "db": "main",
//     "collections": 19,
//     "objects": 741764,
//     "avgObjSize": 904.0529467593466,
//     "dataSize": 670593930,
//     "storageSize": 452423680,
//     "indexes": 44
// }