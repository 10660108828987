import React, { useState } from "react";
import styled from "styled-components";
import {
    AscDescFilter, FilterGroupStyled,
    FilterItemDisplay,
    NewServerItem,
    ServersItemStyled,
    ServersListStyled,
    TagsListWrapperStyled
} from "./ServersList";
import { EuiIcon, EuiSuperSelect } from "@elastic/eui";
import { Link } from "react-router-dom";
import TagsMIniColors from "../../../components/tags/TagsMIniColors";
import utilsService from "../../../../servises/utils.service";
import dayjs from "dayjs";
import MiniPinyWidget from "./serverWidgets/MiniPinyWidget";
import { uptimeFilterTypes } from "../../../../constants/data.types";

const WrapperStyled = styled.div`
  margin-bottom: 20px;
`;

const ServerUptimeListWithChildren = ({ changeOrder, isTableView, canAddNewServers, servers, selectedTagId, position, totalPosition }) => {
    const monitorFilter = localStorage.getItem("monitorFilterUptime") || uptimeFilterTypes.createdAt.val;
    const monitorFilterAsc = localStorage.getItem("monitorFilterUptimeAsc");
    const [selectedFilter, setSelectedFilter] = useState(monitorFilter);
    const [isAscFilter, setIsAscFilter] = useState(monitorFilterAsc === "true");

    const filterTypes = uptimeFilterTypes;
    const filters = Object.values(uptimeFilterTypes);

    const onFilterChange = (value) => {
        setSelectedFilter(value);
        localStorage.setItem("monitorFilterUptime", value);
    };
    const onAscChange = () => {
        setIsAscFilter(!isAscFilter);
        localStorage.setItem("monitorFilterUptimeAsc", (!isAscFilter).toString());
    };
    const getServersList = () => {
        let temp = JSON.parse(JSON.stringify(servers));
        if (selectedTagId) {
            temp = servers.filter(ser => {
                if (ser.tags && ser.tags.length) {
                    const tagIndex = ser.tags.indexOf(selectedTagId);
                    if (tagIndex > -1) {
                        return true;
                    }
                }
                return false;
            });

        }

        const defaultString = isAscFilter ? "a" : "z";
        const defaultNumber = !isAscFilter ? 0 : 99999999999999999;
        temp.sort((a, b) => {
            let valueA, valueB;
            let type = "number";

            switch (selectedFilter) {
                case filterTypes.createdAt.val:
                    valueA = a[selectedFilter] || defaultNumber;
                    valueB = b[selectedFilter] || defaultNumber;
                    break;
                case filterTypes.scheduleTo.val:
                    valueA = a[selectedFilter] || defaultNumber;
                    valueB = b[selectedFilter] || defaultNumber;
                    break;
                case filterTypes.name.val:
                    valueA = a[selectedFilter] || defaultString;
                    valueB = b[selectedFilter] || defaultString;
                    type = "string";
                    break;
                case filterTypes.interval.val:
                    const intervalA = a.isCustomInterval ? a.customInterval : a.interval;
                    const intervalB = b.isCustomInterval ? b.customInterval : b.interval;
                    valueA = intervalA || defaultNumber;
                    valueB = intervalB || defaultNumber;
                    break;
                case filterTypes.alarms.val:
                    valueA = 0;
                    valueB = 0;
                    if (a.alarms && a.alarms.length) {
                        valueA = a.alarms.length || defaultNumber;
                    }
                    if (b.alarms && b.alarms.length) {
                        valueB = b.alarms.length || defaultNumber;
                    }
                    break;
                case filterTypes.tags.val:
                    valueA = "";
                    valueB = "";
                    type = "string";
                    if (a.tags && a.tags.length) {
                        valueA = a.tags[0] || defaultString;
                    }
                    if (b.tags && b.tags.length) {
                        valueB = b.tags[0] || defaultString;
                    }
                    break;
            }

            if (type === "number") {
                if (isAscFilter) {
                    if (valueA < valueB) {
                        return -1;
                    }
                    if (valueA > valueB) {
                        return 1;
                    }
                } else {
                    if (valueA > valueB) {
                        return -1;
                    }
                    if (valueA < valueB) {
                        return 1;
                    }
                }
                return 0;
            }

            if (type === "string" && valueA && valueB) {
                if (isAscFilter) {
                    return valueA.localeCompare(valueB);
                } else {
                    return valueB.localeCompare(valueA);
                }
            }

            if (type === "bool") {
                if (isAscFilter) {
                    return (valueA === valueB) ? 0 : valueA ? -1 : 1;
                } else {
                    return (valueB === valueA) ? 0 : valueB ? -1 : 1;
                }
            }
            return 0;
        });
        return temp;
    };

    return (
        <WrapperStyled>
            <TagsListWrapperStyled>
                <h3><EuiIcon type="clock" size="m" color="text"/>Uptime</h3>
                <div className="position-kdz">
                    {position === 0 && (
                        <EuiIcon
                            onClick={()=>changeOrder(position, 'down')}
                            type="arrowDown" size="m" color="text"/>
                    )}
                    {position === totalPosition - 1 && (
                        <EuiIcon
                            onClick={()=>changeOrder(position, 'up')}
                            type="arrowUp" size="m" color="text"/>
                    )}
                    {position > 0 && position < totalPosition - 1 && (
                        <>
                            <EuiIcon
                                onClick={()=>changeOrder(position, 'down')}
                                type="arrowDown" size="m" color="text"/>
                            <EuiIcon
                                onClick={()=>changeOrder(position, 'up')}
                                type="arrowUp" size="m" color="text"/>
                        </>
                    )}
                </div>
                <FilterGroupStyled>
                    <div className="pllasdaw">
                        <EuiSuperSelect
                            compressed={ true }
                            valueOfSelected={ selectedFilter }
                            onChange={ onFilterChange }
                            options={ filters.map(fil => {
                                return {
                                    value: fil.val,
                                    inputDisplay: (<FilterItemDisplay item={ fil }/>)
                                    // dropdownDisplay: dropdownDisplay,
                                };
                            }) }
                        />
                        <AscDescFilter isAck={ isAscFilter } onClick={ onAscChange }/>
                    </div>
                </FilterGroupStyled>
            </TagsListWrapperStyled>
            <ServersListStyled className={ isTableView ? "table" : "" }>
                { (getServersList()).map(item => {
                    if (isTableView) {
                        return <UptimeItemTable
                            key={ item._id }
                            item={ item }
                        />;
                    } else {
                        return <UptimeItem
                            key={ item._id }
                            item={ item }
                        />;
                    }
                }) }
                { canAddNewServers && (
                    <NewServerItem/>
                ) }
            </ServersListStyled>
        </WrapperStyled>
    );
};

export default ServerUptimeListWithChildren;
const UptimeItem = ({ item }) => {
    let alarmsCount = null;
    if (item && item.alarms && item.alarms.length) {
        alarmsCount = item.alarms.filter(alarm => alarm.status === 1).length;
    }

    const isThereAlarms = alarmsCount > 0;

    return (
        <ServersItemStyled className={ `${ item.classes } uptime-d` }>
            <Link to={ `/uptime/${ item._id }` } className={ "server-link" }/>
            <div className="status color-1">
                <TagsMIniColors style={ { position: "absolute" } }
                                serverTags={ item.tags }/>
                { isThereAlarms && (
                    <div className="alarms-oodz4">
                        <span className="bibl">Alarms:</span> { alarmsCount }
                    </div>
                ) }
                <div className="server-status"/>
                <div className="last-metric">
                    { item.timeAgo }
                </div>
            </div>
            <h4><span className="label">Name:</span>{ item.name }</h4>
            <div className="uptime-data">
                <span className="label">
                    Type:
                </span>
                { item.type }
            </div>
            <div className="uptime-data">
                <span className="label">
                    Status:
                </span>
                { item.isEnabled ? "Enabled" : "Paused" }
            </div>
            <div className="uptime-data">
                <span className="label">
                    Interval:
                </span>
                { utilsService.calculateEveryTime(item) }
            </div>
            { item.isEnabled && (
                <div className="uptime-data">
                <span className="label">
                    Next check at:
                </span>
                    { dayjs(item.scheduleTo).format("HH:mm DD MMM") }
                </div>
            ) }

            { item.isEnabled && (
                <div className="mini-pini">
                    <MiniPinyWidget uptime={ item }/>
                </div>
            ) }
        </ServersItemStyled>
    );
};

const UptimeItemTable = ({ item }) => {
    let alarmsCount = null;
    if (item && item.alarms && item.alarms.length) {
        alarmsCount = item.alarms.filter(alarm => alarm.status === 1).length;
    }

    const isThereAlarms = alarmsCount > 0;

    // status, alarms, name, type, interval, next check, tags
    return (
        <ServersItemStyled className={ item.classes + " table-dz777" }>
            <Link to={ `/uptime/${ item._id }` } className={ "server-link table-view-pox" }/>
            <div className="status color-1 table-view-pox-iuzsd">
                <div className="pepew">
                    <label>Alarms</label>
                    <span className={ `val ${ isThereAlarms ? "alarmed" : "" }` }>
                         { alarmsCount }
                    </span>
                </div>
                <div className="pepew">
                    <label>Name</label>
                    <span className="val">
                         { item.name }
                    </span>
                </div>

                <div className="pepew">
                    <label>Status</label>
                    <span className="val">
                        { item.isEnabled ? "Enabled" : "Paused" }
                    </span>
                </div>

                <div className="pepew mobile12">
                    <label>Type</label>
                    <span className="val">
                        { item.type }
                    </span>
                </div>

                <div className="pepew mobile12">
                    <label>Interval</label>
                    <span className="val">
                         { utilsService.calculateEveryTime(item) }
                    </span>
                </div>
                <div className="pepew mobile12">
                    <label>Interval</label>
                    <span className="val">
                         { utilsService.calculateEveryTime(item) }
                    </span>
                </div>
                <div className="pepew mobile12">
                    <label>Tags</label>
                    <span className="val">
                          <TagsMIniColors serverTags={ item.tags }/>
                    </span>
                </div>

            </div>

        </ServersItemStyled>
    );
};
