import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import userStore from '../../../stores/user.store';
import settingsStore from '../../../stores/settings.store';
import {
    doCreateUserWithEmailAndPassword, signInWithGoogle
} from '../../../servises/auth.service';
import { AuthStyled, FormItem } from './Auth.styled';
import { Link } from 'react-router-dom';
import idleCheckRefreshComponent from '../../../servises/idleRefreshToken';
import Logo from '../../components/Logo';
import BecautifulBackgroud from '../../components/BecautifulBackgroud';
import CountrySelect from '../../components/CountrySelect';
import {
    EuiButton,
    EuiFieldPassword,
    EuiFieldText,
    EuiForm,
    EuiFormRow, EuiHorizontalRule
} from '@elastic/eui';
import { addToast } from '../../components/toasts/Toast';
import { GoogleIcon } from '../../../style/svg';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            country: 'N/A',
            user: true,
            message: 'Logging',
            resetPassword: false,
            errorMessage: '',
            submitLoader: false
        };
    }

    async componentDidMount() {
        document.title = 'Sign up - SyAgent';

        this.unregisterAuthObserver = firebase.auth()
            .onAuthStateChanged(user => {
                this.setState({ user: user });

                if (!user) return;

                user.getIdToken().then(async token => {
                    const currentUser = await userStore.getCurrentUser(token);
                    if (currentUser) {
                        idleCheckRefreshComponent.registerRefreshToken();
                        this.proceedToUrl();
                        return;
                    }
                    userStore.createUser(token,
                        { email: this.state.email, country: this.state.country }
                    ).then(async data => {
                        if (data && !data.verified) {
                            user.sendEmailVerification();
                            localStorage.setItem('emailSentIn',
                                Date.now().toString()
                            );
                        }
                        if (!data) {
                            this.setState({ user: false });
                            return;
                        }
                        idleCheckRefreshComponent.registerRefreshToken();
                        this.proceedToUrl();
                    });
                });
            });
    }

    proceedToUrl = () => {
        this.props.history.push(
            settingsStore.redirectRoute
                ? settingsStore.redirectRoute
                : '/servers'
        );
    };

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    inputValueChangeHandle(e, type) {
        this.setState({
            [type]: e.target.value
        });
    }

    onSelect = (e) => {
        this.setState({
            country: e.target.value
        });
    };

    handleSubmit = e => {
        this.setState({ submitLoader: true });
        const { email, password } = this.state;
        this.signIn(email, password);
        e.preventDefault();
    };

    signIn(email, password) {
        doCreateUserWithEmailAndPassword(email, password).then(resp => {
            console.log(resp);
        }).catch(() => {
            this.setState({ errorMessage: 'Email or password are not valid', submitLoader: false });
            addToast(
                'Authentication failed, email or password are not valid',
                'error'
            );
        });

    }

    render() {
        const { email, password, country } = this.state;

        return (
            <>
                <Logo/>

                <AuthStyled className="login">
                    <div className="forms-sz">
                        <h1>Sign Up</h1>
                        <EuiForm
                            component="form" className="form">
                            <EuiFormRow label="Email">
                                <EuiFieldText
                                    value={ email }
                                    isInvalid={ false }
                                    onChange={ e => this.inputValueChangeHandle(
                                        e,
                                        'email'
                                    ) }
                                />
                            </EuiFormRow>
                            <EuiFormRow label="Password">
                                <EuiFieldPassword
                                    type="dual"
                                    value={ password }
                                    onChange={ e => this.inputValueChangeHandle(
                                        e,
                                        'password'
                                    ) }
                                />
                            </EuiFormRow>

                            <FormItem>
                                <CountrySelect
                                    onSelect={ this.onSelect }
                                    value={ country }
                                />
                            </FormItem>

                            <FormItem>
                                <EuiButton
                                    isDisabled={ !email || !password }
                                    size="s"
                                    onClick={ this.handleSubmit }
                                    fill
                                    isLoading={ this.state.submitLoader }
                                >Join</EuiButton>

                                <EuiHorizontalRule />
                                <span className="ppdaw2">or</span>

                                <EuiButton
                                    className="add-auth"
                                    size="s"
                                    style={{"backgroundColor": "#ffffff", color: "#757575"}}
                                    onClick={signInWithGoogle}
                                    fill
                                ><GoogleIcon/> Sign up with Google</EuiButton>
                            </FormItem>

                            <FormItem>
                                <p className="pooolxz">
                                    Already registered?
                                </p>
                                <Link to={ '/login' }>
                                    Sign in
                                </Link>
                            </FormItem>
                        </EuiForm>
                    </div>
                    <BecautifulBackgroud/>
                </AuthStyled>
            </>
        );
    }
}

export default RegisterPage;
