import React from "react";
import utilsService from "../../../servises/utils.service";
import styled from "styled-components";

const ServerDashboardStyled = styled.section`
  padding: 9px 0;
  margin: 5px 0;

  @media (max-width: 1040px) {
    margin: 54px 0;
  }

  h5 {
    font-size: 11px;
  }

  .full {
    width: 70px;
    background: #2A3F54;
    position: relative;
    height: 100px;

    .part {
      position: absolute;
      background: rgb(0, 128, 213);
      transition: height ease-in-out 2s;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .measures {
    display: grid;
    grid-template-columns: 1fr;
    font-size: 11px;

    div {
      text-align: left;
    }

    .middle {
      align-self: center;
    }

    .start {
      align-self: unset;
    }

    .end {
      align-self: end;
    }
  }

  .server-total-info-vertical-body {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-items: start;
    grid-gap: 9px;
  }
`;

const TotalVerticalInfo = props => {
    const percent = utilsService.getPercent(props.currentOriginal, props.totalOriginal);

    return (
        <ServerDashboardStyled className='server-total-info-vertical'>
            <h5>{ props.title } ({ percent }%)</h5>
            <div className="server-total-info-vertical-body">
                <div className="full">
                    <div className="part" style={ {
                        height: percent + "%"
                    } }/>
                </div>
                <div className="measures">
                    <div className="start">{ Math.round(props.total * 100) /
                    100 }{ props.totaExt }</div>
                    <div className="middle">{ Math.round(props.current * 100) /
                    100 }{ props.currentExt }</div>
                    <div className="end">{ props.start }</div>
                </div>
            </div>
        </ServerDashboardStyled>
    );
};

export default TotalVerticalInfo;
