import { makeAutoObservable } from 'mobx';
import fetchService from '../servises/fetch.service';

class MetricsStore {
    constructor() {
        makeAutoObservable(this);
    }

    spikesByServerId = {};
    since = '10min'; // 5min, 30min, 1hour, 2hours, 6hours, 12hours, 24hours
    selectorLoading = false;

    async getSpikes(serverId, type) {
        this.selectorLoading = true;
        if (!this.spikesByServerId[serverId + type]) {
            const response = await fetchService.authenticatedGet(
                `/spikes/${ serverId }?type=${ type }`);
            const data = response.data.data_o;
            this.spikesByServerId = {
                ...this.spikesByServerId, ...{
                    [serverId + type]: data
                }
            };
        }
        this.selectorLoading = false;
    }
}

const metricsStore = new MetricsStore();

export default metricsStore;