import React from 'react';
import { useObserver } from 'mobx-react-lite';
import MiniPinyWidget from './serverWidgets/MiniPinyWidget';
import uptimeStore from '../../../../stores/uptime.store';
import UptimeStatus from '../../../components/metrics/UptimeStatus';
import { BoxedResponsiveDashboardStyled, ServerNameStyled } from './ServerItem2';
import styled from 'styled-components';
import uptimeChecksStore from '../../../../stores/uptimeChecks.store';
import dayjs from 'dayjs';
import ServerComInfo from './ServerComInfo';
import AlarmsWidget from './serverWidgets/AlarmsWidget';
import BaseChart from './chart/BaseChart';
import utilsService from '../../../../servises/utils.service';
import { useHistory } from 'react-router-dom';
import { EuiIcon } from '@elastic/eui';
import alarmsStore from '../../../../stores/alarms.store';

const MainPageGridStyled = styled.section`
  display: grid;
  padding: 1px 10px;
  margin-top: 8px;
  grid-gap: 8px;

  .pewka-dzkkdkd {
    display: grid;
    grid-gap: 8px;

    @media (max-width: 600px) {
      display: block;
    }
  }
`;

const UptimeItemWrapper = props => {
    const { uptimeId } = props;

    return useObserver(() => (
        <>
            <UptimeStatus
                item={ uptimeStore.uptimeListObj[uptimeId] }
            />
            <UptimeItem
                uptime={ uptimeStore.uptimeListObj[uptimeId] }
                alarms={ alarmsStore.alarmsByServerObj[uptimeId] }
                checks={ uptimeChecksStore.uptimeChecksList }
            />
        </>
    ));
};

export default UptimeItemWrapper;

const UptimeItem = props => {
    const history = useHistory();

    const { uptime, checks, alarms } = props;

    if (uptime) {
        document.title = `${ uptime.name } - SyAgent`;
    } else {
        history.push('/404');
    }

    return (
        <div className="mini-pini">
            <ServerNameStyled>{ uptime.name }</ServerNameStyled>
            <MainPageGridStyled>
                <div className="pewka-dzkkdkd" style={ { gridTemplateColumns: '1fr 4fr' } }>
                    <BoxedResponsiveDashboardStyled>
                        <h3><EuiIcon type="securitySignal" size="m" /> Info</h3>
                        { generateData(uptime).map((imte, idx) => {
                            return (<ServerComInfo
                                    key={ 'daw444lldadawd' + idx }
                                    name={ imte.title }
                                    data={ imte.data }
                                />
                            );
                        }) }
                    </BoxedResponsiveDashboardStyled>
                    <BoxedResponsiveDashboardStyled>
                        <h3><EuiIcon type="uptimeApp" size="m" color="text" /> Uptime history</h3>
                        <MiniPinyWidget
                            uptime={ uptime }
                            uptimeChecks={ checks }
                            isBig={ true }
                        />
                    </BoxedResponsiveDashboardStyled>
                </div>
                <div className="pewka-dzkkdkd">
                    <BaseChart
                        icon="timeslider"
                        title="Response time (last 100) Europe"
                        isLoading={ false }
                        hideTime={ true }
                        data={ generateChartData(checks) }
                    />
                </div>
                <div className="pewka-dzkkdkd">
                    <BoxedResponsiveDashboardStyled>
                        <AlarmsWidget
                            alarms={ alarms }
                            server={ uptime }
                        />
                    </BoxedResponsiveDashboardStyled>
                </div>
            </MainPageGridStyled>
        </div>
    );
};

const generateData = (uptimeData) => {
    return [
        {
            title: 'Status',
            data: uptimeData.isEnabled ? 'Enabled' : 'Paused'
        },
        {
            title: 'Type',
            data: uptimeData.type
        },
        {
            title: 'Target',
            data: uptimeData.url
        },
        {
            title: 'Interval',
            data: utilsService.calculateEveryTime(uptimeData)
        },
        {
            title: 'Next check',
            data: dayjs(uptimeData.scheduleTo).format('HH:mm DD MMM YYYY')
        },
        {
            title: 'Created',
            data: dayjs(uptimeData.createdAt).format('HH:mm DD MMM YYYY')
        }
    ];
};

const generateChartData = checks => {
    const result = {
        ext: 'ms',
        key: 'rsp',
        data: []
    };

    for (let i = 0; i < checks.length; i++) {
        const check = checks[i];
        const date = utilsService.timeMe(check.createdAt);

        result.data.push({
            name: date,
            rsp: check.responseTime
        });
    }
    return result;
};