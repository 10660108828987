import React, { useState } from 'react';
import styled from 'styled-components';
import {
    CodeAreaStyled,
    FlowStyled,
    RunCodeStyled
} from '../../../../style/components/common.styled';
import { GreenButtonStyled } from '../../../../style/components/button.styled';
import { EuiButton, EuiCodeBlock, EuiCopy, EuiSpacer } from '@elastic/eui';

const Styles = styled.div`
  text-align: center;

  h2 {
    font-size: 32px;
  }

  .install {
    div {
      font-size: 20px;
    }
  }
`;

const ServerNoDataRecieved = ({ server }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = e => {
        e.preventDefault();

        setCopied(true);
        const elem = document.createElement('textarea');
        document.body.appendChild(elem);
        elem.value = codeR.innerText;
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);
    };

    let codeR = null;

    return (
        <Styles>
            <h2>Awaiting for data</h2>

            <FlowStyled>
                <h3>How does it work?</h3>
                <ul>
                    <li>Create server name</li>
                    <li>Copy and run code below to Install <a
                        href="https://github.com/syagent/agent-2"
                        rel="noreferrer"
                        target="_blank">the Agent</a> (The code is
                        OPEN, you can check what
                        is monitored)
                    </li>
                    <li>Wait for data</li>
                    <li>Profit..</li>
                </ul>
            </FlowStyled>

            <div className="install">
                <RunCodeStyled>
                    Run following code as root in your server to install the
                    agent
                    <EuiSpacer size="l"/>
                    <EuiCodeBlock language="jsx" fontSize="m"
                                  paddingSize="m">
                        wget -N --no-check-certificate https://raw.githubusercontent.com/syagent/agent-2/main/install.sh && bash install.sh { server._id }
                    </EuiCodeBlock>
                    <EuiSpacer size="l"/>

                    <EuiCopy
                        textToCopy={ `wget -N --no-check-certificate https://raw.githubusercontent.com/syagent/agent-2/main/install.sh && bash install.sh ${ server._id }` }>
                        { copy => (
                            <EuiButton onClick={ copy }
                                       iconType={ 'copyClipboard' }
                                       color={ 'success' }>Click
                                to
                                copy</EuiButton>
                        ) }
                    </EuiCopy>

                </RunCodeStyled>
            </div>
        </Styles>
    );
};

export default ServerNoDataRecieved;