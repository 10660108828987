import styled from 'styled-components';

export const AuthStyled = styled.main`
  display: grid;
  grid-template-columns: auto 1fr;

  @media (max-width: 600px) {
    display: block;

    .background {
      display: none;
    }
  }

  .errorMessage {
    text-align: center;
    color: rgb(247, 97, 78);
  }

  h1 {
    text-align: center;
    padding: 10px 0;
    font-size: 28px;
  }

  .pooolxz {
    margin: 11px 0;
  }

  .forms-sz {
    //background-color: rgb(52, 55, 65);
    background-color: rgb(20, 21, 25);
    padding: 40px;
    border-radius: 4px;
    border: 1px solid rgba(0, 120, 212, 0.2);
    border-width: 1px;
    height: 100vh;
    //position: absolute;
    border-width: ${props => props.loader ? 0 : '1px'};

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .ppdaw2 {
    text-align: center;
    text-transform: uppercase;
  }
`;

export const FormItem = styled.div`
  display: grid;
  width: 250px;
  margin: 15px auto;

  label {
    margin-bottom: 5px;
  }

  p, a {
    text-align: center;
  }

  .add-auth {
    margin-top: 15px;

    svg {
      width: 20px;
      vertical-align: middle;
      height: auto;
    }
  }
`;