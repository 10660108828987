import React, { useState } from 'react';
import Logo from '../../components/Logo';
import { AuthStyled, FormItem } from './Auth.styled';
import { doPasswordReset } from '../../../servises/auth.service';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import BecautifulBackgroud from '../../components/BecautifulBackgroud';
import { EuiButton, EuiFieldText, EuiFormRow } from '@elastic/eui';

const Styles = styled.div`

  .message-sent {
    text-align: center;
    margin: 20px auto;
    font-size: 16px;

    p {
      margin-bottom: 20px;
    }
  }
`;

function validateInput(input) {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(input);
}

const ForgotPassword = () => {
    const [inputText, setInputText] = useState('');
    const [isError, setIsError] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleSubmit = async () => {
        const isValid = validateInput(inputText);
        if (!isValid) {
            setIsError('Email format is not valid');
            return;
        } else {
            setIsError('');
        }
        if (messageSent) {
            return;
        }

        setLoader(true);
        setMessageSent(true);
        await doPasswordReset(inputText);
    };

    return (
        <Styles>
            <Logo/>

            <AuthStyled>
                <div className="forms-sz">
                    <h1>Reset Password</h1>

                    { !messageSent && (
                        <form className="form">
                            <EuiFormRow label="Email">
                                <EuiFieldText
                                    value={ inputText }
                                    isInvalid={ false }
                                    onChange={ (e) => setInputText(
                                        e.target.value) }
                                />
                            </EuiFormRow>

                            <FormItem>
                                <EuiButton
                                    isDisabled={ !inputText }
                                    size="s"
                                    onClick={ handleSubmit }
                                    fill
                                    isLoading={ loader }
                                >Reset password</EuiButton>
                            </FormItem>
                            <FormItem>
                                <Link to={ '/login' }>
                                    Back to Login
                                </Link>
                            </FormItem>
                        </form>
                    ) }

                    { messageSent && (
                        <div className="message-sent">
                            <p>
                                Message with password reset link has been sent
                            </p>
                            <Link to={ '/login' }>To Login page</Link>
                        </div>
                    ) }
                </div>
                <BecautifulBackgroud/>
            </AuthStyled>
        </Styles>
    );
};

export default ForgotPassword;