export const COLORS = {
    '1': '#2965CC',
    '2': '#29A634',
    '3': '#D99E0B',
    '4': '#D13913',
    '5': '#8F398F',
    '6': '#00B3A4',
    '7': '#DB2C6F',
    '8': '#9BBF30',
    '9': '#96622D',
    '10': '#7157D9'
};