import React from 'react';
import styled from 'styled-components';
import ServerComInfo from '../ServerComInfo';

const Styles = styled.div`

`;

const ServerLocationWidget = ({ server }) => {
    const data = generateData(server);

    return (
        <Styles>
            { data.map((item, idx) => {
                return (
                    <ServerComInfo
                        key={ idx + '_ddw2-s222322' }
                        name={ item.title }
                        data={ item.data }
                    />
                );
            }) }
        </Styles>
    );
};

export default ServerLocationWidget;

function generateData(server) {
    return [
        {
            title: 'Name',
            data: getFromIpData(server, 'asn', 'name')
        },
        {
            title: 'Country',
            data: getFromIpData(server, 'countryname')
        },
        {
            title: 'Host',
            data: getFromIpData(server, 'detail')
        }
    ];
}

function getFromIpData(server, key, key2) {
    let res = null;

    if (server && server.ipData && !server.ipData.unavailable) {
        if (key2) {
            if (server.ipData && server.ipData[key] &&
                server.ipData[key][key2]) {
                res = server.ipData[key][key2];
            }
        } else {
            if(server.ipData && server.ipData[key]){
                res = server.ipData[key];
            }
        }
    }

    if (!res) {
        res = 'N/A';
    }

    return res;
}