import utilsService from '../../../../../servises/utils.service';

export const getConnectionsData = data => {
    if (data) {
        const tempData = {
            ext: '',
            key: 'connections',
            data: []
        };
        data.forEach((item, index) => {
            const connections = item.serverStatus ? item.serverStatus.connections : null;
            if(connections) {
                const date = utilsService.timeMe(item.createdAt);
                tempData.data.push({
                    name: date,
                    total: connections.available,
                    connections: connections.current
                });
            }
        });
        return tempData;
    } else {
        return null;
    }
};