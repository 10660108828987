import React, { useMemo } from 'react';
import { useObserver } from 'mobx-react-lite';
import userStore from '../../../../../stores/user.store';
import serverStore from '../../../../../stores/server.store';
import styled from 'styled-components';
import uptimeStore from '../../../../../stores/uptime.store';
import mongoDbStore from '../../../../../stores/mongodb.store';

const Styles = styled.div`
  display: grid;
`;

const StylesItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 16px;
  text-align: center;
  margin-top: 15px;
`;

const StylesTitle = styled.div`

`;

const StylesValue = styled.div`
  display: grid;
  text-align: left;
`;

const UsageWidget = () => {
    return useObserver(() => (
        <UsageWidgetContent
            user={ userStore.user }
            serversObj={ serverStore.serversListObj }
            uptimeObj={ uptimeStore.uptimeListObj }
            mongoObj={ mongoDbStore.mongoDbListObj }
        />
    ));
};

export default UsageWidget;

const UsageWidgetContent = ({ user, serversObj, uptimeObj, mongoObj }) => {
    const totalServers = useMemo(() => {
        if (serversObj) {
            return Object.keys(serversObj).length;
        }
        return 0;
    }, [serversObj]);

    const totalServersLimit = useMemo(() => {
        if (user && user.subscription && user.subscription.servers) {
            return user.subscription.servers;
        }
        return 0;
    }, [user]);

    const totalUptime = useMemo(() => {
        if (uptimeObj) {
            return Object.keys(uptimeObj).length;
        }
        return 0;
    }, [uptimeObj]);

    const totalUptimeLimit = useMemo(() => {
        if (user && user.subscription && user.subscription.uptime) {
            return user.subscription.uptime;
        }
        return 0;
    }, [user]);

    const totalMongo = useMemo(() => {
        if (mongoObj) {
            return Object.keys(mongoObj).length;
        }
        return 0;
    }, [mongoObj]);

    const totalMongoLimit = useMemo(() => {
        if (user && user.subscription && user.subscription.mongo) {
            return user.subscription.mongo;
        }
        return 0;
    }, [user]);

    return (
        <Styles>
            <StylesItem>
                <StylesTitle>
                    Servers
                </StylesTitle>
                <StylesValue>
                    { `${ totalServers } of ${ totalServersLimit }` }
                </StylesValue>
            </StylesItem>
            <StylesItem>
                <StylesTitle>
                    Uptime
                </StylesTitle>
                <StylesValue>
                    { `${ totalUptime } of ${ totalUptimeLimit }` }
                </StylesValue>
            </StylesItem>
            <StylesItem>
                <StylesTitle>
                    Mongo
                </StylesTitle>
                <StylesValue>
                    { `${ totalMongo } of ${ totalMongoLimit }` }
                </StylesValue>
            </StylesItem>
        </Styles>
    );
};