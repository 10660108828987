import React from 'react';
import FillBar from '../../../../components/metrics/FillBar';
import utilsService from '../../../../../servises/utils.service';
import styled from 'styled-components';
import { MarginTopStyled } from '../../../../../style/components/common.styled';

const Styles = styled.div`
  .llddeeassdd {
    //background: #0e1b25;
    background-color: rgb(52,55,65);
    height: 32px;
  }

  .llddeeassdd-s {

  }
`;

const ServerMemoryWidget = ({ server }) => {
    const memoryTotal = utilsService.getFromMetrics(server, 'ram_total', 0);
    const memoryUsage = utilsService.getFromMetrics(server, 'ram_usage', 0);

    const swapTotal = utilsService.getFromMetrics(server, 'swap_total', 0);
    const swapUsage = utilsService.getFromMetrics(server, 'swap_usage', 0);
    return (
        <Styles>
            <MarginTopStyled>
                <FillBar
                    name={ 'Memory Usage:' }
                    total={ server.serverData.ramDataTotal.value }
                    totalUnit={ server.serverData.ramDataTotal.unit }
                    current={ server.serverData.ramDataCurrent.value }
                    currentUnit={ server.serverData.ramDataCurrent.unit }
                    originalTotal={ memoryTotal }
                    originalCurrent={ memoryUsage }
                />
            </MarginTopStyled>

            <MarginTopStyled>
                <FillBar
                    name={ 'Swap Usage:' }
                    total={ server.serverData.swapDataTotal.value }
                    totalUnit={ server.serverData.swapDataTotal.unit }
                    current={ server.serverData.swapDataCurrent.value }
                    currentUnit={ server.serverData.swapDataCurrent.unit }
                    originalTotal={ swapTotal }
                    originalCurrent={ swapUsage }
                />
            </MarginTopStyled>
        </Styles>
    );
};

export default ServerMemoryWidget;