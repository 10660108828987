import React from 'react';
import { spikesTypes } from '../../../../../constants/data.types';
import utilsService from '../../../../../servises/utils.service';
import styled from 'styled-components';
import BaseChart from '../chart/BaseChart';

const Styled = styled.div`
  padding: 20px;
`;

const EmptyStyled = styled.div`
  padding: 20px;
  font-size: 25px;
  text-align: center;
`;

const StyledChart = styled.div`
  margin-top: 20px;

  .recharts-surface {
    //padding: 1px;
  }

  .recharts-responsive-container {
    font-size: 12px;
  }
`;

const SpikesChart = ({ type, serverId, data }) => {
    const charts = [];

    for (let i = 0; i < data.length; i++) {
        const item = data[i];

        const date = utilsService.dateMeWithTime(item.createdAt);
        let value = item.value;
        let unit = '%';
        let total = 100;

        if (type === spikesTypes.RAM) {
            const ramData = utilsService.formatBytes(item.value);
            const ramTotalData = utilsService.formatByTesByUnit(
                item.total, ramData.unit);
            value = ramData.value;
            unit = ramData.unit;
            total = ramTotalData;
        }

        if (type === spikesTypes.DISK) {
            const disData = utilsService.formatBytes(value);
            const disTotalData = utilsService.formatByTesByUnit(
                item.total, disData.unit);

            value = disData.value;
            unit = disData.unit;
            total = disTotalData;
        }

        if (i === 0 || i === 20 || i === 40 || i === 60) {
            const dataForChart = {
                ext: unit,
                key: type,
                data: [
                    {
                        name: date,
                        [type]: value,
                        total: total
                    }]
            };
            charts.push(dataForChart);
        }

        let key = 0;
        if (i > 20 && i < 40) {
            key = 1;
        }

        if (i > 40 && i < 60) {
            key = 2;
        }

        if (i > 60) {
            key = 3;
        }
        if (i !== 0 && i !== 21 && i !== 41 && i !== 61) {
            charts[key].data.push({
                name: date,
                [type]: value,
                total: total
            });
        }
    }

    let isEmpty = !charts || charts.length === 0;

    if (!isEmpty) {
        if (charts && charts.length > 0) {
            if (charts[0] && charts[0].data.length === 1) {
                isEmpty = true;
            }
        }
    }

    return (
        <Styled>
            { !isEmpty && charts.map((chart, idx) => {
                if (chart && chart.data && chart.data.length > 1) {
                    return (
                        <StyledChart
                            key={ 'chart_ze' + idx }
                        >
                            <BaseChart
                                key={ 'chart' + idx }
                                title="CPU"
                                data={ chart }
                                hideHeader={ true }
                            />
                        </StyledChart>
                    );
                } else {
                    return null;
                }
            }) }

            { isEmpty && (
                <EmptyStyled>
                    No Spikes
                </EmptyStyled>
            ) }
        </Styled>
    );
};

export default SpikesChart;