import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
    doPasswordUpdate,
    doSignInWithEmailAndPassword
} from '../../../../servises/auth.service';
import { EuiButton, EuiFieldPassword, EuiFormRow } from '@elastic/eui';
import { addToast } from '../../../components/toasts/Toast';

const Styles = styled.div`
  margin: 20px 10px;

  h2 {
    text-align: center;
  }
`;

const ItemStyled = styled.div`
    padding: 20px 0;
`;

const ChangePassword = props => {
    const { user } = props;

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewtPassword] = useState('');
    const [isSendingSave, setIsSendingSave] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleChange = useCallback(async (key, data) => {
        if (isSendingSave) {
            return;
        }

        setIsSendingSave(true);
        await doSignInWithEmailAndPassword(user.email, currentPassword)
            .then(() => {
                doPasswordUpdate(newPassword).then(() => {
                    setIsSendingSave(false);
                    addToast(
                        'Password has been changed', 'suc');
                }).catch(error2 => {
                    console.log('pew', error2);
                    addToast(
                        'Error happened, please retry later',
                        'error'
                    );
                    setIsError(true);
                });
            })
            .catch(error => {
                setIsSendingSave(false);
                console.log('pew', error);
                addToast(
                    'Error happened, please retry later',
                    'error'
                );
                setIsError(true);
            });
    }, [isSendingSave, user]);

    return (
        <Styles>
            <h2>Change password</h2>
            <ItemStyled>

                <EuiFormRow label="Current password">
                    <EuiFieldPassword
                        type="dual"
                        value={ currentPassword }
                        onChange={ e => setCurrentPassword(
                            e.target.value) }
                    />
                </EuiFormRow>

                <EuiFormRow label="New password">
                    <EuiFieldPassword
                        type="dual"
                        value={ newPassword }
                        onChange={ e => setNewtPassword(
                            e.target.value) }
                    />
                </EuiFormRow>

                <EuiButton
                    isDisabled={ !currentPassword || !newPassword }
                    size="s"
                    onClick={ handleChange }
                    fill
                    isLoading={ isSendingSave }
                >Save</EuiButton>
            </ItemStyled>
        </Styles>
    );
};

export default ChangePassword;
