import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { BoxedStyled } from '../../../../style/components/common.styled';
import {
    EuiFieldText,
    EuiFormFieldset,
    EuiFormRow,
    EuiSelect,
    EuiFieldNumber,
    EuiTextArea,
    EuiSwitch, EuiButton
} from '@elastic/eui';
import validationsService from '../../../../servises/validations.service';
import uptimeStore from '../../../../stores/uptime.store';
import { useHistory } from 'react-router-dom';

const BoxedStyledOwn = styled(BoxedStyled)`
  width: 750px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ContentStyled = styled.div`
  padding: 20px;
`;

export const ContentItemStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 40px;
  align-items: end;

  &.one-row {
    grid-template-columns: 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const HEART_BEAT_TYPES = {
    HTTP: 'HTTP',
    PUSH: 'PUSH (soon)',
    PING: 'PING'
};

const CreateNewMonitorComponent = () => {
    const history = useHistory();

    const [name, setName] = useState('');
    const [heartBeatType, setHeartBeatType] = useState(HEART_BEAT_TYPES.HTTP);
    const [method, setMethod] = useState('GET');
    const [url, setUrl] = useState('');
    const [interval, setIterval] = useState('5 min');
    const [customInterval, setCustomIterval] = useState(6000);
    const [isCustomInterval, setIsCustomIterval] = useState(false);
    const [validHttpStatus, setValidHttpStatus] = useState('200');
    const [body, setBody] = useState('');
    const [header, setHeader] = useState('');
    const [allDisabled, setAllDisabled] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const change = (type, val) => {
        if (type === 'name') {
            setName(val);
        }

        if (type === 'customInterval') {
            if (val) {
                const num = Number(val);
                if (num < 59 || Number.isNaN(num)) {
                    setCustomIterval(60);
                } else {
                    setCustomIterval(val);
                }
            } else {
                setCustomIterval(60);
            }
        }
    };

    const validateAddButton = () => {
        let res = false;

        if (name) {
            if (heartBeatType === HEART_BEAT_TYPES.HTTP) {
                if (url) {
                    res = validationsService.validateUrl(url);
                }
            }

            if (heartBeatType === HEART_BEAT_TYPES.PING) {
                if (url) {
                    res = validationsService.validateIp(url);
                }
            }
        }

        return res;
    };

    const handleSubmit = useCallback(async () => {
        if (isSending) {
            return;
        }
        setAllDisabled(true);
        setIsSending(true);

        let intervalInSeconds = 0;
        if (!isCustomInterval) {
            intervalInSeconds = ITERVAL_OPTIONS.find(p => p.text === interval).interval;
        }

        const payload = {
            name,
            type: heartBeatType,
            method,
            url,
            interval: intervalInSeconds,
            customInterval,
            isCustomInterval,
            validHttpStatus,
            body,
            header
        };

        await uptimeStore.createUptime(payload);

        setTimeout(() => {
            history.push('/servers');
        }, 2000);
    }, [
        isSending,
        name,
        allDisabled,
        heartBeatType,
        method,
        url,
        interval,
        customInterval,
        isCustomInterval,
        validHttpStatus,
        body,
        header]);

    return (
        <BoxedStyledOwn>
            <ContentStyled>
                <ContentItemStyled>
                    <EuiFormRow fullWidth={ true } label="Monitor Name">
                        <EuiFieldText
                            fullWidth={ true }
                            value={ name }
                            disabled={ allDisabled }
                            isInvalid={ !name }
                            onChange={ e => change('name', e.target.value) }
                        />
                    </EuiFormRow>
                </ContentItemStyled>
                <ContentItemStyled>
                    <EuiFormRow
                        label="Select Uptime type"
                    >
                        <EuiSelect
                            disabled={ allDisabled }
                            options={ [
                                {
                                    key: HEART_BEAT_TYPES.HTTP,
                                    text: HEART_BEAT_TYPES.HTTP
                                },
                                {
                                    key: HEART_BEAT_TYPES.PING,
                                    text: HEART_BEAT_TYPES.PING
                                },
                                {
                                    key: HEART_BEAT_TYPES.PUSH,
                                    text: HEART_BEAT_TYPES.PUSH,
                                    disabled: true
                                }
                            ] }
                            value={ heartBeatType }
                            onChange={ (e) => setHeartBeatType(e.target.value) }
                        />
                    </EuiFormRow>
                </ContentItemStyled>

                <ContentItemStyled>
                    <div style={ { display: 'grid', gridGap: 25 } }>
                        <EuiFormFieldset
                            legend={ { children: 'Custom Interval' } }>
                            <EuiSwitch
                                disabled={ allDisabled }
                                label={ isCustomInterval ? 'On' : 'Off' }
                                checked={ isCustomInterval }
                                onChange={ () => setIsCustomIterval(
                                    !isCustomInterval) }
                            />
                        </EuiFormFieldset>

                        <EuiFormRow
                            label="Interval"
                        >
                            <EuiSelect
                                isDisabled={ isCustomInterval || allDisabled }
                                placeholder="Select an option"
                                options={ ITERVAL_OPTIONS }
                                value={ interval }
                                onChange={ (e) => setIterval(e.target.value) }
                            />
                        </EuiFormRow>

                    </div>
                    <EuiFormRow fullWidth={ true }
                                label="Custom Interval (set value in seconds)">
                        <EuiFieldNumber
                            fullWidth={ true }
                            value={ customInterval }
                            disabled={ !isCustomInterval || allDisabled }
                            onChange={ e => change('customInterval',
                                e.target.value
                            ) }
                        />
                    </EuiFormRow>
                </ContentItemStyled>

                { heartBeatType === HEART_BEAT_TYPES.HTTP && (
                    <>
                        <ContentItemStyled className={ 'one-row' }>
                            <EuiFormRow fullWidth={ true } label="URL">
                                <EuiFieldText
                                    disabled={ allDisabled }
                                    fullWidth={ true }
                                    isInvalid={ !validationsService.validateUrl(
                                        url) }
                                    placeholder="http://"
                                    value={ url }
                                    onChange={ e => setUrl(
                                        e.target.value) }
                                />
                            </EuiFormRow>
                        </ContentItemStyled>

                        <ContentItemStyled>
                            <EuiFormRow fullWidth={ true }
                                        label="Valid http status">
                                <EuiFieldNumber
                                    disabled={ allDisabled }
                                    fullWidth={ true }
                                    value={ validHttpStatus }
                                    onChange={ e => setValidHttpStatus(
                                        e.target.value) }
                                />
                            </EuiFormRow>

                            <EuiFormRow
                                label="Select HTTP Method"
                            >
                                <EuiSelect
                                    placeholder="Select an option"
                                    disabled={ allDisabled }
                                    options={ [
                                        {
                                            key: 'GET',
                                            text: 'GET'
                                        },
                                        {
                                            key: 'POST',
                                            text: 'POST'
                                        },
                                        {
                                            key: 'PUT',
                                            text: 'PUT'
                                        },
                                        {
                                            key: 'PATCH',
                                            text: 'PATCH'
                                        },
                                        {
                                            key: 'DELETE',
                                            text: 'DELETE'
                                        },
                                        {
                                            key: 'HEAD',
                                            text: 'HEAD'
                                        },
                                        {
                                            key: 'OPTIONS',
                                            text: 'OPTIONS'
                                        }
                                    ] }
                                    value={ method }
                                    onChange={ (e) => setMethod(
                                        e.target.value) }
                                />
                            </EuiFormRow>
                        </ContentItemStyled>

                        <ContentItemStyled>
                            <EuiFormRow fullWidth={ true }
                                        label="Body (JSON)">
                                <EuiTextArea
                                    disabled={ allDisabled }
                                    fullWidth={ true }
                                    placeholder={ 'Example:\n' +
                                        '{\n' +
                                        '    "key": "value"\n' +
                                        '}' }
                                    value={ body }
                                    onChange={ e => setBody(
                                        e.target.value) }
                                />
                            </EuiFormRow>

                            <EuiFormRow fullWidth={ true }
                                        label="Headers {JSON}">
                                <EuiTextArea
                                    disabled={ allDisabled }
                                    fullWidth={ true }
                                    placeholder={ 'Example:\n' +
                                        '{\n' +
                                        '    "HeaderName": "HeaderValue"\n' +
                                        '}' }
                                    value={ header }
                                    onChange={ e => setHeader(
                                        e.target.value) }
                                />
                            </EuiFormRow>
                        </ContentItemStyled>
                    </>
                ) }

                { heartBeatType === HEART_BEAT_TYPES.PING && (
                    <ContentItemStyled className={ '' }>
                        <EuiFormRow fullWidth={ true } label="IP">
                            <EuiFieldText
                                fullWidth={ true }
                                isInvalid={ !validationsService.validateIp(
                                    url) }
                                placeholder="127.0 0.1"
                                value={ url }
                                onChange={ e => setUrl(
                                    e.target.value) }
                            />
                        </EuiFormRow>
                    </ContentItemStyled>
                ) }
                <EuiButton
                    isDisabled={ !validateAddButton() }
                    size="s"
                    color={ 'success' }
                    onClick={ handleSubmit }
                    fill
                    isLoading={ isSending }
                >Add</EuiButton>
            </ContentStyled>

        </BoxedStyledOwn>
    );
};

export default CreateNewMonitorComponent;

export const ITERVAL_OPTIONS = [
    {
        key: '1m',
        text: '1 min',
        interval: 60
    }, {
        key: '2m',
        text: '2 min',
        interval: 120
    }, {
        key: '3m',
        text: '3 min',
        interval: 180
    }, {
        key: '4m',
        text: '4 min',
        interval: 240
    }, {
        key: '5m',
        text: '5 min',
        interval: 300
    }, {
        key: '10m',
        text: '10 min',
        interval: 600
    }, {
        key: '15m',
        text: '15 min',
        interval: 900
    }, {
        key: '20m',
        text: '20 min',
        interval: 1200
    }, {
        key: '30m',
        text: '30 min',
        interval: 1800
    }, {
        key: '1h',
        text: '1 hour',
        interval: 3600
    }, {
        key: '2h',
        text: '2 hours',
        interval: 7200
    }, {
        key: '3h',
        text: '3 hours',
        interval: 10800
    }, {
        key: '5h',
        text: '5 hours',
        interval: 18000
    }, {
        key: '10h',
        text: '10 hours',
        interval: 36000
    }, {
        key: '15h',
        text: '15 hours',
        interval: 54000
    }, {
        key: '20h',
        text: '20 hours',
        interval: 72000
    }, {
        key: '1d',
        text: '1 day',
        interval: 86400
    }, {
        key: '2d',
        text: '2 days',
        interval: 172800
    }, {
        key: '5d',
        text: '5 days',
        interval: 432000
    }, {
        key: '1w',
        text: '1 week',
        interval: 604800
    }
];