import React, { useState } from "react";
import styled from "styled-components";
import { BoxedStyled, FlowStyled } from "../../../../style/components/common.styled";
import { EuiButton, EuiFieldText, EuiFormRow } from "@elastic/eui";
import { useHistory } from "react-router-dom";
import mongoDbStore from "../../../../stores/mongodb.store";

const ContentStyled = styled.div`
  padding: 20px;
  width: 400px;
  margin: auto;
`;

const BoxedStyledOwn = styled(BoxedStyled)`
  width: 750px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const CreateNewMongoMonitorComponent = () => {
    const history = useHistory();

    const [name, setName] = useState("");
    const [connectionString, setConnectionString] = useState("");
    const [allDisabled, setAllDisabled] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const handleSubmit = async () => {
        if (isSending) {
            return;
        }
        setAllDisabled(true);
        setIsSending(true);

        const payload = {
            name,
            connectionString
        };

        await mongoDbStore.create(payload);

        setTimeout(() => {
            history.push("/servers");
        }, 2000);
    };

    return (
        <BoxedStyledOwn>
            <ContentStyled>
                <EuiFormRow fullWidth={ false } label="Monitor Name">
                    <EuiFieldText
                        fullWidth={ true }
                        value={ name }
                        disabled={ allDisabled }
                        isInvalid={ !name }
                        onChange={ e => setName(e.target.value) }
                    />
                </EuiFormRow>
                <EuiFormRow fullWidth={ false } label="Mongodb connection string">
                    <EuiFieldText
                        fullWidth={ true }
                        value={ connectionString }
                        disabled={ allDisabled }
                        isInvalid={ !connectionString }
                        onChange={ e => setConnectionString(e.target.value) }
                    />
                </EuiFormRow>

                <EuiButton
                    isDisabled={ !name || !connectionString || allDisabled }
                    size="s"
                    color={ "success" }
                    onClick={ handleSubmit }
                    fill
                    isLoading={ isSending }
                >Add</EuiButton>
            </ContentStyled>
            <FlowStyled>
                <h3>How does it work?</h3>
                <ul>
                    <li>Create READ only user in your mongodb database</li>
                    <li>Prepare mongodb connection string with previously created read only user</li>
                    <li>Text some Monitor name</li>
                    <li>Paste your connection string to to required field</li>
                    <li>Wait for data</li>
                    <li>Profit..</li>
                </ul>
            </FlowStyled>
        </BoxedStyledOwn>
    );
};

export default CreateNewMongoMonitorComponent;
