import { useObserver } from 'mobx-react-lite';
import AlarmsWidget from './AlarmsWidget';
import alarmsStore from '../../../../../stores/alarms.store';

const AlarmsWrapperWidget = () => {
    return useObserver(() => (
        <AlarmsWidget
            alarms={ deListAlarms(alarmsStore.alarmsByServerObj) }
        />
    ));
};

export default AlarmsWrapperWidget;

function deListAlarms(alarmsObj) {
    const objOfAlarms = {
        today: [],
        lastWeek: [],
        rest: []
    };

    const keys = Object.keys(alarmsObj);

    keys.forEach(jey => {
        const servAlarms = alarmsObj[jey];

        const weeksObjKeys = Object.keys(servAlarms);

        weeksObjKeys.forEach(llkye=>{

            servAlarms[llkye].forEach(alarm => {
                if (alarm.status === 1) {
                    objOfAlarms[llkye].push(alarm);
                }
            });
        })
    });

    // listOfAlarms.sort((a, b) => b.severity - a.severity);

    return objOfAlarms;
}