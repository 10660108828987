import React from 'react';
import utilsService from '../../../servises/utils.service';
import VerifyEmail from './subPages/VerifyEmail';
import ResetPassword from './subPages/ResetPassword';

const ActionsFromFirebasePage = props => {
    const queryParams = utilsService.queryParamsParser(props.location.search);

    const { apiKey, mode, oobCode } = queryParams;

    return (
        <div>
            { getRightComponent(mode, apiKey, oobCode) }
        </div>
    );
};

function getRightComponent(mode, apiKey, oobCode) {
    switch (mode) {
        case 'verifyEmail':
            return <VerifyEmail
                apiKey={ apiKey }
                oobCode={ oobCode }
            />;
        case 'resetPassword':
            return <ResetPassword
                apiKey={ apiKey }
                oobCode={ oobCode }
            />;
    }
}

export default ActionsFromFirebasePage;