import React, { useState } from 'react';
import styled from 'styled-components';
import { BoxedResponsiveStyled } from '../../../style/components/common.styled';

const WrapperStyles = styled.section`
  width: 1000px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  margin: 10px auto;
  min-height: 500px;

   @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const MenuStyles = styled(BoxedResponsiveStyled)`
  padding: 20px;
  
  h3 {
    font-size: 15px;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0,193,216,0.2);
    padding-bottom: 7px;
  }
`;

const MenuItemStyles = styled.div`
  cursor: pointer;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 700;
  border: none;
  position: relative;
  margin-bottom: 6px;

  &.active {
    color: #1890ff;
  }

  &.disabled {
    color: rgba(255, 255, 255, 0.55);
    cursor: default;
  }

  .sub {
    font-size: 10px;
    margin-left: 3px;
    font-weight: 100;
    color: rgb(0, 128, 213);
  }

  &:after {
    content: "›";
    color: #8392a5;
    font-weight: 500;
    font-size: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
`;

const ContentStyles = styled(BoxedResponsiveStyled)`
  padding: 20px;
`;

const ContentItemStyles = styled.div`

`;

const MenuSettingsComponent = ({ title, menu, content }) => {

    const [contentIndex, setContentIndex] = useState(0);

    return (
        <WrapperStyles>
            <MenuStyles>
                <h3>{ title }</h3>
                { menu.map((mItem, idx) => {
                    let classes = '';
                    if (idx === contentIndex) {
                        classes += 'active ';
                    }

                    if (mItem.disabled) {
                        classes += 'disabled ';
                    }

                    return (
                        <MenuItemStyles
                            key={ 'Pzxd' + idx }
                            onClick={ () => {
                                if (!mItem.disabled) {
                                    setContentIndex(idx);
                                }
                            } }
                            className={ classes }
                        >
                            { mItem.icon ? <span
                                className="cion">{ mItem.icon }</span> : null }
                            <span>{ mItem.title }</span>
                            { mItem.subTitle
                                ? <span
                                    className="sub">{ mItem.subTitle }</span>
                                : null }
                        </MenuItemStyles>
                    );
                }) }
            </MenuStyles>
            <ContentStyles>
                <ContentItemStyles>
                    { content[contentIndex] }
                </ContentItemStyles>
            </ContentStyles>
        </WrapperStyles>
    );
};

export default MenuSettingsComponent;
