import React from 'react';
import styled from 'styled-components';
import {
    BoxedResponsiveStyled
} from '../../../../../style/components/common.styled';
import BaseLineChart from '../../../../components/charts/BaseLineChart';
import { EuiIcon } from '@elastic/eui';

const ServerDashboardChartStyled = styled(BoxedResponsiveStyled)`
  margin: 0 auto 10px auto;

  .chart-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 12px;
    
    h3{
      display: grid;
      align-items: flex-start;
      grid-template-columns: auto 1fr;
      grid-gap: 5px;
    }
  }

  .bp3-select {
    width: 140px;
    display: grid;
    justify-self: end;
  }
`;

const BaseChart = ({
    title,
    isLoading,
    selected,
    handleSelectChange,
    data,
    icon,
    hideHeader = false,
    hideTime = false
}) => {
    return (
        <ServerDashboardChartStyled>
            { !hideHeader && (
                <ChartHeader
                    title={ title }
                    icon={ icon }
                    hideTime={ hideTime }
                    isLoading={ isLoading }
                    selected={ selected }
                    handleSelectChange={ handleSelectChange }
                />
            ) }
            <BaseLineChart
                data={ data }
            />
        </ServerDashboardChartStyled>
    );
};

export default BaseChart;

const ChartHeader = ({
    title,
    isLoading,
    selected,
    handleSelectChange,
    hideTime,
    icon
}) => {

    return (
        <div className="chart-header">
            <h3><EuiIcon type={ icon } size="m"/> { title }</h3>
            { !hideTime && (
                <Selector
                    isLoading={ isLoading }
                    selected={ selected }
                    handleSelectChange={ handleSelectChange }
                />
            ) }
        </div>
    );
};

const Selector = props => {
    const { selected, handleSelectChange, isLoading } = props;

    return (
        <div className="bp3-select">
            <select
                onChange={ handleSelectChange }
                disabled={ isLoading }
            >
                { isLoading && (
                    <option>
                        Loading...
                    </option>
                ) }
                { !isLoading && _SELECTOR_OPTIONS.map(option => {
                    return (
                        <option
                            selected={ option.value === selected }
                            key={ option.value }
                            value={ option.value }
                        >{ option.name }
                        </option>
                    );
                }) }
            </select>
        </div>
    );
};

const _SELECTOR_OPTIONS = [
    {
        value: '10min',
        name: '10 min'
    },
    {
        value: '5min',
        name: '5 min'
    },
    {
        value: '30min',
        name: '30 min'
    },
    {
        value: '1hour',
        name: '1 hour'
    },
    {
        value: '2hours',
        name: '2 hours'
    },
    {
        value: '6hours',
        name: '6 hours'
    },
    {
        value: '12hours',
        name: '12 hours'
    },
    {
        value: '24hours',
        name: '24 hours'
    }
];