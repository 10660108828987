import { useObserver } from "mobx-react-lite";
import alarmsStore from "../../../../../stores/alarms.store";
import styled from "styled-components";

const Styles = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
`;

const ShishivitzaStyles = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  //background-color: #122330;
  background-color: rgb(29,30,36);
  box-shadow: rgb(0 0 0 / 28%) 0px 0.9px 4px, rgb(0 0 0 / 21%) 0px 2.6px 8px, rgb(0 0 0 / 18%) 0px 5.7px 12px, rgb(0 0 0 / 14%) 0px 15px 15px;
  margin: 13px auto;
  min-width: 150px;
  padding: 11px 0;

  legend {
    font-size: 12px;
    text-align: center;
  }

  span {
    font-size: 20px;
    text-align: center;
  }

  &.info {
    border: 1px solid #00bcd4;
  }

  &.warning {
    border: 1px solid #f9be49;
  }

  &.critical {
    border: 1px solid #F86B63;
  }
`;

const AlarmsCountWrapperWidget = () => {
    return useObserver(() => (
        <AlarmsCountWidget
            ctit={ alarmsStore.criticalAlarmsCount }
            war={ alarmsStore.warningAlarmsCount }
            info={ alarmsStore.infoAlarmsCount }
        />
    ));
};

export default AlarmsCountWrapperWidget;

const AlarmsCountWidget = ({ ctit, war, info }) => {
    return (
        <Styles>
            <ShishivitzaStyles
                className='critical'
            >
                <legend>
                    Critical
                </legend>

                <span>{ ctit }</span>
            </ShishivitzaStyles>
            <ShishivitzaStyles
                className='warning'
            >
                <legend>
                    Warning
                </legend>

                <span>{ war }</span>
            </ShishivitzaStyles>
            <ShishivitzaStyles
                className='info'
            >
                <legend>
                    Info
                </legend>

                <span>{ info }</span>
            </ShishivitzaStyles>
        </Styles>
    );
};