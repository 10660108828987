import fetchService from '../servises/fetch.service';
import { makeAutoObservable } from 'mobx';
import utilsService from '../servises/utils.service';
import alarmsStore from './alarms.store';
import userStore from './user.store';

class UptimeStore {
    constructor() {
        makeAutoObservable(this);
    }

    uptimeListObj = {};
    uptimeCreated = null;
    canAddNewUptime = false;

    async getUptime(isForce) {
        if (!isForce && Object.keys(this.uptimeListObj).length > 0) {
            return;
        }
        const response = await fetchService.authenticatedGet(`/uptime`);
        const data = response.data.data_o;
        if (data) {
            data.forEach(item => {
                this.normalize(item);
            });
            this.uptimeListObj = utilsService.normalizeApiData(data);
            this.uptimeListObj = { ...this.uptimeListObj };
        }
        this.detectIfCanAddNewServers();
    }

    async deleteMonitor(uptimeId) {
        await fetchService.authenticatedDelete(
            `/uptime/${ uptimeId }`);
        await this.getUptime(true);
        this.detectIfCanAddNewServers();
    }

    async updateUptime(id, uptime) {
        const response = await fetchService.authenticatedPut(`/uptime/${ id }`, uptime);
        const data = response.data.data_o;
        if (data) {
            this.normalize(data);
            this.uptimeListObj[data._id] = data;
            // this.uptimeListObj = { ...this.uptimeListObj };
        }
    }

    async createUptime(payload) {
        await fetchService.authenticatedPost(`/uptime`, payload);
        await this.getUptime(true);
        this.detectIfCanAddNewServers();
    }

    async updateUptimeTags(id, tagId, isAdd, isServerCreated) {
        const response = await fetchService.authenticatedPut(`/uptime/${ id }`,
            { tagId: tagId, isAdd }
        );

        const data = response.data.data_o;
        this.uptimeListObj[data._id].tags = data.tags;
        this.uptimeListObj = { ...this.uptimeListObj };
    }

    normalize(item) {
        item.classes = '';
        if (item.uptimechecks && item.uptimechecks.length) {
            item.uptimechecks.reverse();
            const check = item.uptimechecks[item.uptimechecks.length - 1];
            if (check.isValid) {
                item.classes = 'normal';
            } else {
                item.classes = 'old-data';
            }

            // item.timeAgo = utilsService.timeAgo(check.createdAt);

        } else {
            item.classes = 'no-data';
            // item.timeAgo = 'N/A';
        }
        if (!item.isEnabled) {
            item.classes = 'disabled';
        }
        alarmsStore.setAlarms(item);
    }

    detectIfCanAddNewServers() {
        const serversCount = Object.keys(this.uptimeListObj).length;
        const subscriptionServers = userStore.user.subscription.uptime;

        if (serversCount < subscriptionServers) {
            this.canAddNewUptime = true;
        } else {
            this.canAddNewUptime = false;
        }
    }
}

const uptimeStore = new UptimeStore();

export default uptimeStore;