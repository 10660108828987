import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Styles = styled.div`
  font-size: 20px;
  text-align: center;
  margin: 10% auto;
  
  h2{
    margin-bottom: 30px;
    font-size: 35px;
  }
`;

const PageNotFound = () => {
    return (
        <Styles>
            <h2>Page not found</h2>

            <Link to={ '/servers' }>
                Proceed to servers list
            </Link>
        </Styles>
    );
};

export default PageNotFound;