import { doRefreshToken, doSignOut } from './auth.service';
import userStore from '../stores/user.store';

class IdleCheckRefreshComponent {

    checkTime = () => {
        if (window.lastActivity <= Date.now() - 300000) {
            console.log('Refreshing the token');
            doRefreshToken().then(res => {
                userStore.userToken = res;
            });
        }
    };

    registerIdleChecker = () => {
        window.lastActivity = Date.now();
        document.addEventListener('click', () => {
            window.lastActivity = Date.now();
        });

        window.setInterval(this.checkTime, 180000);
    };

    registerRefreshToken = () => {
        window.setInterval(this.refreshToken, 180000);
    };

    refreshToken = () => {
        doRefreshToken().then(res => {
            userStore.userToken = res;
        }).catch(err => {
            console.error('FB', err);
            // doSignOut();
        });
    };
}

const idleCheckRefreshComponent = new IdleCheckRefreshComponent();

export default idleCheckRefreshComponent;