import { makeAutoObservable } from 'mobx';
import fetchService from '../servises/fetch.service';
import utilsService from '../servises/utils.service';

class MetricsStore {
    constructor() {
        makeAutoObservable(this);
    }

    cpu = {
        ext: '%',
        key: 'cpu',
        data: []
    };
    ram = {
        ext: '',
        key: 'ram',
        data: []
    };
    net = {
        ext: '',
        key: 'net',
        key2: 'net2',
        data: []
    };
    disk = {
        ext: '',
        key: 'disk',
        data: []
    };
    since = '10min'; // 5min, 30min, 1hour, 2hours, 6hours, 12hours, 24hours
    selectorLoading = false;

    async getMetrics(serverId) {
        this.selectorLoading = true;
        const response = await fetchService.authenticatedGet(
            `/metrics/${ serverId }?from=${ this.since }`);
        const data = response.data.data_o;
        if (data) {
            this.normalizeMetrics(data);
        }
        this.selectorLoading = false;
    }

    async setSince(since, serverId) {
        this.since = since;
        await this.getMetrics(serverId);
    }

    clearMetrics() {
        this.cpu = {
            ext: '%',
            key: 'cpu',
            data: []
        };
        this.ram = {
            ext: '',
            key: 'ram',
            data: []
        };
        this.net = {
            ext: '',
            key: 'net',
            key2: 'net2',
            data: []
        };
        this.disk = {
            ext: '',
            key: 'disk',
            data: []
        };
    }

    normalizeMetrics(data) {
        const len = data.length;

        const cpu = {
            ext: '%',
            key: 'cpu',
            data: []
        };

        const ram = {
            ext: '',
            key: 'ram',
            data: []
        };
        const net = {
            ext: '',
            key: 'net',
            key2: 'net2',
            data: []
        };
        const disk = {
            ext: '',
            key: 'disk',
            data: []
        };

        const connections = {
            ext: '',
            key: 'connections',
            data: []
        };
        const processes = {
            ext: '',
            key: 'processes',
            data: []
        };
        const file_handles = {
            ext: '',
            key: 'file_handles',
            data: []
        };

        const sessions = {
            ext: '',
            key: 'sessions',
            data: []
        };

        // connections  processes file_handles file_handles_limit

        for (let i = len - 1; i >= 0; i--) {
            const metric = data[i];

            const date = utilsService.timeMe(metric.createdAt);

            const ramData = utilsService.formatBytes(metric.ram_usage);
            const ramTotalData = utilsService.formatByTesByUnit(
                metric.ram_total, ramData.unit);
            const netTxData = utilsService.formatBytes(metric.tx_gap);
            const netRxData = utilsService.formatBytes(metric.rx_gap);
            const disData = utilsService.formatBytes(metric.disk_usage);
            const disTotalData = utilsService.formatByTesByUnit(
                metric.disk_total, disData.unit);
            const connectionData = metric.connections;
            const processesData = metric.processes;
            const file_handlesData = metric.file_handles;
            const sessionsData = metric.sessions;

            cpu.data.push({
                name: date,
                cpu: metric.load_cpu,
                total: 100
            });

            ram.ext = ramData.unit;
            ram.data.push({
                name: date,
                ram: ramData.value,
                total: ramTotalData
            });

            net.ext = netTxData.unit;
            net.data.push({
                name: date,
                net: netTxData.value
            });

            disk.ext = disData.unit;
            disk.data.push({
                name: date,
                disk: disData.value,
                total: disTotalData
            });

            connections.data.push({
                name: date,
                connections: connectionData
            });

            processes.data.push({
                name: date,
                processes: processesData
            });

            sessions.data.push({
                name: date,
                sessions: sessionsData
            });

            file_handles.data.push({
                name: date,
                file_handles: file_handlesData,
                // total: metric.file_handles_limit.high
            });
        }

        this.cpu = cpu;
        this.ram = ram;
        this.net = net;
        this.disk = disk;
        this.connections = connections;
        this.processes = processes;
        this.file_handles = file_handles;
        this.sessions = sessions;
    }
}

const metricsStore = new MetricsStore();

export default metricsStore;