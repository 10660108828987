import React from 'react';
import styled from 'styled-components';
import utilsService from '../../../../../servises/utils.service';
import ServerComInfo from '../ServerComInfo';
import CpuCssPulse from "../../../../components/metrics/CpuCssPulse";

const Styles = styled.div`
    .cpu-spinner{
      .circle {
        width: 70%;
        margin-bottom: 6px;
      }
    }
`;

const generateData = server => {
    return [
        {
            title: 'Cpu Name',
            data: utilsService.getFromMetrics(server, 'cpu_name')
        },
        {
            title: 'Frequency',
            data: utilsService.getFromMetrics(server, 'cpu_freq')
        },
        {
            title: 'Cores',
            data: utilsService.getFromMetrics(server, 'cpu_cores')
        },
        // {
        //     title: 'Processes',
        //     data: utilsService.getFromMetrics(server, 'processes')
        // }
    ];
};

const ServerCpuWidget = ({ server }) => {
    // cpu_cores, cpu_freq, cpu_name, load, load_cpu, processes
    const data = generateData(server);
    const cpuLoad = utilsService.getFromMetrics(server, 'load_cpu');

    return (
        <Styles>
            { data.map((item, idx) => {
                return (
                    <ServerComInfo
                        key={ idx + '_ddw2-3d' }
                        name={ item.title }
                        data={ item.data }
                    />
                );
            }) }
            {/*<CpuSpinner*/}
            {/*    load={ cpuLoad }*/}
            {/*/>*/}
            <CpuCssPulse
                load={ cpuLoad }
            />
        </Styles>
    );
};

export default ServerCpuWidget;