import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`

`;

const TableBodyStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
`;

const TableBodyWrapperStyled = styled.div`
  height: 250px;
  overflow: auto;
`;

const TableRowStyled = styled.div`
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 0.65);
  margin: 6px 0;
`;

const TableHeadsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.65);
  border-bottom: 1px solid rgba(255, 255, 255, 0.02);
  padding: 6px 0;
`;

// cpu: 3
// process_name: "systemd"
// ram: 9720
// user: "sysAgent"

const _heads = [
    {
        name: 'User',
        key: 'user'
    },
    {
        name: 'Process',
        key: 'process_name'
    },
    {
        name: 'Cpu',
        key: 'cpu'
    },
    {
        name: 'Ram',
        key: 'ram'
    }
];

const ServerProcessesListWidget = ({ server, processes }) => {
    const processesList = processes && processes.processes_list
        ? processes.processes_list
        : [];
    return (
        <Styles>
            <TableHeadsStyled>
                { _heads.map((pew, idxa) => {
                    return (
                        <div
                            key={ idxa + pew.key }
                        >
                            { pew.name }
                        </div>
                    );
                }) }
            </TableHeadsStyled>
            <TableBodyWrapperStyled>
                { processesList.map((process, idx) => {
                    return (
                        <TableBodyStyled
                            key={ idx + 'zz' }
                        >
                            { _heads.map((head, idxx) => {
                                return (
                                    <TableRowStyled
                                        key={ idxx + 'ss' }
                                    >
                                        { process[head.key] }
                                    </TableRowStyled>
                                );
                            }) }
                        </TableBodyStyled>
                    );
                }) }
            </TableBodyWrapperStyled>
        </Styles>
    );
};

export default ServerProcessesListWidget;