import React, {useState} from "react";
import {
    AscDescFilter, FilterGroupStyled,
    FilterItemDisplay,
    NewServerItem,
    ServersItemStyled,
    ServersListStyled,
    TagsListWrapperStyled
} from "./ServersList";
import styled from "styled-components";
import {EuiIcon, EuiSuperSelect} from "@elastic/eui";
import {serverFilterTypes} from "../../../../constants/data.types";
import {Link} from "react-router-dom";
import TagsMIniColors from "../../../components/tags/TagsMIniColors";
import FillBar from "../../../components/metrics/FillBar";

const WrapperStyled = styled.div`
  margin-bottom: 20px;
`;

const ServerServerListWithChildren = ({
                                          changeOrder,
                                          isTableView,
                                          canAddNewServers,
                                          servers,
                                          selectedTagId,
                                          position,
                                          totalPosition
                                      }) => {
    const monitorFilter = localStorage.getItem("monitorFilter") || serverFilterTypes.createdAt.val;
    const monitorFilterAsc = localStorage.getItem("monitorFilterAsc");
    const [selectedFilter, setSelectedFilter] = useState(monitorFilter);
    const [isAscFilter, setIsAscFilter] = useState(monitorFilterAsc === "true");

    const filters = Object.values(serverFilterTypes);
    const onFilterChange = (value) => {
        setSelectedFilter(value);
        localStorage.setItem("monitorFilter", value);
    };
    const onAscChange = () => {
        setIsAscFilter(!isAscFilter);
        localStorage.setItem("monitorFilterAsc", (!isAscFilter).toString());
    };

    const getServersList = () => {
        let temp = JSON.parse(JSON.stringify(servers));
        if (selectedTagId) {
            temp = servers.filter(ser => {
                if (ser.tags && ser.tags.length) {
                    const tagIndex = ser.tags.indexOf(selectedTagId);
                    if (tagIndex > -1) {
                        return true;
                    }
                }
                return false;
            });

        }

        const defaultString = isAscFilter ? "a" : "z";
        const defaultNumber = !isAscFilter ? 0 : 99999999999999999;
        temp.sort((a, b) => {
            let valueA, valueB;
            let type = "number";

            switch (selectedFilter) {
                case serverFilterTypes.createdAt.val:
                    valueA = a[selectedFilter] || defaultNumber;
                    valueB = b[selectedFilter] || defaultNumber;
                    break;
                case serverFilterTypes.name.val:
                    valueA = a[selectedFilter] || defaultString;
                    valueB = b[selectedFilter] || defaultString;
                    type = "string";
                    break;
                case serverFilterTypes.alarms.val:
                    valueA = 0;
                    valueB = 0;
                    if (a.alarms && a.alarms.length) {
                        valueA = a.alarms.length || defaultNumber;
                    }
                    if (b.alarms && b.alarms.length) {
                        valueB = b.alarms.length || defaultNumber;
                    }
                    break;
                case serverFilterTypes.tags.val:
                    valueA = "";
                    valueB = "";
                    type = "string";
                    if (a.tags && a.tags.length) {
                        valueA = a.tags[0] || defaultString;
                    }
                    if (b.tags && b.tags.length) {
                        valueB = b.tags[0] || defaultString;
                    }
                    break;
                case serverFilterTypes.load_cpu.val:
                    valueA = defaultNumber;
                    valueB = defaultNumber;
                    if (a.metrics && a.metrics.length) {
                        valueA = a.metrics[0].load_cpu || defaultNumber;
                    }
                    if (b.metrics && b.metrics.length) {
                        valueB = b.metrics[0].load_cpu || defaultNumber;
                    }
                    break;
                case serverFilterTypes.ram_usage.val:
                    valueA = defaultNumber;
                    valueB = defaultNumber;
                    if (a.metrics && a.metrics.length) {
                        valueA = a.metrics[0].ram_usage || defaultNumber;
                    }
                    if (b.metrics && b.metrics.length) {
                        valueB = b.metrics[0].ram_usage || defaultNumber;
                    }

                    break;
                case serverFilterTypes.disk_usage.val:
                    valueA = defaultNumber;
                    valueB = defaultNumber;
                    if (a.metrics && a.metrics.length) {
                        valueA = a.metrics[0].disk_usage || defaultNumber;
                    }
                    if (b.metrics && b.metrics.length) {
                        valueB = b.metrics[0].disk_usage || defaultNumber;
                    }

                    break;
            }

            if (type === "number") {
                if (isAscFilter) {
                    if (valueA < valueB) {
                        return -1;
                    }
                    if (valueA > valueB) {
                        return 1;
                    }
                } else {
                    if (valueA > valueB) {
                        return -1;
                    }
                    if (valueA < valueB) {
                        return 1;
                    }
                }
                return 0;
            }

            if (type === "string" && valueA && valueB) {
                console.log('valueB',valueB);
                console.log('valueA',valueA);
                if (isAscFilter) {
                    return valueA.localeCompare(valueB);
                } else {
                    return valueB.localeCompare(valueA);
                }
            }

            if (type === "bool") {
                if (isAscFilter) {
                    return (valueA === valueB) ? 0 : valueA ? -1 : 1;
                } else {
                    return (valueB === valueA) ? 0 : valueB ? -1 : 1;
                }
            }
            return 0;
        });
        return temp;
    };

    return (
        <WrapperStyled>
            <TagsListWrapperStyled>
                <h3><EuiIcon type="createMultiMetricJob" size="m" color="text"/>Servers</h3>
                <div className="position-kdz">
                    {position === 0 && (
                        <EuiIcon
                            onClick={()=>changeOrder(position, 'down')}
                            type="arrowDown" size="m" color="text"/>
                    )}
                    {position === totalPosition - 1 && (
                        <EuiIcon
                            onClick={()=>changeOrder(position, 'up')}
                            type="arrowUp" size="m" color="text"/>
                    )}
                    {position > 0 && position < totalPosition - 1 && (
                        <>
                            <EuiIcon
                                onClick={()=>changeOrder(position, 'down')}
                                type="arrowDown" size="m" color="text"/>
                            <EuiIcon
                                onClick={()=>changeOrder(position, 'up')}
                                type="arrowUp" size="m" color="text"/>
                        </>
                    )}
                </div>
                <FilterGroupStyled>
                    <div className="pllasdaw">
                        <EuiSuperSelect
                            compressed={true}
                            valueOfSelected={selectedFilter}
                            onChange={onFilterChange}
                            options={filters.map(fil => {
                                return {
                                    value: fil.val,
                                    inputDisplay: (<FilterItemDisplay item={fil}/>)
                                };
                            })}
                        />
                        <AscDescFilter isAck={isAscFilter} onClick={onAscChange}/>
                    </div>
                </FilterGroupStyled>
            </TagsListWrapperStyled>
            <ServersListStyled className={isTableView ? "table" : ""}>
                {(getServersList()).map(item => {
                    if (isTableView) {
                        return <ServerItemTable
                            key={item._id}
                            item={item}
                        />;
                    } else {
                        return <ServerItem
                            key={item._id}
                            item={item}
                        />;
                    }
                })}
                {canAddNewServers && (
                    <NewServerItem/>
                )}
            </ServersListStyled>
        </WrapperStyled>
    );
};

export default ServerServerListWithChildren;


const ServerItemTable = ({item}) => {
    const {
        agentPushedTheData,
        classes,
        diskDataCurrent,
        diskDataTotal,
        ramDataCurrent,
        ramDataTotal,
        timeAgo
    } = item.serverData;

    // status, alarms, name, ip, load, last time updated, tags

    const metric = item.metrics ? item.metrics[0] : null;
    let alarmsCount = null;
    if (item && item.alarms && item.alarms.length) {
        alarmsCount = item.alarms.filter(alarm => alarm.status === 1).length;
    }

    const isThereAlarms = alarmsCount > 0;

    return (
        <ServersItemStyled className={classes + " table-dz777"}>
            <Link to={`/servers/${item._id}`} className={"server-link table-view-pox"}/>
            <div className="status color-1 table-view-pox-iuzsd">

                <div className="pepew">
                    <label>Alarms</label>
                    <span className={`val ${isThereAlarms ? "alarmed" : ""}`}>
                         {alarmsCount}
                    </span>
                </div>

                <div className="pepew">
                    <label>Name</label>
                    <span className="val">
                         {item.name}
                    </span>
                </div>

                <div className="pepew mobile12">
                    <label>Ipv4</label>
                    <span className="val">
                       {agentPushedTheData
                           ? metric.ipv4
                           : "N/A"}
                    </span>
                </div>

                <div className="pepew">
                    <label>Load</label>
                    <span className="val" style={{color: "rgb(0, 128, 213)"}}>
                         {agentPushedTheData ? metric.load : ""}
                    </span>
                </div>

                <div className="pepew mobile12">
                    <label>Update ago</label>
                    <span className="val">
                         {timeAgo}
                    </span>
                </div>

                <div className="pepew mobile12">
                    <label>Tags</label>
                    <span className="val">
                          <TagsMIniColors serverTags={item.tags}/>
                    </span>
                </div>

            </div>
        </ServersItemStyled>);
};

const ServerItem = ({item}) => {
    const {
        agentPushedTheData,
        classes,
        diskDataCurrent,
        diskDataTotal,
        ramDataCurrent,
        ramDataTotal,
        timeAgo
    } = item.serverData;

    const metric = item.metrics ? item.metrics[0] : null;
    let alarmsCount = null;
    if (item && item.alarms && item.alarms.length) {
        alarmsCount = item.alarms.filter(alarm => alarm.status === 1).length;
    }

    return (
        <ServersItemStyled className={classes}>
            <Link to={`/servers/${item._id}`} className={"server-link"}/>
            <div className="status color-1">
                <TagsMIniColors
                    style={{position: "absolute"}}
                    serverTags={item.tags}
                />
                {alarmsCount && (
                    <div className="alarms-oodz4">
                        <span className="bibl">Alarms:</span> {alarmsCount}
                    </div>
                )}
                <div className="server-status"/>
                <div className="last-metric">
                    {timeAgo}
                </div>
            </div>
            <h4><span className="label">Name:</span>{item.name}</h4>
            <div className="ip"><span
                className="label">Ipv4:</span>{agentPushedTheData
                ? metric.ipv4
                : "N/A"}</div>
            {agentPushedTheData && (
                <div className="server-data">
                    <div className="load">
                        <span
                            className="label">Load:</span>
                        {metric.load}
                    </div>
                    <div className="cpu">
                        <FillBar
                            name={"Cpu"}
                            total={100}
                            current={metric.load_cpu}
                        />
                    </div>
                    <div className="ram">
                        <FillBar
                            name={"Ram"}
                            total={ramDataTotal.value}
                            totalUnit={ramDataTotal.unit}
                            current={ramDataCurrent.value}
                            currentUnit={ramDataCurrent.unit}
                            originalTotal={metric.ram_total}
                            originalCurrent={metric.ram_usage}
                        />
                    </div>
                    <div className="disk">
                        <FillBar
                            name={"Disk"}
                            total={diskDataTotal.value}
                            totalUnit={diskDataTotal.unit}
                            current={diskDataCurrent.value}
                            currentUnit={diskDataCurrent.unit}
                            originalTotal={metric.disk_total}
                            originalCurrent={metric.disk_usage}
                        />
                    </div>
                </div>
            )}
        </ServersItemStyled>);
};
