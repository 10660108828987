import React, { useState } from 'react';
import { EuiGlobalToastList, EuiLink, EuiCode } from '@elastic/eui';

let addToastHandler;

export function addToast(message, type) {
    let color = 'success';

    if (type === 'error') {
        color = 'danger';
    }

    const data = {
        color: color,
        id: Date.now(),
        text: message,
        toastLifeTimeMs: 6000
    };
    addToastHandler(data);
}

const Toast = () => {
    const [toasts, setToasts] = useState([]);

    addToastHandler = (data) => {
        setToasts(toasts.concat(data));
    };
    return (
        <EuiGlobalToastList
            toasts={ toasts }
            toastLifeTimeMs={ 6000 }
        />
    );
};

export default Toast;