import { makeAutoObservable } from 'mobx';
import fetchService from '../servises/fetch.service';

class TagsStore {
    constructor() {
        makeAutoObservable(this);
    }

    tags = [];

    async getTags() {
        if (this.tags.length > 0) {
            return;
        }
        const response = await fetchService.authenticatedGet(
            `/tags`);

        if (response && response.data && response.data.data_o) {
            this.tags = response.data.data_o;
        }
    }

    async createTag(data) {
        const response = await fetchService.authenticatedPost(
            `/tags`, data);

        if (response && response.data && response.data.data_o) {
            this.tags = response.data.data_o;
        }
    }

    async editTag(id, data) {
        const response = await fetchService.authenticatedPut(
            `/tags/${ id }`, data);

        if (response && response.data && response.data.data_o) {
            this.tags = response.data.data_o;
        }
    }

    async deleteTag(id) {
        const response = await fetchService.authenticatedDelete(
            `/tags/${ id }`);

        if (response && response.data && response.data.data_o) {
            this.tags = response.data.data_o;
        }
    }
}

const tagsStore = new TagsStore();

export default tagsStore;