import React, { useCallback, useState } from "react";
import { applyActionCode, checkActionCode } from "../../../../servises/auth.service";
import LoaderComponent from "../../../components/Loader.component";
import { useHistory } from "react-router-dom";
import userStore from "../../../../stores/user.store";
import { BoxedStyled } from "../../../../style/components/common.styled";
import styled from "styled-components";
import settingsStore from "../../../../stores/settings.store";

const Styles = styled.div`
  text-align: center;
  padding: 30px;
  font-size: 19px;
  line-height: 1.3;
`;

const VerifyEmail = props => {
    const { apiKey, oobCode } = props;

    const history = useHistory();

    if (!userStore.logged) {
        history.push('/login');
    }

    const [loader, setLoader] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    // data:
    //     email: "a0546379525@gmail.com"
    // fromEmail: null
    // multiFactorInfo: null
    // previousEmail: null
    // __proto__: Object
    // operation: "VERIFY_EMAIL"

    const makeEmailVerify = useCallback(async () => {
        await userStore.verifyEmail();
        history.push("/servers");
    }, [loader]);

    const checkOobCone = () => {
        checkActionCode(oobCode).then(async data => {

            applyActionCode(oobCode).then(appliData => {
                userStore.emailVerified = true;
                history.push("/servers");
            }).catch(err => {
                console.log(err);
                setErrorMessage(err.message);
                setLoader(false);
                userStore.emailVerified = true;
                history.push("/servers");
            });
            if (data.operation === "VERIFY_EMAIL") {
                // await makeEmailVerify();
            }
        }).catch(e => {
            setErrorMessage(e.message);
            setLoader(false);
            userStore.emailVerified = true;
            history.push("/servers");
        });
    };

    checkOobCone(oobCode);

    return (
        <BoxedStyled>
            { loader && (
                <LoaderComponent/>
            ) }

            { !loader && errorMessage && (
                <Styles>{ errorMessage }</Styles>
            ) }

            { !loader && !errorMessage && (
                <Styles>Server error, please refresh the page</Styles>
            ) }
        </BoxedStyled>
    );
};

export default VerifyEmail;